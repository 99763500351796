<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM9.44799 13.3634L9.45201 14.0002C9.45201 14.0886 9.37969 14.1629 9.29129 14.1629H8.72076C8.63237 14.1629 8.56004 14.0906 8.56004 14.0022V13.3714C6.77612 13.2388 5.93638 12.2223 5.84598 11.1174C5.83795 11.023 5.91228 10.9426 6.0067 10.9426H6.93482C7.01317 10.9426 7.08147 10.9989 7.09353 11.0752C7.19598 11.7121 7.69219 12.1882 8.58214 12.3067V9.43996L8.08594 9.31339C7.03527 9.06228 6.03482 8.40737 6.03482 7.04933C6.03482 5.58482 7.14777 4.79732 8.57009 4.65871V3.99576C8.57009 3.90737 8.64241 3.83504 8.7308 3.83504H9.29531C9.38371 3.83504 9.45603 3.90737 9.45603 3.99576V4.65268C10.8321 4.79129 11.8647 5.59487 11.9853 6.84643C11.9953 6.94085 11.921 7.02321 11.8246 7.02321H10.9225C10.8422 7.02321 10.7739 6.96295 10.7638 6.8846C10.6835 6.29799 10.2134 5.81987 9.44799 5.7154V8.41339L9.95826 8.53192C11.26 8.85335 12.146 9.47612 12.146 10.8703C12.146 12.383 11.021 13.2268 9.44799 13.3634ZM7.28438 6.95692C7.28438 7.46719 7.59978 7.86295 8.27879 8.10804C8.37321 8.14621 8.46763 8.17634 8.58013 8.20848V5.71741C7.83884 5.81183 7.28438 6.22768 7.28438 6.95692ZM9.62478 9.67299C9.56853 9.66094 9.51228 9.64687 9.44799 9.62879V12.3147C10.3038 12.2384 10.8944 11.7683 10.8944 10.9808C10.8944 10.3641 10.575 9.96228 9.62478 9.67299Z" fill="#00A389"/>
	</svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconDollarGreen",
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
