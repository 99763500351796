import axios from "@/helpers/axiosInterceptor";

const state = {
  dataset: {
    dataset_list: [],
    isLoadingDataset : false,
  }
};

const mutations = {
  SET_DATASET_LIST(state, payload) {
    state.dataset.dataset_list = payload;
  },
  SET_DATASET_ITEM(state, payload) {
  	for(const odsi in state.dataset.dataset_list.rows) {
		if (state.dataset.dataset_list.rows[odsi]?.id === payload.id){
			state.dataset.dataset_list.rows[odsi] = payload;
			break;
		}
  	}
  },
  SET_LOADING_DATASET(state , payload) {
    state.dataset.isLoadingDataset = payload;
  }
};

const actions = {
  async getDatasetList({ commit }, params = {
    "offset": 0,
    "limit": 10,
    "order": {
      "sortBy": "id",
      "sortDesc": true
    }
  }) {
    try {
      commit("SET_LOADING_DATASET" , true)
      const { data, status } = await axios.post("/api/datasets/list", params);
      if (status === 200) {
        commit("SET_DATASET_LIST", data);
      }
    } finally {
      commit("SET_LOADING_DATASET" , false)
    }
  },

  async getDatasetStat({ commit }, id) {
    try {
      commit("SET_LOADING_DATASET" , true)
      const { data, status } = await axios.post("/api/datasets/list",
      	{
    		"offset": 0,
    		"limit": 1,
    		"filter": {"id": id}
    	});
      if (status === 200 && data?.rows?.at(0)?.id === id) {
        commit("SET_DATASET_ITEM", data.rows.at(0));
      }
    } finally {
      commit("SET_LOADING_DATASET" , false)
    }
  },
};

const getters = {
  datasetList: state => state.dataset.dataset_list,
  isLoadingDataset : state => state.dataset.isLoadingDataset
};

export default {
  state,
  mutations,
  actions,
  getters
};
