<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M6.42781 12.0151C6.28531 12.0151 6.14281 11.9626 6.03031 11.8501C5.81281 11.6326 5.81281 11.2726 6.03031 11.0551L10.9428 6.14262C11.1603 5.92512 11.5203 5.92512 11.7378 6.14262C11.9553 6.36012 11.9553 6.72012 11.7378 6.93762L6.82531 11.8501C6.71281 11.9626 6.57031 12.0151 6.42781 12.0151Z" :fill="iconColor"/>
	<path d="M6.73501 8.3323C5.91751 8.3323 5.25 7.66481 5.25 6.84731C5.25 6.02981 5.91751 5.3623 6.73501 5.3623C7.55251 5.3623 8.21999 6.02981 8.21999 6.84731C8.21999 7.66481 7.55251 8.3323 6.73501 8.3323ZM6.73501 6.49481C6.54001 6.49481 6.375 6.65232 6.375 6.85482C6.375 7.05732 6.53251 7.21481 6.73501 7.21481C6.93751 7.21481 7.09499 7.05732 7.09499 6.85482C7.09499 6.65232 6.93001 6.49481 6.73501 6.49481Z" :fill="iconColor"/>
	<path d="M11.6403 12.6301C10.8228 12.6301 10.1553 11.9626 10.1553 11.1451C10.1553 10.3276 10.8228 9.66016 11.6403 9.66016C12.4578 9.66016 13.1253 10.3276 13.1253 11.1451C13.1253 11.9626 12.4578 12.6301 11.6403 12.6301ZM11.6403 10.7927C11.4453 10.7927 11.2803 10.9501 11.2803 11.1526C11.2803 11.3551 11.4378 11.5127 11.6403 11.5127C11.8428 11.5127 12.0003 11.3551 12.0003 11.1526C12.0003 10.9501 11.8428 10.7927 11.6403 10.7927Z" :fill="iconColor"/>
	<path d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625ZM9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 5.175 12.825 2.0625 9 2.0625Z" :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconPercentRound",
    },
    iconColor: {
      type: String,
      default: "#0A6408",
    },
  },
};
</script>
