<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M3.61963 4.41016H15.153C16.5363 4.41016 17.653 5.52682 17.653 6.91016V9.67683" :stroke="iconColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M6.25297 1.77637L3.61963 4.40968L6.25297 7.04304" :stroke="iconColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M17.653 15.8096H6.11963C4.7363 15.8096 3.61963 14.693 3.61963 13.3096V10.543" :stroke="iconColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M15.0195 18.4434L17.6529 15.8101L15.0195 13.1768" :stroke="iconColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "IconDSpending",
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
