<template>
  <div class="pa-10 fill-height d-flex">
    <v-card
      class="fill-height flex-grow-1 pa-6 d-flex flex-column"
      elevation="0"
    >
    
      <v-layout
        align-center
        justify-space-between
        class="flex-grow-0 mb-10"
      >
      	<v-row>
      	<v-col>
        <v-layout align-center justify-start>
          <v-btn
            v-for="(item, index) in filteredTabs"
            :key="index"
            text
            link
            :to="`/admin/users/${item.url}`"
            color="gray"
            class="mr-1"
          >{{item.text}}</v-btn>
        </v-layout>
		</v-col>
		</v-row>
		<v-row>
		<v-col>
        <v-layout class="mb-6" v-if="$route.name!=='distribution'&&$route.name!=='allocation'" align-center justify-end>
			<v-select v-if="['agents', 'staff'].includes($route.name)" outlined
                clearable
                flat
                background-color="gray4"
                hide-details
                :items="team_leaders_list"
                :item-text="item => item.team_name"
                :item-value="item => item.id"
                v-model="theTeam"
                @change="setTeam($event)"
				@click:clear="clearTeamFilter()"
                label="The Team"
            />
          	<v-spacer />
            <v-text-field
              outlined
              background-color="gray4"
              flat
              label="Full name..."
              required
              class="elevation-0 ml-3 shrink"
              append-icon="$vuetify.icons.iconsearch"
              hide-details
              clearable
              @keyup="textSearch($event)"
              v-model="searchInput"
              @click:clear="clearSearchInput()"
              
            />

          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Select Date"
                outlined
                background-color="gray4"
                hide-details
                v-model="dateFormatted"
                prepend-inner-icon="$vuetify.icons.iconcalendardate"
                readonly
                v-bind="attrs"
                v-on="on"
                class="ml-3 shrink"
                clearable
                @click:clear="clearDateRange()"
              >

              </v-text-field>
            </template>
            <v-date-picker
              v-model="computedDate"
              no-title
              scrollable
              range
            >
            </v-date-picker>
          </v-menu>

        </v-layout>
      	</v-col>
      	</v-row>
      </v-layout>
      <div class="flex-grow-1">
        <router-view :key="$route.path"></router-view>
      </div>
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {userTabItems} from "@/helpers/utils";

export default {
  name: "Wrapper",

  data: () => ({

    //filters
    filterObj: {
      search: null,
      filter: {
        createdAt: {
          "Op.between": null,
        },
        teamleader_id: null,
      },
    },

	theTeam: null,
    searchInput: null,
    timer: null,
    dateRange: [],
    //end filters
  }),

  computed: {
    ...mapGetters([
      'userRole',
      'team_leaders_list',
    ]),
    dateFormatted: {
    	get() {
	        return this.dateRange ? this.formatDate(this.dateRange).join('~') : null
	    },
	    set() {}
    },
    computedDate: {
      get() {
        return this.dateRange
      },
      set(val) {
        const [from , to] = val
        new Date(from) > new Date(to) ? this.dateRange = val.reverse() : this.dateRange = val
      }
    },

    allRoutes() {
      return this.$router.matcher.getRoutes();
    },
    filteredTabs() {
      return userTabItems.filter(tab => Boolean(this.getRouteInfoByUrl(tab.name)))
    }
  },


  methods: {
    textSearch() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.filterObj.search = this.searchInput
        this.triggerApplyFilter()
      }, 2000)
    },

	setTeam() {
		this.filterObj.filter.teamleader_id=this.theTeam;
		this.triggerApplyFilter();
	},
    formatDate (date) {
      if(date.length === 2) {
        const arrBetween = []
        for (const i in date) {
          let [year, month, day] = date[i].split('-')
          arrBetween.push(`${year}/${month}/${day}`)
        }
        if (!this.filterObj.filter?.createdAt) {
        	this.filterObj.filter.createdAt = {};
        }
        this.filterObj.filter.createdAt["Op.between"] = arrBetween
        this.triggerApplyFilter()
      }
      return date
    },

    triggerApplyFilter() {
      let obj = {...this.filterObj}

      this.$nextTick(() => {
        if (this.dateRange.length < 2) delete obj.filter.createdAt;
        if (!this.searchInput) obj.search = null
        if (!this.theTeam) delete obj.filter.teamleader_id

        this.$root.$refs.Users.onApply(obj)
      })
    },

    clearDateRange() {
      this.dateRange = []
      this.triggerApplyFilter()
    },
    clearSearchInput() {
      this.searchInput = null
      this.triggerApplyFilter()
    },
    clearTeamFilter(){
    	this.theTeam = null
    	this.triggerApplyFilter()
    },

    getRouteInfoByUrl(name) {
	    const routeByRole = this.allRoutes
		    .find(route => route.name === name && route.parent.name === 'users')
		    .meta?.roles

      return routeByRole && routeByRole.indexOf(this.userRole) !== -1
    },
    
  },

  watch: {
    '$route.path': function() {
      this.searchInput = null
      this.dateRange = []
      this.theTeam = null
    },
  },
};
</script>

<style lang="scss" scoped></style>
