<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M3.96973 10.1092V7.14254C3.96973 3.45921 6.57806 1.95087 9.76973 3.79254L12.3447 5.27587L14.9197 6.75921C18.1114 8.60087 18.1114 11.6175 14.9197 13.4592L12.3447 14.9425L9.76973 16.4259C6.57806 18.2675 3.96973 16.7592 3.96973 13.0759V10.1092Z" :stroke="iconColor"  stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "IconDSin_work",
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
