<template>
  <div
    v-if="this.$vuetify.breakpoint.mdAndUp || this.userRole === 'client'"
    class="app-wrapper"
    :class="{
      isMobile: toggleNav,
    }"
  >
    <v-toolbar :elevation="isScrolling ? 3 : 0" v-scroll="handleScroll" flat height="100" class="header">
      {{ /** Mob burger **/ }}
      <v-btn
        class="burger pa-0 mr-3"
        icon
        color="primary"
        @click="toggleNav = !toggleNav"
      >
        <v-icon color="black" size="24" v-if="toggleNav">mdi-close</v-icon>
        <v-icon color="black" size="24" v-else>mdi-menu</v-icon>
      </v-btn>
      {{ /** end Mob burger **/ }}

        {{ /** Logo **/ }}
        <div class="header-logo d-flex align-center">
          <a href='/'>
          	<img :src="require('@/assets/logo.svg')" height="48" alt="logo"/>
		  </a>
          <div
            v-if="$vuetify.breakpoint.lgAndUp"
            class="header-logo__date d-flex align-center"
          >
            <v-icon color="gray" class="mr-2 opacity-65">$vuetify.icon.iconcalendar</v-icon>
            <div class="gray--text opacity-65">{{ dateToday }}</div>
          </div>
        </div>
        {{ /** end Logo **/ }}

        {{ /* MENU */ }}
        <v-toolbar-items class="header-menu" :class="{ active: toggleNav }">
          <template v-for="(item, index) in filteredMenuItems">
            <v-hover
              v-slot:default="{ hover }"
              open-delay="200"
              :key="index"
            >
              <v-btn
                text
                link
                :ripple="false"
                :to="{ path: item.url }"
                :disabled="item.disable"
                active-class="active"
                class="header-menu__btn"
                :class="{
                  'active': checkActiveRoute(item.url),
                  'save_marker': item?.save_marker&&!app_settings_saving
                }"
                exact-path
                exact
                @click="toggleNav = false"
              >
                <v-icon
                  class="mr-1"
                  :class="{
                  	'save_marker_icon': item?.save_marker
            	  }"
                  :color="hover || checkActiveRoute(item.url) ? 'black' : 'gray2'"
                  size="32">{{ item?.save_marker&&app_settings_saving ? `$vuetify.icons.${item.icon_save}` : `$vuetify.icons.${item.icon}`  }}
                </v-icon>

                <v-expand-x-transition>
                  <span
                    v-show="$vuetify.breakpoint.smAndDown || checkActiveRoute(item.url) || hover "
                    class="text--black"
                  >{{ item.name }}</span>
                </v-expand-x-transition>
                <v-chip
                  class="ma-2"
                  label
                  small
                  color="primary"
                  v-if="item.disable"
                >
                  Soon
                </v-chip>
              </v-btn>
            </v-hover>

          </template>
          
          
              

        </v-toolbar-items>
        {{ /* END MENU */ }}

        <v-toolbar-items v-if="userProfile" class="header-navmenu">
          <v-sheet
            class="d-flex align-center ml-auto"
            :class="{
              'mr-6': $vuetify.breakpoint.mdAndUp,
              'mr-2': $vuetify.breakpoint.smAndDown
            }"
          > 

          
          <Notifications
		  	v-if="['1_admin', '2_manager', '3_teamleader', '4_agent'].includes(userRole) && app_settings?.Security?.NOTIFICATIONS"
		  />


          </v-sheet>



          <v-menu
            offset-y
            content-class="menu_btn__user_menu"
            open-on-hover
            bottom
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                elevation="0"
                text
                color="white"
                class="menu_btn__user_btn"
              >
                <v-avatar
                  color="primary"
                  :size="$vuetify.breakpoint.mdAndUp ? '60' : '40'"
                >
                  <v-icon
                    class="menu_btn__user_menu--avatar-icon"
                    :size="$vuetify.breakpoint.mdAndUp ? '30' : '24'"
                    color="white">{{ userProfile?.icon }}
                  </v-icon>
                </v-avatar>

                <div
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="black--text text-capitalize ml-2 text-h6">{{ userProfile?.text }}</div>

                <v-icon
                  :color="attrs['aria-expanded'] === 'true' ? 'primary' : 'gray'"
                  class="ml-2"
                >mdi-chevron-down
                </v-icon
                >
              </v-btn>
            </template>
            <v-list color="white">
              <v-subheader v-show="user?.fullname" class="text-body-2 font-weight-bold"
                           v-if="userRole !== 'client' && userRole !== '3_teamleader'">
                {{ user?.language }} {{ user?.role.charAt(2).toUpperCase()+user?.role.substring(3)}}<br> {{ user?.fullname }}
              </v-subheader>

              <v-divider></v-divider>

              <v-list-item
                link
                @click="openSettings()"
                v-if="userRole === 'client'"
              >
                <v-list-item-icon>
                  <v-icon size="16">mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  class="body1"
                  v-text="$t('header.menu_user.profile_settings')"
                ></v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>

              <v-list-item link @click="$store.dispatch('logout')">
                <v-list-item-icon>
                  <v-icon size="16">$vuetify.icons.iconlogout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="$t('header.menu_user.log_out')"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
    </v-toolbar>
    
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>

  <v-layout
    class="fill-height"
    v-else
    align-center justify-center
  >
    <v-sheet
      color="white"
      max-width="500"
      rounded
      class="pa-6"
    >
      <v-layout justify-center column align-center>
        <v-icon color="green" size="50" class="mb-6">mdi-laptop</v-icon>

        <div class="text-h3 text-center">Available on Desktop</div>
      </v-layout>

    </v-sheet>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {getKeyFromSessionStorage, menuItems} from "@/helpers/utils";
import Notifications from "@/components/UI/Notifications.vue";

export default {
    name: "AppLayout",
    components: { Notifications },
    mounted() {
        if (!this.user) {
            this._authGetCredentials().then(()=>{
            	if (['1_admin', '2_manager', '3_teamleader', '4_agent'].includes(this.userRole)) {
            		this.loadAppSettings()
            	}
            });
		}
    },
    data: () => ({
        toggleNav: false,
        isScrolling: false
    }),
    methods: {
        ...mapActions(["_authGetCredentials", "loadAppSettings"]),
        
        handleScroll: function() {
            this.isScrolling = window.scrollY > 0;
        },
	  
      openSettings() {
        if (this.$route.name === 'clientSettings') return
        this.$router.push({name: 'clientSettings'})
      },

      checkActiveRoute(url) {
          return url === this.$route.matched[0].path || (this.$route.matched[1].path  === url)
      },

    },
    computed: {
        ...mapGetters(["user", "userRole", "app_settings_saving", "app_settings"]),
        dateToday() {
            const options = {
                day: "numeric",
                month: "short",
                year: "numeric"
            };
            return new Date().toLocaleString("en-GB", options);
        },
        userProfile() {
            switch (this.userRole) {
                case "1_admin" :
                    return { icon: "$vuetify.icons.iconmenuuser", text: "Administrator" };
                case "2_manager" :
                    return { icon: "$vuetify.icons.IconCrmManager", text: "CRM Manager" };
                case "3_teamleader" :
                    return { icon: "$vuetify.icons.IconTeamLead", text: this.user?.fullname };
                case "4_agent" :
                    return { icon: "$vuetify.icons.IconAgent", text: "Agent" };
                case "client" :
                    return { icon: "$vuetify.icons.IconUser", text: this.user?.fullname };
                default :
                    return [];
            }
        },
        filteredMenuItems() {
          if (this.userRole === 'client' && !getKeyFromSessionStorage('user')?.has_transactions) {
            return []
          } else {
            return menuItems.filter(item => item?.roles.indexOf(this.userRole) !== -1)
          }
        },
    },
};
</script>

<style lang="scss">
.app-wrapper {
  min-height: 100vh;
  //max-height: 100vh;
  background: var(--v-gray4-base);
  position: relative;
}

.v-main {
  min-height: calc(100vh - 100px);
  //max-height: calc(100vh - 100px);
}

.header {
  position: sticky;
  top: 0;
  z-index: 8;

  &__sticky {
    box-shadow: 0 3px 45px rgba(43, 94, 194, 0.15) !important;
  }

  .v-toolbar__content {
    padding: 0 43px;

    @include max-media($xs-breakpoint) {
      padding: 0 16px;
    }
  }

  &-logo {
    flex: 1;
  }

  @include max-media(959px) {
    .v-toolbar__content {
      justify-content: space-between;
    }
    .header-logo {
      img {
        margin-right: 0 !important;
      }
    }
    .header-menu {
      position: absolute;
      left: 0;
      top: 100px;
      height: calc(100vh - 100px);
      width: 100%;
      background: var(--v-gray4-base);
      z-index: 1;
      flex-direction: column;
      align-items: flex-start;
      transform: translateX(-100%);
      transition: all .3s ease-in-out;

      &.active {
        transform: translateX(0);
      }
    }
  }
  @include max-media($xs-breakpoint) {
    .v-toolbar__content {
      //height: 44px !important;
    }
    .header-menu {
      //top: 43px;
      //height: calc(100vh - 43px);
    }
  }
}

.burger {
  display: none;
  @include max-media(959px) {
    display: block;
  }
}

//Header logo
.header-logo {
  position: relative;

  &__date {
    position: absolute;
    left: 173px;
    white-space: nowrap;
  }
}

//header Menu
.header-menu {
  &__btn.active {
    svg {
      path {
        stroke: var(--v-black-base) !important;
      }
    }

    .v-btn__content {
      .v-icon.gray2--text {
        color: var(-v-gray2-base) !important;
      }
      span.text--black {
        display: block !important;
      }
    }
  }

  .v-btn.v-size--default {
    text-transform: inherit;
    font-size: 16px;
    padding: 0 24px;
    font-weight: 500 !important;

    .v-chip {
      padding: 2px 8px !important;
      font-weight: 700 !important;
      font-size: 10px !important;
      line-height: 1.6 !important;
      height: 20px !important;
    }

    &:hover,
    &:active,
    &:focus,
    &.v-btn--active {
      &:before {
        content: none;
      }
    }
  }

  .v-btn--disabled {
    color: #fff !important;
  }

  @include max-media(959px) {
    .v-btn{
      height: auto !important;
      padding: 16px 24px !important;

      border-bottom: 1px solid var(--v-gray3-base);
      width: 100%;
    }
  }
}

//end header Menu

//nav Menu
.header-navmenu {
  flex: 1;
}

.menu_btn {
  &__settings {
    &.v-btn--active {
      color: var(--v-primary-base) !important;
    }
  }

  &__user {
    &_btn {
      padding-right: 0 !important;

      &:focus::before {
        opacity: 0 !important;
      }
    }

    &_menu {
      //border: none;
      //border-top-right-radius: 0;
      //border-top-left-radius: 0;
      //box-shadow: none;
      //right: 0 !important;
      //top: 72px !important;
      //left: unset !important;

      &--avatar-icon {
        border-radius: 0 !important;
      }

      @include max-media($xs-breakpoint) {
        top: 44px !important;
      }

      .v-list {
        padding-top: 0;

        .v-list-item,
        .v-subheader {
          //padding-left: 32px;
          //padding-right: 32px;
        }
      }

      .v-list-item {
        &:before {
          opacity: 0;
        }

        &:hover {
          .v-list-item__title,
          .v-icon {
            //color: var(--v-primary-base) !important;
          }
        }

        .v-list-item__icon:first-child {
          //margin-right: 10px;
        }
      }

      .v-divider.theme--dark {
        border-color: var(--v-grayDark-base);
        opacity: 0.4;
      }
    }
  }
}

.isMobile {
  @include max-media(1260px) {
    overflow: hidden !important;
    height: 100vh !important;
  }
}

.isActiveFilter {
  @include max-media($md-breakpoint) {
    overflow: hidden !important;
    height: 100vh !important;
  }
}

// .isCardDetail {
//   @include max-media($md-breakpoint) {
//     overflow: hidden !important;
//   }
// }

.isOverflow {
  overflow: hidden !important;
  height: 100vh !important;
}

.spaces-list {
  &__item {
    cursor: pointer;
    transition: all 0.3s;
    color: $white;

    &:hover {
      color: $accent;
    }
  }
}

/* Save button */

.save_marker>span.v-btn__content{
	transition: width .5s
}
.save_marker_max_width>span.v-btn__content {
	width: 225px;
}

.save_marker_active>span.v-btn__content {
	border-radius: 23px;
    padding: 2px;
    background: #e7e7e7;
    border:1px solid #ccc;
    place-content: flex-start;
	transition: width .5s;
    width: 225px;
}
.save_marker_active>span>span.text--black:after {
	content: ' saving... (ESC)';
}
.save_marker_unsaved>span>span.text--black:after {
	content: ' unsaved';
	color: #ff3200;
}
.save_marker_min_width>span.v-btn__content {
	transition: width 4s cubic-bezier(0.44, 0.2, 0.29, 0.81);
	width: 38px;
}

</style>
