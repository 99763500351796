import UiErrorHandler from "@/components/UI/ErrorPlugin.vue";
export default {
    install(Vue , {router , vuetify}) {
        async function renderErrorComponent(error, redirectUrl) {
            const ErrorHandlerComponent = Vue.extend(UiErrorHandler);
            const instance = new ErrorHandlerComponent({
                router,
                vuetify,
                propsData: {
                    error,
                    redirectUrl,
                }
            });
            instance.$mount();
        }

        Vue.prototype.$error = renderErrorComponent;
    }
};
