<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9.78749 12.1877H8.37751C7.38001 12.1877 6.5625 11.3477 6.5625 10.3127C6.5625 10.0052 6.8175 9.75017 7.125 9.75017C7.4325 9.75017 7.6875 10.0052 7.6875 10.3127C7.6875 10.7252 7.99501 11.0627 8.37751 11.0627H9.78749C10.08 11.0627 10.3125 10.8002 10.3125 10.4777C10.3125 10.0727 10.2 10.0127 9.94501 9.92267L7.6875 9.13517C7.2075 8.96267 6.5625 8.61767 6.5625 7.51517C6.5625 6.57767 7.30501 5.80518 8.21251 5.80518H9.62249C10.62 5.80518 11.4375 6.64518 11.4375 7.68018C11.4375 7.98768 11.1825 8.24268 10.875 8.24268C10.5675 8.24268 10.3125 7.98768 10.3125 7.68018C10.3125 7.26768 10.005 6.93018 9.62249 6.93018H8.21251C7.92001 6.93018 7.6875 7.19267 7.6875 7.51517C7.6875 7.92017 7.79999 7.98017 8.05499 8.07017L10.3125 8.85767C10.7925 9.03017 11.4375 9.37517 11.4375 10.4777C11.4375 11.4227 10.695 12.1877 9.78749 12.1877Z" :fill="iconColor"/>
		<path d="M9 12.9375C8.6925 12.9375 8.4375 12.6825 8.4375 12.375V5.625C8.4375 5.3175 8.6925 5.0625 9 5.0625C9.3075 5.0625 9.5625 5.3175 9.5625 5.625V12.375C9.5625 12.6825 9.3075 12.9375 9 12.9375Z" :fill="iconColor"/>
		<path d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C9.3075 0.9375 9.5625 1.1925 9.5625 1.5C9.5625 1.8075 9.3075 2.0625 9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 8.6925 16.1925 8.4375 16.5 8.4375C16.8075 8.4375 17.0625 8.6925 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625Z" :fill="iconColor"/>
		<path d="M16.5 5.0625C16.1925 5.0625 15.9375 4.8075 15.9375 4.5V2.0625H13.5C13.1925 2.0625 12.9375 1.8075 12.9375 1.5C12.9375 1.1925 13.1925 0.9375 13.5 0.9375H16.5C16.8075 0.9375 17.0625 1.1925 17.0625 1.5V4.5C17.0625 4.8075 16.8075 5.0625 16.5 5.0625Z" :fill="iconColor"/>
		<path d="M12.7501 5.81258C12.6076 5.81258 12.4651 5.76008 12.3526 5.64758C12.1351 5.43008 12.1351 5.07008 12.3526 4.85258L16.1026 1.10258C16.3201 0.885078 16.6801 0.885078 16.8976 1.10258C17.1151 1.32008 17.1151 1.68008 16.8976 1.89758L13.1476 5.64758C13.0351 5.76008 12.8926 5.81258 12.7501 5.81258Z" :fill="iconColor"/>
	</svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "IconMoneyOut",
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
