<template>
  <svg :width="width" :height="height" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5008 9.14926C14.5008 12.7027 11.8695 15.5833 8.31607 15.5833C5.03062 15.5843 2.41806 13.1071 2.0436 9.91426C1.73348 7.79851 3.17866 5.00682 3.20443 5.00012C4.23985 5.13949 4.47467 6.28045 4.47467 6.28045C4.65657 6.80881 5.26764 7.04949 5.76101 6.78708C6.71702 6.26149 6.10449 5.53693 5.92223 4.43878C5.67294 2.93676 6.88261 1.45211 8.4333 1C7.37133 2.15205 7.88238 4.62077 9.23193 4.78536C10.5815 4.94994 10.8448 3.07372 10.8448 3.07372C11.3365 3.36512 11.5965 3.92857 11.6749 4.47976C11.7456 4.97711 11.6145 5.47848 11.3387 5.89839C10.1255 7.74595 12.9753 9.78161 13.7743 6.39827C14.2148 7.40161 14.4746 8.30777 14.5008 9.14926Z" :stroke="iconColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.71484 12.9255L9.72827 8.40234" :stroke="iconColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.20952 9.62518C7.49439 9.34031 7.49439 8.87844 7.20952 8.59357C6.92465 8.3087 6.46278 8.3087 6.17791 8.59357C5.89304 8.87844 5.89304 9.34031 6.17791 9.62518C6.46278 9.91005 6.92465 9.91005 7.20952 9.62518Z" :stroke="iconColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.3208 12.7853C10.6057 12.5005 10.6057 12.0386 10.3208 11.7537C10.036 11.4689 9.57411 11.4689 9.28924 11.7537C9.00437 12.0386 9.00437 12.5005 9.28924 12.7853C9.57411 13.0702 10.036 13.0702 10.3208 12.7853Z" :stroke="iconColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconDeals",
    },
    width: {
      type: [Number, String],
      default: 17,
    },
    height: {
      type: [Number, String],
      default: 17,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
