export default [
  // {
  //   path: "*",
  //   redirect: "login",
  // },
  {
      path: "/login",
      name: "login",
      meta : {type : 'client'},
      component: () => import(/* webpackChunkName: '' */ "@/views/Auth/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import(/* webpackChunkName: '' */ "@/views/Auth/Register.vue"),
  },
  {
    path: "/password_recovery",
    name: "password-recovery",
    component: () => import(/* webpackChunkName: '' */ "@/views/Auth/Reset.vue"),
  },
  {
    path: "/password_recovery/:id",
    name: "password-recovery",
    component: () => import(/* webpackChunkName: '' */ "@/views/Auth/PasswordRecovery.vue"),
  },
  {
    path: "/activate/:id",
    name: "activate",
    component: () => import(/* webpackChunkName: '' */ "@/views/Auth/Activate.vue"),
  },
  // {
  //   path: "/",
  //   name: "main",
  //   meta: { auth: true },
  //   component: () => import(/* webpackChunkName: '' */ "@/views/Auth/Login.vue"),
  // },
  // {
  //   path: "/",
  //   redirect: { path: "/dashboard" },
  // },
  {
    path: "*",
    redirect: { path: "/" },
  },
];
