<template>
	<svg width="56" height="55" viewBox="0 0 56 55" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.333496" y="0.901367" width="55" height="54.0984" rx="12" fill="#828282"/>
		<path d="M21.5835 25.6262C22.4561 24.7202 23.5027 23.9997 24.6604 23.5078C25.8181 23.016 27.0632 22.7629 28.321 22.7637C30.771 22.7637 33.2085 23.7137 35.0585 25.6262C38.771 29.4387 38.771 35.6387 35.0585 39.4512C34.1867 40.3582 33.1403 41.0794 31.9825 41.5713C30.8246 42.0632 29.5791 42.3158 28.321 42.3137C25.7835 42.3137 23.3835 41.3012 21.5835 39.4512C19.7839 37.6017 18.7828 35.1192 18.796 32.5387C18.796 29.9262 19.7835 27.4762 21.5835 25.6262ZM25.6685 37.8387H30.631C31.331 37.8387 31.881 37.2637 31.881 36.5637C31.881 35.8637 31.3185 35.3012 30.6185 35.3012H28.3185L30.756 32.3012C31.581 31.2887 31.756 29.9262 31.2185 28.7262C30.9646 28.1577 30.5579 27.671 30.0437 27.32C29.5295 26.9691 28.9279 26.7679 28.306 26.7387H28.1435C26.931 26.7387 25.7935 27.3262 25.0935 28.3137C24.6935 28.8887 24.8185 29.6762 25.3935 30.0762C25.9685 30.4762 26.756 30.3387 27.156 29.7762C27.3935 29.4512 27.756 29.2637 28.156 29.2637H28.2185C28.681 29.2762 28.881 29.6137 28.9435 29.7637C28.9935 29.8887 29.1435 30.3137 28.8185 30.7137L24.6935 35.7762C24.381 36.1512 24.3185 36.6762 24.531 37.1137C24.6314 37.3307 24.792 37.5143 24.9936 37.6428C25.1952 37.7713 25.4294 37.8393 25.6685 37.8387Z" fill="white"/>
		<path d="M28.331 16.5976L25.2685 11.5639C24.8435 10.8389 24.081 10.4014 23.2435 10.4014H15.006C13.1935 10.4014 12.056 12.3514 12.9435 13.9264C14.5931 16.8448 16.9212 19.323 19.731 21.1514C20.1735 21.4951 20.6648 21.7651 21.186 21.9564C17.9148 24.2764 15.771 28.1514 15.771 32.5389C15.771 39.6426 21.3898 45.4014 28.321 45.4014C35.2523 45.4014 40.871 39.6426 40.871 32.5389C40.871 28.1551 38.7323 24.2851 35.466 21.9626C35.9969 21.7688 36.4951 21.4953 36.9435 21.1514C39.756 19.3264 42.081 16.8514 43.731 13.9264C44.606 12.3514 43.4685 10.4014 41.656 10.4014H33.4185C32.581 10.4014 31.8185 10.8389 31.3935 11.5639L28.331 16.5976ZM28.321 19.6764C28.186 19.6764 28.051 19.6789 27.9173 19.6826L32.4673 12.2051L32.4723 12.1964C32.5677 12.03 32.7056 11.8919 32.8718 11.7962C33.038 11.7004 33.2267 11.6505 33.4185 11.6514H41.656C41.851 11.6519 42.0426 11.7032 42.2118 11.8001C42.381 11.897 42.5222 12.0362 42.6214 12.2041C42.7206 12.372 42.7745 12.5628 42.7777 12.7578C42.7809 12.9528 42.7334 13.1453 42.6398 13.3164C41.0928 16.0603 38.9046 18.3891 36.2623 20.1039L36.2223 20.1289L36.1835 20.1589C35.5313 20.6621 34.7461 20.9636 33.9248 21.0264C32.1908 20.1371 30.2697 19.6742 28.321 19.6764ZM20.5335 40.5389C18.4451 38.4012 17.2821 35.5273 17.296 32.5389C17.296 29.5139 18.446 26.6764 20.5335 24.5389C21.5464 23.4921 22.7594 22.6598 24.1005 22.0913C25.4415 21.5229 26.8832 21.2299 28.3398 21.2299C29.7963 21.2299 31.238 21.5229 32.5791 22.0913C33.9201 22.6598 35.1331 23.4921 36.146 24.5389C40.446 28.9514 40.446 36.1264 36.146 40.5389C34.046 42.6764 31.271 43.8514 28.3335 43.8514C25.3835 43.8514 22.6085 42.6764 20.5335 40.5389Z" fill="white"/>
	</svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "Icon2ndPlace",
    }
  },
};
</script>
