import merge from "lodash/merge";
import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";

import App from "./App.vue";
import common_store from './store'
import i18n from "./lang/i18n";
import { extra_modules_list } from "./modules/custom_modules_list";

import vuetify from "./plugins/vuetify";
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import VueMask from 'v-mask'
import coreRouter from "./router/router";

import vueScroll from "./plugins/vueScroll";
import Moment from "vue-moment"
import VueClipboard from 'vue-clipboard2'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import DatetimePicker from 'vuetify-datetime-picker'
import VueJwtDecode from 'vue-jwt-decode'
import cardValidator from 'card-validator'
// (Optional)
// import 'vuetify-datetime-picker/src/stylus/main.styl'
Vue.use(DatetimePicker)
import "./scss/fonts.scss";
import "./scss/main.scss";
import {Config} from '@/constants/config';

import wb from "./registerServiceWorker";
Vue.prototype.$workbox = wb;
import ErrorPlugin from "@/plugins/error";
import ConfirmPlugin from "@/plugins/confirm";
// for develop mode
import axios from "axios";
import { getKeyFromSessionStorage, noAuthRoutes } from "@/helpers/utils";
import VueApexCharts from 'vue-apexcharts'
axios.defaults.withCredentials = true
axios.defaults.baseURL = Config.VUE_APP_BASEURL;

// import modules stores
let modules_stores = {};

const connectModuleStore = (module) => {
  try {
    const module_store = require(`./modules/${module.component_name}/store/store.js`);
    if (module_store && module_store.default) {
      modules_stores = merge(modules_stores, module_store.default);
    }
  } catch (e) {
    // console.log('store file not found');
  }
};


// import modules routers
const modules_routers = [];
const connectModuleRoute = (module) => {
  try {
    const module_router = require(`./modules/${module.component_name}/router/router.js`);

    if (module_router && module_router.default) {
      modules_routers.push(module_router.default);
    }
  } catch (e) {
    // console.log('store file not found');
  }
};

// add modules if ACTIVE
extra_modules_list
  .filter((module) => module && module.active && module.component_name)
  .forEach((module) => {
    connectModuleStore(module);
    connectModuleRoute(module);
  });


Vue.config.productionTip = false;
Vue.config.devtools = false;
Vue.config.debug = false;

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueJwtDecode)

Vue.use(cardValidator)

Vue.use(vueScroll);
Vue.use(Moment);
Vue.use(VueClipboard)
Vue.use(VueToast);
Vue.use(VueMask);
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
export const appStore = new Vuex.Store({
    modules: {
        common: common_store,
        app: merge(
            modules_stores,
        ),
    },
});

export const router = new VueRouter({
  mode: "history",
  routes: [...coreRouter, ...modules_routers.flat()],
});

Vue.mixin({
    beforeRouteEnter(to, from, next) {
        if (noAuthRoutes.indexOf(to.name) === -1 && !sessionStorage.getItem('token')) {
            next("/login");
        }
        const roles = to.meta?.roles;
        const user = getKeyFromSessionStorage('user')
      // console.log(user, 'user')
        if (roles && user?.role && roles.indexOf(user?.role) === -1) {
            // if the user does not have the required role, redirect to a different route or show an error message
            if (user?.role !== 'client') {
                next('/admin');
            } else {
                next('/');
            }
        } else {
          // if the user has the required role, allow them to access the route
          if ( user?.role === 'client' && user?.has_transactions && to?.name !== 'clientSettings' && to?.name !== 'welcome' ) {
            next();
          } else if (user?.role === 'client' && to?.name !== 'clientSettings' && !user?.has_transactions && to?.name !== 'welcome'){
            next('/welcome');
          } else {
            next();
          }
        }
    }
})

Vue.directive('scroll', {
    inserted: function(el, binding) {
        let f = function(evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f);
            }
        };
        window.addEventListener('scroll', f);
    }
});

Vue.filter("amountWithSeparator", (value) => {
  if (!value) return "0,00";

  // eslint-disable-next-line prefer-const
  let [integerPart, decimalPart] = value.toString().split(".");
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  if (!decimalPart) {
    decimalPart = "00";
  }else{
    decimalPart = decimalPart.length>2?decimalPart.substring(0,2):decimalPart;
  }
  return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
});
Vue.use(ErrorPlugin , {
    vuetify,
    router
});

Vue.use(ConfirmPlugin , {
    vuetify,
});

Vue.prototype.$AppRouter = router
Vue.prototype.$store = appStore
import "./helpers/axiosInterceptor"

new Vue({
  router,
  i18n,
  store: appStore,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
