<template>
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M11.1665 16.2666H20.4998" :stroke="iconColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M11.1665 21.5996H17.0065" :stroke="iconColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M13.8332 8.00033H19.1665C21.8332 8.00033 21.8332 6.66699 21.8332 5.33366C21.8332 2.66699 20.4998 2.66699 19.1665 2.66699H13.8332C12.4998 2.66699 11.1665 2.66699 11.1665 5.33366C11.1665 8.00033 12.4998 8.00033 13.8332 8.00033Z" :stroke="iconColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M21.8333 5.36035C26.2733 5.60035 28.5 7.24035 28.5 13.3337V21.3337C28.5 26.667 27.1667 29.3337 20.5 29.3337H12.5C5.83333 29.3337 4.5 26.667 4.5 21.3337V13.3337C4.5 7.25368 6.72667 5.60035 11.1667 5.36035" :stroke="iconColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconSettings",
    },
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
