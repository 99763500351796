import LAYOUT from "@/layout/layout";

export default [
  {
    path: "/admin/finance",
    component: LAYOUT.base,
    children: [
      {
        path: "",
        name: "finance",
        meta: { roles: ["1_admin", "2_manager", "3_teamleader", "4_agent"] },
        component: () => import("../../views/Finance.vue")
      },
    ]
  },
  {
    path: "/admin/contracts",
    component: LAYOUT.base,
    children: [
      {
        path: "/admin/contracts",
        name: "contracts",
        meta: { roles: ["1_admin", "2_manager", "3_teamleader", "4_agent"] },
        component: () => import("../../components/Contracts.vue")
      },
    ]
  },
  {
    path: "/admin/leaderboard",
    name: "leaderboard",
    meta: { roles: ["1_admin"] },
    component: () => import(/* webpackChunkName: '' */ "@/modules/AdminFinance/components/LeaderBoard.vue")
  }     
];
