<template>
  <!-- svg :width="width" :height="height" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="19" width="28" height="9" rx="2" :stroke="iconColor" stroke-width="2"/>
    <path d="M15.5 12.332V1.66536" :stroke="iconColor" stroke-width="2" stroke-linecap="round"/>
    <path d="M18.166 4.33203L15.5877 1.75375C15.5389 1.70494 15.4598 1.70494 15.411 1.75375L12.8327 4.33203" :stroke="iconColor" stroke-width="2" stroke-linecap="round"/>
  </svg -->
	<svg :width="width" :height="height" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1.8335 5C1.8335 7.20933 6.6095 9 12.5002 9C18.3908 9 23.1668 7.20933 23.1668 5C23.1668 2.79067 18.3908 1 12.5002 1C6.6095 1 1.8335 2.79067 1.8335 5Z" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M1.8335 5V13C1.8335 15.2093 6.6095 17 12.5002 17C13.1375 17 13.7628 16.9787 14.3695 16.9387M23.1668 13V5" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M1.8335 13V21C1.8335 23.0347 5.88683 24.7147 11.1295 24.9667M21.0602 17.8133C21.5853 17.2882 22.2975 16.9932 23.0402 16.9932C23.7828 16.9932 24.495 17.2882 25.0202 17.8133C25.5453 18.3385 25.8403 19.0507 25.8403 19.7933C25.8403 20.536 25.5453 21.2482 25.0202 21.7733L20.5002 26.3333H16.5002V22.3333L21.0602 17.8133Z" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconexports",
    },
    width: {
      type: [Number, String],
      default: 31,
    },
    height: {
      type: [Number, String],
      default: 29,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
