<template>
  <svg :width="width" :height="height" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.58268 17.9993C13.9549 17.9993 17.4993 14.4549 17.4993 10.0827C17.4993 5.71043 13.9549 2.16602 9.58268 2.16602C5.21043 2.16602 1.66602 5.71043 1.66602 10.0827C1.66602 14.4549 5.21043 17.9993 9.58268 17.9993Z" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.3327 18.8327L16.666 17.166" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconsearch",
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 21,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
