<template>
  <svg :width="width" :height="height" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.2002 13.5875C15.0752 13.575 14.9252 13.575 14.7877 13.5875C11.8127 13.4875 9.4502 11.05 9.4502 8.05C9.4502 4.9875 11.9252 2.5 15.0002 2.5C18.0627 2.5 20.5502 4.9875 20.5502 8.05C20.5377 11.05 18.1752 13.4875 15.2002 13.5875Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.9499 18.2C5.9249 20.225 5.9249 23.525 8.9499 25.5375C12.3874 27.8375 18.0249 27.8375 21.4624 25.5375C24.4874 23.5125 24.4874 20.2125 21.4624 18.2C18.0374 15.9125 12.3999 15.9125 8.9499 18.2Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "IconUser",
    },
    width: {
      type: [Number, String],
      default: 33,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
