<template>
  <v-chip
    :color="computedStatus.color"
    outlined
    small
  >
    <v-icon v-if="computedStatus?.icon" small left>
      {{computedStatus.icon}}
    </v-icon>
    {{computedStatus.text}}
  </v-chip>
</template>

<script>
export default {
  name: 'DynamicClientStatus',

  props: {
    client_status: {
      type: [Number,String],
      default: 1,
    },
    callback_attempts: {
    	type: [Number,String],
    	default: 0,
    },
    incompleted_verification: {
    	type: Boolean,
    	default: false,
    },
    trxOverdue: {
    	type: Boolean,
    	default: false,
    }
  },

  methods : {
   // match with backend/clients/clients.statuses
    statusList() {
  		return {
  			// Active
  			1: {text : 'Activation', color: '#16AA13', icon: 'mdi-window-close', type: 'client', active: true},
  			2: {text : 'Hot Client', color: '#FFA51D', icon: '$vuetify.icons.IconHotClient', type: 'client', active: true},
  			3: {text : 'Verification', color: '#FFC600', icon: 'mdi-check', type: 'client', active: true},
  			4: {text : 'Cold Client', color: '#0E80FF', type: 'client', active: true},
    		5: {text : 'No Status', color: '#828282', type: 'client', active: true},
    		// 55 NA/VM - pseudo
			55: {text : 'No Status: NA/VM', color: '#D4D4D4', icon: 'mdi-window-close', type: 'pseudo', active: true},
    		60: {text : 'Preparation', color: '#F05555', icon: 'mdi-circle-outline', type: 'client', active: true},
			70: {text : 'Transaction', color: '#920000', icon: '$vuetify.icons.IconBitcoinCircle', type: 'client', active: true},
    		// Archived	// Archived
			6: {text : 'Closed Client', color: '#16AA13', type: 'client'},
			7: {text : 'Closed Client NC', color: '#9747FF', type: 'client'},
			8: {text : 'PF - Under Age', color: '#88C1FF', type: 'client'},
			9: {text : 'PF - Broken Number', color: '#0654AC', type: 'client'},
			10: {text : 'PF - Never Invested', color: '#0B71E3', type: 'client'},
			11: {text : 'NI - Client Refused', color: '#B578E7', type: 'client'},
			12: {text : 'NI - Agent Mistake', color: '#612393', type: 'client'},
			13: {text : 'Verification Failed', color: '#F06595', type: 'client'},
			15: {text : 'PF - Wrong Person', color: '#4BA0FF', type: 'client'},
			20: {text : 'NA / VM', color: '#D4D4D4', type: 'client'},
			25: {text : 'Preparation Failed', color: '#FFA51D', type: 'client'},
			30: {text : 'Transaction Failed', color: '#CF0000', type: 'client'},
			// Transactions statuses
			'Canceled': {text : 'Canceled', color: 'redDark' , icon: 'mdi-window-close', type: 'transaction'},
    	    'Complete': {text : 'Complete', color: 'success', icon: 'mdi-check', type: 'transaction'},
    	    'Pending' : {text : 'Pending', color: this.trxOverdue?'red':'orange', icon: 'mdi-circle-outline', type: 'transaction'},
    	    'Verification' : {text : 'Verification', color: 'purple', icon: 'mdi-circle-outline', type: 'transaction'},
			// Contract status
			'Close': {text : 'Close', color: 'redDark' , icon: 'mdi-window-close', type: 'contract'},
    	    'Done': {text : 'Done', color: 'success', icon: 'mdi-check', type: 'contract'},
    	    'Open' : {text : 'Open', color: 'orange', icon: 'mdi-circle-outline', type: 'contract'},
			
		};
  	},
  },

  computed: {
  	computedStatus() {
  		const statusList = this.statusList();
  		// pseudo status replace
  		if (parseInt(this.client_status) === 5 && parseInt(this.callback_attempts) > 0) {
        	return {text : 'NA/VM', color: 'gray2', icon: 'mdi-window-close'};  			
  		}
  		//
  		if (this?.incompleted_verification && this.client_status === 3) {
  			let status = statusList[this.client_status];
  			status.icon = 'mdi-circle-outline';
  			return status;
  		}
    	if (statusList[this.client_status]) return statusList[this.client_status];
        return {text : 'Unknown status', color: 'redDark', icon: 'mdi-window-close'};
    }
  }
}
</script>
