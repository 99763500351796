<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M9.00012 17.0623C8.49762 17.0623 7.98762 16.9348 7.53762 16.6723L3.08262 14.0998C2.18262 13.5748 1.62012 12.6073 1.62012 11.5648V6.43484C1.62012 5.39234 2.18262 4.42484 3.08262 3.89984L7.53762 1.32734C8.43762 0.802344 9.55512 0.802344 10.4626 1.32734L14.9176 3.89984C15.8176 4.42484 16.3801 5.39234 16.3801 6.43484V11.5648C16.3801 12.6073 15.8176 13.5748 14.9176 14.0998L10.4626 16.6723C10.0126 16.9348 9.50262 17.0623 9.00012 17.0623ZM9.00012 2.06233C8.69262 2.06233 8.37762 2.14483 8.10012 2.30233L3.64512 4.87483C3.09012 5.19733 2.74512 5.78984 2.74512 6.43484V11.5648C2.74512 12.2023 3.09012 12.8023 3.64512 13.1248L8.10012 15.6973C8.65512 16.0198 9.34512 16.0198 9.90012 15.6973L14.3551 13.1248C14.9101 12.8023 15.2551 12.2098 15.2551 11.5648V6.43484C15.2551 5.79734 14.9101 5.19733 14.3551 4.87483L9.90012 2.30233C9.62262 2.14483 9.30762 2.06233 9.00012 2.06233Z" :fill="iconColor"/>
	<path d="M8.99945 8.81236C7.72445 8.81236 6.68945 7.77735 6.68945 6.50235C6.68945 5.22735 7.72445 4.19238 8.99945 4.19238C10.2745 4.19238 11.3095 5.22735 11.3095 6.50235C11.3095 7.77735 10.2745 8.81236 8.99945 8.81236ZM8.99945 5.31738C8.34695 5.31738 7.81445 5.84985 7.81445 6.50235C7.81445 7.15485 8.34695 7.68736 8.99945 7.68736C9.65195 7.68736 10.1845 7.15485 10.1845 6.50235C10.1845 5.84985 9.65195 5.31738 8.99945 5.31738Z" :fill="iconColor"/>
	<path d="M12 13.0578C11.6925 13.0578 11.4375 12.8028 11.4375 12.4953C11.4375 11.4603 10.3425 10.6128 9 10.6128C7.6575 10.6128 6.5625 11.4603 6.5625 12.4953C6.5625 12.8028 6.3075 13.0578 6 13.0578C5.6925 13.0578 5.4375 12.8028 5.4375 12.4953C5.4375 10.8378 7.035 9.48779 9 9.48779C10.965 9.48779 12.5625 10.8378 12.5625 12.4953C12.5625 12.8028 12.3075 13.0578 12 13.0578Z" :fill="iconColor"/>
  </svg>

</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconReferrer",
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
