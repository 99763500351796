<template>
  <svg :width="width" :height="height" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M9.67334 24.2004V21.4404" :stroke="iconColor" stroke-width="2" stroke-linecap="round"/>
	<path d="M16.5 24.1997V18.6797" :stroke="iconColor" stroke-width="2" stroke-linecap="round"/>
	<path d="M23.3267 24.1996V15.9062" :stroke="iconColor" stroke-width="2" stroke-linecap="round"/>
	<path d="M23.3267 7.7998L22.7133 8.5198C19.3133 12.4931 14.7533 15.3065 9.67334 16.5731" :stroke="iconColor" stroke-width="2" stroke-linecap="round"/>
	<path d="M19.4199 7.7998H23.3266V11.6931" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M12.4998 29.3337H20.4998C27.1665 29.3337 29.8332 26.667 29.8332 20.0003V12.0003C29.8332 5.33366 27.1665 2.66699 20.4998 2.66699H12.4998C5.83317 2.66699 3.1665 5.33366 3.1665 12.0003V20.0003C3.1665 26.667 5.83317 29.3337 12.4998 29.3337Z" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconFinances",
    },
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
