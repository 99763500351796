import i18n from "@/lang/i18n";
import Vue from "vue";
import { transformDate } from "@/helpers/utils";

const rules = {
  required: (v) => !!v || i18n.t("rules.field_required"),
  required_array: (v) => (v && v.length > 0) || i18n.t("rules.field_required"),
  select_multiple_required: (v) => v.length > 0 || i18n.t("rules.field_required"),
  email: (v) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(v) || i18n.t("rules.email_valid");
  },
  min_char7: (v) => v.length >= 7 || i18n.t("rules.min7"),
  max_char12: (v) => (!v || v?.length <= 12) || i18n.t("rules.max12"),
  max_char64: (v) => (!v || v?.length <= 64) || i18n.t("rules.max64"),
  max_char128: (v) => (!v || v?.length <= 128) || i18n.t("rules.max128"),
  strong_char6: (v) => v.length === 6 || i18n.t("rules.strong6"),
  max_char_cardholder_name: (v) => v.length <= 22 || i18n.t("rules.max_char_cardholder_name"),
  digits: v => {
    if (!v) {
      return true;
    }

    const pattern = /\d+/g;
    return pattern.test(v) || i18n.t("rules.only_digits");
  },
  latin: v => {
    const pattern = /^[a-z]+$/;
    return pattern.test(v) || i18n.t("rules.latin_small");
  },
  latin2: v => {
    const pattern = /^[a-z_]+$/;
    return pattern.test(v) || i18n.t("rules.latin_small_and_");
  },
  password: (value) => {
    const pattern =
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (
      pattern.test(value) ||
      "Min. 8 characters with at least one capital letter and number."
    );
  },
  emptyPassword: (value) => {
    if (!value) return true;

    const pattern =
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (
      pattern.test(value) ||
      "Min. 8 characters with at least one capital letter and number."
    );
  },
  password_confirmation: (value, data) => {
    const pattern =
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    return (
      pattern.test(value) &&
      value === data ||
      "Passwords don't match."
    );
  },
  maxSumAddCard: (v) => v <= 5000 || i18n.t("rules.max5000"),
  minSumAddCard: (v) => v > 0 || i18n.t("rules.minLimit"),
  number: (value) => {
    const pattern =
      /^[0-9]+$/;
    return (
      pattern.test(value) ||
      "Only number."
    );
  },
  min: (v) => v >= 0 || "The minimum amount allowed is 0",
  maxAmount: (v, maxAmount) => {
    if (v > maxAmount) {
      return `The maximum allowed is ${maxAmount}`;
    }
    return true;
  },
  age: (birthDate) => {
    if (birthDate?.length >= 10) {
      const userBirthDate = Vue.prototype.$moment(transformDate(birthDate))
      if (userBirthDate.isValid()) {
        const minAge = 18;
        const maxAge = 100;
        const currentDate = Vue.prototype.$moment(new Date());
        const ageDifference = currentDate.format("YYYY") - userBirthDate.format("YYYY")
        if (ageDifference === minAge) {
          const currentMonth = currentDate.format("MM"); // Adding 1 because months are zero-based
          const userBirthMonth = userBirthDate.format("MM");
          const currentDay = currentDate.format("DD");
          const userBirthDay = userBirthDate.format("DD");
          return currentMonth > userBirthMonth || currentMonth === userBirthMonth && currentDay >= userBirthDay || `Age should be between ${minAge} and ${maxAge}`;
        }

        return ageDifference > minAge && ageDifference < maxAge || `Age should be between ${minAge} and ${maxAge}`;
      }
      return "Invalid Date"
    }
    return "Invalid Date";
  },
  cardExpired: (v) => {
    if (v?.length === 5) {
      const [month, year] = v.split("/");
      const currentYear = String(new Date().getFullYear()).substr(-2);
      const currentMonth = new Date().getMonth() + 1;
      const validMonth = month >= 1 && month <= 12;
      const validYear = +currentYear <= +year;
      if (!validMonth) {
        return "Month should be between 1 and 12";
      }
      if (!validYear) {
        return "Year should be bigger or equal to current year";
      }
      if (currentYear === year && month <= currentMonth) {
        return "Month and year should be bigger that current date";
      }
      return true;
    }
    return "Invalid Date";
  }
};


export default rules;
