<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99844 11.1922C11.4344 11.1922 12.5984 10.0281 12.5984 8.59219C12.5984 7.15625 11.4344 5.99219 9.99844 5.99219C8.5625 5.99219 7.39844 7.15625 7.39844 8.59219C7.39844 10.0281 8.5625 11.1922 9.99844 11.1922Z" :stroke="iconColor" stroke-width="1.5"/>
    <path d="M3.0148 7.07435C4.65646 -0.142316 15.3481 -0.133982 16.9815 7.08268C17.9398 11.316 15.3065 14.8994 12.9981 17.116C11.3231 18.7327 8.67313 18.7327 6.9898 17.116C4.6898 14.8994 2.05646 11.3077 3.0148 7.07435Z" :stroke="iconColor" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconusername",
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
