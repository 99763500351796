import axios from "axios";
import axiosWithBearer from "@/helpers/axiosInterceptor";

import { router } from "@/main.js";

const state = {
    token: null,
    refreshToken: null,
    roles: ["1_admin", "2_manager", "3_teamleader", "4_agent", "client"],
    user: null,

    // dynamic component DynamicSnackbar.vue
    snackbar: {
        text: "",
        color: "",
        timeout: ""
    },

    selectedFilters: null,
    isMobileOverflow: false,
    isOverflow: false

};

const mutations = {

    LOGOUT(state) {
        state.user = null;
        state.token = "";
        state.refreshToken = "";
    },
    SET_TOKEN(state, payload) {
        state.token = payload;
    },
    SET_REFRESH_TOKEN(state, payload) {
        state.refreshToken = payload;
    },
    SET_USER(state, payload) {
        state.user = payload;
    },
    SHOW_SNACKBAR_MESSAGE(state, payload) {
        state.snackbar.text = payload.text;
        state.snackbar.color = payload.color;
        state.snackbar.timeout = payload.timeout;
    },
    SET_SELECTED_FILTERS(state, payload) {
        state.selectedFilters = payload;
    },
    DESTROY_ACTIVE_FILTER(state) {
        state.selectedFilters = null;
    },

    SET_MOBILE_OVERFLOW(state) {
        state.isMobileOverflow = !state.isMobileOverflow;
    },

    SET_CURRENT_WHITE_SPACE(state) {
        state.current_white_space = localStorage.getItem("active_spaces");
    },

    SET_ALL_WHITE_SPACES(state) {
        state.all_white_space = JSON.parse(localStorage.getItem("spaces"));
    },

    UPDATE_WHITE_SPACE(state) {
        state.update_white_space = !state.update_white_space;
    },

    CHANGE_IS_OVERFLOW_STATE(state) {
        state.isOverflow = !state.isOverflow;
    }
};

const actions = {

    async axiosLogin({ dispatch }, body) {
        try {
            const { data, status } = await axios.post(`/api/auth/login`, body);
            if (status === 200) {
                if (!data.user?.role) {
                    data.user.role = "client";
                }
                dispatch("_authAddState", data);
                dispatch("_authSetCredentials", data);
                return data;
            }
        } catch (e) {
            console.log("axiosLogin error", e);
            throw e;
        }
    },

  async axiosChangePassword( _, params) {
    return new Promise((resolve, reject) => {
      axiosWithBearer.post(`api/auth/change_password/`, {
        ...params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

    _authAddState({ commit }, response) {
        // console.log("_authAddState");
        commit("SET_TOKEN", response.token);
        commit("SET_REFRESH_TOKEN", response.refresh_token);
        commit("SET_USER", response.user);
    },

    _authSetCredentials(_, data) {
        // console.log("_authSetCredentials");
        const { user, token, refresh_token } = data;
        window.sessionStorage.setItem("user", JSON.stringify(user));
        window.sessionStorage.setItem("token", token);
        window.sessionStorage.setItem("refresh_token", refresh_token);
    },
    _authGetCredentials({ dispatch }) {
        const userFromStorage = window.sessionStorage.getItem("user");
        const user = JSON.parse(userFromStorage);
        const token = window.sessionStorage.getItem("token");
        const refresh_token = window.sessionStorage.getItem("refresh_token");
        // console.log(user, "user");
        const data = { user, token, refresh_token };
        dispatch("_authSetCredentials", data);
        dispatch("_authAddState", data);
    },


    async logout({ commit }) {
        window.sessionStorage.removeItem("token");
        window.sessionStorage.removeItem("user");
        window.sessionStorage.removeItem("refresh_token");
        window.sessionStorage.removeItem("agent_type");
        commit("LOGOUT");
        await router.push({ name: "login" });
    }
};

const getters = {
    user: (state) => state.user,
    userRole: (getters) => getters.user?.role,
    agent_type: (getters) => getters.user?.agent_type,

    activeFilter: (state) => state.selectedFilters,
};

export default {
    state,
    mutations,
    actions,
    getters
};
