<template>
    <v-dialog
      v-model="dialog"
      :max-width="maxWidth ?? 700"
      transition="scale-transition"
      no-click-animation
      persistent
      @keydown.esc="cancel"
    >
      <v-sheet class="pa-5 px-sm-10 pt-sm-8 pb-sm-10 dialog-inner" rounded>
        <v-icon size="14" color="primary" @click.native="cancel" class="dialog-close-icon">
          $vuetify.icons.iconclose
        </v-icon>

        <v-layout justify-center>
          <v-icon :color="iconColor" size="74">$vuetify.icons.icon{{icon}}big</v-icon>
        </v-layout>
        <div class="text-center text-h6 font-weight-bold mt-6">
          {{title}}</div>
        <div class="text-center text-h6 gray2--text mt-6">
          {{message}}</div>

        <slot></slot>

        <v-layout justify-center align-center class="mt-10">
          <v-btn
            outlined
            :color="btnsColor"
            class="mr-2"
            height="60"
            :width="$vuetify.breakpoint.xsOnly?140:200"
            @click.native="cancel"
          >Cancel</v-btn>
          <v-btn
            dark
            :color="btnsColor"
            class="ml-2"
            :class="btnConfirmClass"
            height="60"
            :width="$vuetify.breakpoint.xsOnly?140:200"
            elevation="0"
            @click.native="agree"
            :disabled="disabledConfirm"
          >Confirm</v-btn>

        </v-layout>
      </v-sheet>
    </v-dialog>
</template>

<script>
export default {
  name: "Modal",
  props: {
    maxWidth: [Number, String],
	  disabledConfirm: {
      type: Boolean,
		  default: false,
	  }
  },
  data: () => ({
    // dialog: false,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    btnsColor: null,
    btnConfirmClass: null,
    icon: null,
    iconColor: null,
  }),
  methods: {
    open(title, message, btnsColor, btnConfirmClass, icon, iconColor) {
      this.dialog = true;
      this.title = title;
      this.btnsColor = btnsColor;
      this.btnConfirmClass = btnConfirmClass;
      this.icon = icon;
      this.iconColor = iconColor;
      this.message = message;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
      this.$emit('modal_closed')
    }
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  &-inner {
    position: relative;
    background-color: rgba(255, 255, 255, 0.85);
  }
  &-close-icon {
    position: absolute !important;
    top: 34px;
    right: 34px;
  }
}
</style>
