<template>
  <svg :width="width" :height="height" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7134 14.4927C12.5801 14.4793 12.4201 14.4793 12.2734 14.4927C9.10008 14.386 6.58008 11.786 6.58008 8.58602C6.58008 5.31935 9.22008 2.66602 12.5001 2.66602C15.7667 2.66602 18.4201 5.31935 18.4201 8.58602C18.4067 11.786 15.8867 14.386 12.7134 14.4927Z" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M22.3804 5.33398C24.967 5.33398 27.047 7.42732 27.047 10.0007C27.047 12.5207 25.047 14.574 22.5537 14.6673C22.447 14.654 22.327 14.654 22.207 14.6673" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.04695 19.414C2.82029 21.574 2.82029 25.094 6.04695 27.2407C9.71362 29.694 15.727 29.694 19.3936 27.2407C22.6203 25.0807 22.6203 21.5607 19.3936 19.414C15.7403 16.974 9.72695 16.974 6.04695 19.414Z" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.9531 26.666C25.9131 26.466 26.8198 26.0793 27.5665 25.506C29.6465 23.946 29.6465 21.3727 27.5665 19.8127C26.8331 19.2527 25.9398 18.8793 24.9931 18.666" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconusers",
    },
    width: {
      type: [Number, String],
      default: 33,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
