import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import "@mdi/font/css/materialdesignicons.css";

import IconEye from "@/components/icons/IconEye.vue";
import IconEyeHide from "@/components/icons/IconEyeHide";
import IconLogout from "@/components/icons/IconLogout.vue";
import IconClose from "@/components/icons/IconClose";
import IconTable from "@/components/icons/IconTable";
import IconFile from "@/components/icons/IconFile";
import IconHome from "@/components/icons/IconHome";
import IconUsers from "@/components/icons/IconUsers";
import IconUser from "@/components/icons/IconUser";
import IconClients from "@/components/icons/IconClients";
import IconAgents from "@/components/icons/IconAgents";
import IconExports from "@/components/icons/IconExports";
import IconCalendar from "@/components/icons/IconCalendar";
import IconCalendarDate from "@/components/icons/IconCalendarDate";
import IconMenuUser from "@/components/icons/IconMenuUser";
import IconEmail from "@/components/icons/IconEmail";
import IconWarningBig from "@/components/icons/IconWarningBig";
import IconSuccessBig from "@/components/icons/IconSuccessBig";
import IconDeleteBig from "@/components/icons/IconDeleteBig";
import IconPhone from "@/components/icons/IconPhone";
import IconSearch from "@/components/icons/IconSearch";
import IconEdit from "@/components/icons/IconEdit";
import IconDelete from "@/components/icons/IconDelete";
import IconDownload from "@/components/icons/IconDownload";
import IconTrash from "@/components/icons/IconTrash";
import IconVerify from "@/components/icons/IconVerify";
import IconTeamLead from "@/components/icons/IconTeamLead";
import IconTeamLeadMicro from "@/components/icons/IconTeamLeadMicro";
import IconCrmManager from "@/components/icons/IconCrmManager";
import IconAgent from "@/components/icons/IconAgents.vue";
import IconKYC from "@/components/icons/IconKYC";
import IconPassport from "@/components/icons/IconPassport";
import IconNationalCard from "@/components/icons/IconNationalCard";
import IconDriverLicense from "@/components/icons/IconDriverLicense";
import IconWallet from "@/components/icons/IconWallet";
import IconWalletFrozen from "@/components/icons/IconWalletFrozen.vue";
import IconFunds from "@/components/icons/IconFunds";
import IconDealing from "@/components/icons/IconDealing.vue";
import IconInvestor from "@/components/icons/IconInvestor.vue";
import IconCardCoin from "@/components/icons/IconCardCoin.vue";
import IconNotification from "@/components/icons/IconNotification";
import IconSuccess from "@/components/icons/IconSuccess.vue";
import IconUsername from "@/components/icons/IconUsername";
import iconLeadsDistributed from "@/components/icons/IconLeadsDistributed.vue";
import iconClientsRegistered from "@/components/icons/IconClientsRegistered.vue";
import iconDeals from "@/components/icons/IconDeals.vue";
import iconTurnover from "@/components/icons/IconTurnover.vue";
import IconArrowFall from "@/components/icons/IconArrowFall.vue";
import IconArrowRise from "@/components/icons/IconArrowRise.vue";
import IconSettings from "@/components/icons/IconSettings.vue";
import iconTime from "@/components/icons/IconTime.vue";
import iconTimePaused from "@/components/icons/IconTimePaused.vue";
import iconSave from "@/components/icons/IconSave.vue";
import iconDollar from "@/components/icons/IconDollar.vue";
import IconDollarGreen from "@/components/icons/IconDollarGreen.vue";
import IconMoneyOut from "@/components/icons/IconMoneyOut.vue";
import IconTicket from "@/components/icons/IconTicket.vue";

import IconReferral from "@/components/icons/IconReferral.vue";
import IconReferrer from "@/components/icons/IconReferrer.vue";
import IconPercentSquare from "@/components/icons/IconPercentSquare.vue";
import IconPercentRound from "@/components/icons/IconPercentRound.vue";
import IconDollarHead from "@/components/icons/IconDollarHead.vue";

import IconDSin_work from "@/components/icons/IconDSin_work.vue";
import IconDSpending from "@/components/icons/IconDSpending.vue";
import IconDSpaused from "@/components/icons/IconDSpaused.vue";
import IconReceipt from "@/components/icons/IconReceipt.vue";
import IconReceiptDark from "@/components/icons/IconReceiptDark.vue";
import IconCopy from "@/components/icons/IconCopy.vue";
import IconRefresh from "@/components/icons/IconRefresh.vue";
import IconFinances from "@/components/icons/IconFinances.vue";
import IconUSD from "@/components/icons/IconUSD.vue";
import IconFTD from "@/components/icons/IconFTD.vue";
import IconUpSale from "@/components/icons/IconUpSale.vue";
import IconContracts from "@/components/icons/IconContracts.vue";
import IconComments from "@/components/icons/IconComments.vue";
import Icon1stPlace from "@/components/icons/Icon1stPlace.vue";
import Icon2ndPlace from "@/components/icons/Icon2ndPlace.vue";
import Icon3rdPlace from "@/components/icons/Icon3rdPlace.vue";
import IconYooMoney from "@/components/icons/IconYooMoney.vue";
import IconContract from "@/components/icons/IconContract.vue";
import IconBitcoinCircle from "@/components/icons/IconBitcoinCircle.vue";
import IconHotClient from "@/components/icons/IconHotClient.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#0E80FF",
        orangeLight1: "#fff0de",
        secondary: "#050038",
        gray: "#1C1C1C",
        gray1: "#AFAFAF",
        gray2: "#828282",
        gray3: "#f3f3f3",
        gray4: "#F8FAFB",
        gray5: "#808080",
        grayDark: "#afafaf",
        black: "#000",
        black2: "#364049",
        purple: "#45109f",
        orange: "#FF9900",
        orangeLight: "#fff0de",
        orangeLight2: "#ffe0bc",
        green: "#0D890B",
        green2: "#16AA13",
        success: "#38c357",
        red: "#E80101",
        redDark: "#B60000",
        white: "#fff",
        orangeLight3: "#F0C591",

        status_active: "#38c357",
        status_not_active: "#afafaf",
        status_closing: "#45109f",
        status_closed: "#000",
        status_soft_block: "#fe8a01",
        status_hard_block: "#ef1d26"
      },
      dark: { primary: "#fff" }
    }
  },
  customVariables: ["../scss/variables.scss"],
  icons: {
    iconfont: "mdi",
    values: {
      iconeye: { component: IconEye },
      iconeyehide: { component: IconEyeHide },
      iconlogout: { component: IconLogout },
      iconclose: { component: IconClose },
      IconTable: { component: IconTable },
      IconFile: { component: IconFile },
      iconhome: { component: IconHome },
      iconusers: { component: IconUsers },
      iconclients: { component: IconClients },
      iconagents: { component: IconAgents },
      iconexports: { component: IconExports },
      iconcalendar: { component: IconCalendar },
      iconcalendardate: { component: IconCalendarDate },
      iconmenuuser: { component: IconMenuUser },
      iconemail: { component: IconEmail },
      iconwarningbig: { component: IconWarningBig },
      iconsuccessbig: { component: IconSuccessBig },
      icondeletebig: { component: IconDeleteBig },
      iconphone: { component: IconPhone },
      iconsearch: { component: IconSearch },
      iconedit: { component: IconEdit },
      icondelete: { component: IconDelete },
      IconDownload: { component: IconDownload },
      IconTrash: { component: IconTrash },
      IconVerify: { component: IconVerify },
      IconTeamLead: { component: IconTeamLead },
      IconTeamLeadMicro: { component: IconTeamLeadMicro },
      IconCrmManager: { component: IconCrmManager },
      IconAgent: { component: IconAgent },
      IconUser: { component: IconUser },
      iconkyc: { component: IconKYC },
      iconpassport: { component: IconPassport },
      iconnationalcard: { component: IconNationalCard },
      icondriverlicense: { component: IconDriverLicense },
      iconWalletFrozen: { component: IconWalletFrozen },
      iconFunds: { component: IconFunds },
      iconDealing: { component: IconDealing },
      iconInvestor: { component: IconInvestor },
      iconWallet: { component: IconWallet },
      iconCardCoin: { component: IconCardCoin },
      iconnotification: { component: IconNotification },
      iconSuccess: { component: IconSuccess },
      iconusername: { component: IconUsername },
      iconLeadsDistributed: { component: iconLeadsDistributed },
      iconClientsRegistered: { component: iconClientsRegistered },
      iconDeals: { component: iconDeals },
      iconTurnover: { component: iconTurnover },
      iconArrowFall: { component: IconArrowFall },
      iconArrowRise: { component: IconArrowRise },
      iconSettings: { component: IconSettings },
      iconTime: { component: iconTime },
      iconTimePaused: { component: iconTimePaused },
      iconSave: { component: iconSave },
      iconDollar: { component: iconDollar },
      IconDollarGreen: { component: IconDollarGreen },
      IconMoneyOut: { component: IconMoneyOut },
      IconTicket: { component: IconTicket },
      IconReferral: { component: IconReferral },
      IconReferrer: { component: IconReferrer },
      IconPercentSquare: { component: IconPercentSquare },
      IconPercentRound: { component: IconPercentRound },
      IconDSin_work: {component: IconDSin_work},
      IconDSpending: {component: IconDSpending},
      IconDSpaused: {component: IconDSpaused},
      IconDollarHead: {component: IconDollarHead},
      IconReceipt: {component: IconReceipt},
      IconReceiptDark: {component: IconReceiptDark},
      IconCopy: {component: IconCopy},
      IconRefresh: {component: IconRefresh},
      IconFinances: {component: IconFinances},
      IconUSD: {component: IconUSD},
      IconFTD: {component: IconFTD},
      IconUpSale: {component: IconUpSale},
      IconContracts: {component: IconContracts},
      IconComments: {component: IconComments},
      Icon1stPlace: {component: Icon1stPlace},
      Icon2ndPlace: {component: Icon2ndPlace},
      Icon3rdPlace: {component: Icon3rdPlace},
      IconYooMoney: {component: IconYooMoney},
      IconContract: {component: IconContract},
      IconBitcoinCircle: {component: IconBitcoinCircle},
      IconHotClient: {component: IconHotClient}
    }
  }
});
