<template>
  <svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 26.6673H6C4.89543 26.6673 4 25.7719 4 24.6673V11.334C4 10.2294 4.89543 9.33398 6 9.33398H26C27.1046 9.33398 28 10.2294 28 11.334V24.6673C28 25.7719 27.1046 26.6673 26 26.6673Z" :stroke="iconColor" stroke-width="2"/>
    <path d="M24 9.33333V6.60322C24 5.28916 22.7544 4.33217 21.4847 4.67075L5.48467 8.93742C4.60917 9.17089 4 9.96379 4 10.8699V12" :stroke="iconColor" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "IconWallet",
    },
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
