import LAYOUT from "@/layout/layout";
import Wrapper from "@/modules/AdminUsers/layout/Users.vue";

export default [
    {
        path: "/admin/users",
        component: LAYOUT.base,
        children: [
            {
                path: "",
                name: "users",
                component: Wrapper,
                redirect: "/admin/users/staff",
                children: [
                    {
                        path: "/admin/users/staff",
                        meta: { roles: ["1_admin"] },
                        name: "staff",
                        component: () => import(/* webpackChunkName: '' */ "@/modules/AdminUsers/views/Users.vue")
                    },
                    {
                        path: "/admin/users/teams",
                        name: "teams",
                        meta: { roles: ["1_admin", "2_manager"] },
                        component: () => import(/* webpackChunkName: '' */ "@/modules/AdminUsers/views/Users.vue")
                    },
                    {
                        path: "/admin/users/managers",
                        name: "managers",
                        meta: { roles: ["1_admin"] },
                        component: () => import(/* webpackChunkName: '' */ "@/modules/AdminUsers/views/Users.vue")
                    },
                    {
                        path: "/admin/users/agents",
                        meta: { roles: ["1_admin", "3_teamleader", "2_manager"] },
                        name: "agents",
                        component: () => import(/* webpackChunkName: '' */ "@/modules/AdminUsers/views/Users.vue")
                    },
                    {
                        path: "/admin/users/restricted-access",
                        name: "restricted_access",
                        meta: { roles: ["1_admin"] },
                        component: () => import(/* webpackChunkName: '' */ "@/modules/AdminUsers/views/Users.vue")
                    },
                    {
                        path: "/admin/users/distribution",
                        name: "distribution",
                        meta: { roles: ["1_admin"] },
                        component: () => import(/* webpackChunkName: '' */ "@/modules/AdminUsers/views/Distribution.vue")
                    },
                    {
                        path: "/admin/users/allocation",
                        name: "allocation",
                        meta: { roles: ["1_admin"] },
                        component: () => import(/* webpackChunkName: '' */ "@/modules/AdminUsers/views/Allocation.vue")
                    }
                ]
            }

        ]

    }
];
