<template>
  <svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9869 2.97406L7.33354 5.48073C5.80021 6.05406 4.54688 7.8674 4.54688 9.49406V19.4007C4.54688 20.9741 5.58687 23.0407 6.85354 23.9874L12.5869 28.2674C14.4669 29.6807 17.5602 29.6807 19.4402 28.2674L25.1735 23.9874C26.4402 23.0407 27.4802 20.9741 27.4802 19.4007V9.49406C27.4802 7.85406 26.2269 6.04073 24.6935 5.4674L18.0402 2.97406C16.9069 2.56073 15.0935 2.56073 13.9869 2.97406Z" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.0669 15.8269L14.2136 17.9736L19.9469 12.2402" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconkyc",
    },
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
