// import axios from "@/helpers/axiosInterceptor";

const state = {
  clientKYC: {

  },
};

const mutations = {

};

const actions = {

};


const getters = {
};

export default {
  state,
  mutations,
  actions,
  getters,
};
