import LAYOUT from "@/layout/layout";

export default [
  {
    path: "/",
    component: LAYOUT.base,
    children: [
      {
        path: "",
        name: "dashboard",
        meta: {roles: ['client']},
        component: () => import(/* webpackChunkName: '' */ "@/modules/ClientDashboard/views/Dashboard"),
      },
      {
        path: "/wallet",
        name: "wallet",
        meta: {roles: ['client']},
        component: () => import(/* webpackChunkName: '' */ "@/modules/ClientDashboard/views/Wallet.vue"),
      },
      {
        path: "/welcome",
        name: "welcome",
        meta: {roles: ['client']},
        component: () => import(/* webpackChunkName: '' */ "@/modules/ClientDashboard/views/Welcome"),
      },
    ],

  },
];
