/*
 Modules Controls
*/

// List of modules and their activity
const extra_modules_list = [
  {
    component_name: "AdminUsers",
    active: true,
  },
  {
    component_name: "AdminClients",
    active: true
  },
  {
    component_name: "AdminDashboard",
    active: true
  },
  {
    component_name: "AdminExportData",
    active: true
  },
  {
    component_name: "AdminSettings",
    active: true
  },
  {
    component_name: "AdminFinance",
    active: true
  },

  //client
  {
    component_name: "ClientDashboard",
    active: true
  },
  {
    component_name: "ClientKYC",
    active: true
  },
  {
    component_name: "ClientTransactions",
    active: true
  },
  {
    component_name: "ClientSettings",
    active: true
  },
];

module.exports = { extra_modules_list };
