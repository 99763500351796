<template>
  <svg :width="width" :height="height" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M53.375 30.2201C48.2412 29.7113 43.0767 29.4492 37.9275 29.4492C34.875 29.4492 31.8225 29.6034 28.77 29.9117L25.625 30.2201" :stroke="iconColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M34.1035 28.6627L34.4427 26.6432C34.6893 25.1786 34.8743 24.084 37.4798 24.084H41.5189C44.1244 24.084 44.3248 25.2402 44.556 26.6586L44.8952 28.6627" :stroke="iconColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M50.0603 35.0918L49.0582 50.6164C48.8886 53.0368 48.7499 54.9176 44.4486 54.9176H34.5511C30.2499 54.9176 30.1111 53.0368 29.9415 50.6164L28.9395 35.0918" :stroke="iconColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M36.9258 46.4375H42.0595" :stroke="iconColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M35.6465 40.2715H43.3548" :stroke="iconColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="40" cy="40" r="37.5" :stroke="iconColor" stroke-width="3"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "icondeletegbig",
    },
    width: {
      type: [Number, String],
      default: 80,
    },
    height: {
      type: [Number, String],
      default: 80,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
