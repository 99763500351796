<template>
  <svg :width="width" :height="height" viewBox="0 0 17 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.2522 26.4912H1.74818C0.884176 26.4912 0.180176 25.7872 0.180176 24.9232V5.01917C0.180176 4.15517 0.884176 3.45117 1.74818 3.45117H6.54818V5.69117C6.54818 6.07517 6.86818 6.36317 7.22018 6.36317H9.78018C10.1642 6.36317 10.4522 6.04317 10.4522 5.69117V3.45117H15.2522C16.1162 3.45117 16.8202 4.15517 16.8202 5.01917V24.9232C16.8202 25.7872 16.1162 26.4912 15.2522 26.4912ZM1.74818 4.09117C1.23618 4.09117 0.820176 4.50717 0.820176 5.01917V24.9232C0.820176 25.4352 1.23618 25.8512 1.74818 25.8512H15.2522C15.7642 25.8512 16.1802 25.4352 16.1802 24.9232V5.01917C16.1802 4.50717 15.7642 4.09117 15.2522 4.09117H11.0922V5.69117C11.0922 6.42717 10.5162 7.00317 9.78018 7.00317H7.22018C6.48418 7.00317 5.90818 6.39517 5.90818 5.69117V4.09117H1.74818Z" :fill="iconColor"/>
    <path d="M8.49979 15.5162C8.08379 15.5162 7.69979 15.4202 7.34779 15.2602C6.38779 14.8122 5.77979 13.8522 5.77979 12.7962C5.77979 11.2922 6.99579 10.0762 8.49979 10.0762C10.0038 10.0762 11.2198 11.2922 11.2198 12.7962C11.2198 13.8522 10.6118 14.8122 9.65178 15.2602C9.29978 15.4202 8.91579 15.5162 8.49979 15.5162ZM8.49979 10.7162C7.34779 10.7162 6.41979 11.6442 6.41979 12.7962C6.41979 13.5962 6.89979 14.3322 7.60379 14.6842C8.14779 14.9402 8.81979 14.9402 9.36379 14.6842C10.0998 14.3322 10.5478 13.5962 10.5478 12.7962C10.5798 11.6442 9.65179 10.7162 8.49979 10.7162Z" :fill="iconColor"/>
    <path d="M8.72404 20.4759C8.69204 20.4759 8.66004 20.4759 8.62804 20.4759H8.40404C7.34804 20.4759 6.42004 20.4759 5.71604 20.2199C4.85204 19.8999 4.43604 19.2919 4.43604 18.2679C4.43604 16.5719 5.65203 15.0999 7.41203 14.6199L7.54004 14.5879L7.63603 14.6519C8.18004 14.9079 8.85204 14.9079 9.39604 14.6519L9.49203 14.5879L9.62004 14.6199C11.38 15.0679 12.596 16.5719 12.596 18.2679C12.596 19.2599 12.18 19.8999 11.316 20.2199C10.612 20.4759 9.74804 20.4759 8.72404 20.4759ZM8.50004 19.8359H8.59604C9.55604 19.8359 10.452 19.8359 11.06 19.6119C11.668 19.3879 11.924 19.0039 11.924 18.2679C11.924 16.8919 10.964 15.6759 9.55604 15.2919C8.88404 15.5799 8.11603 15.5799 7.47604 15.2919C6.06804 15.7079 5.10804 16.9239 5.10804 18.2679C5.10804 19.0039 5.36403 19.3879 5.97203 19.6119C6.58003 19.8359 7.47604 19.8359 8.43604 19.8359H8.50004Z" :fill="iconColor"/>
    <path d="M13.0762 23.933H3.95623C3.76423 23.933 3.63623 23.805 3.63623 23.613C3.63623 23.421 3.76423 23.293 3.95623 23.293H13.0762C13.2682 23.293 13.3962 23.421 13.3962 23.613C13.3962 23.805 13.2362 23.933 13.0762 23.933Z" :fill="iconColor"/>
    <path d="M12.18 22.3314H4.82C4.628 22.3314 4.5 22.2034 4.5 22.0114C4.5 21.8194 4.628 21.6914 4.82 21.6914H12.18C12.372 21.6914 12.5 21.8194 12.5 22.0114C12.5 22.2034 12.372 22.3314 12.18 22.3314Z" :fill="iconColor"/>
    <path d="M9.7802 7.03581H7.2202C6.4842 7.03581 5.9082 6.42781 5.9082 5.72381V1.81981C5.9082 1.08381 6.4842 0.507812 7.2202 0.507812H9.7802C10.5162 0.507812 11.0922 1.11581 11.0922 1.81981V5.69181C11.0922 6.42781 10.4842 7.03581 9.7802 7.03581ZM7.2202 1.14781C6.8362 1.14781 6.5482 1.46781 6.5482 1.81981V5.69181C6.5482 6.07581 6.8682 6.36381 7.2202 6.36381H9.7802C10.1642 6.36381 10.4522 6.04381 10.4522 5.69181V1.81981C10.4522 1.43581 10.1322 1.14781 9.7802 1.14781H7.2202Z" :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "IconNationalCard",
    },
    width: {
      type: [Number, String],
      default: 17,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
