import { router } from "@/main";
import axios from "@/helpers/axiosInterceptor";

const state = {
  clients: {
    clients: null,
    clients_headers_map: {
      all: {
        fullname: {
          text: "Full name",
          value: "fullname",
          type: "fullname",
          width: "",
          sortable: false
        },
        dataset_name: {
          text: "Dataset Info",
          value: "origin_dataset.createdAt",
          type: "origin_dataset.info",
          width: "",
          sortable: true
        },
        /*origin_dataset_name: {
          text: "Origin Dataset",
          value: "origin_dataset.dataset_name",
          type: "origin_dataset.dataset_name",
          width: "",
          sortable: false
        },
        */
        country: {
          text: "ISO Country",
          value: "iso_country",
          type: "iso_country",
          width: "",
          sortable: true
        },
        "agent.fullname": {
          text: "Agent Full Name",
          value: "agent.fullname",
          type: "agent.fullname",
          width: "",
          sortable: true
        },
        "agent.teamleader.fullname": {
          text: "Team Leader",
          value: "agent.teamleader.fullname",
          type: "agent.teamleader.fullname",
          width: "",
          sortable: false
        },
        rating: {
          text: "Rating",
          value: "rating",
          type: "rating",
          width: "",
          sortable: true
        },
//         client_type: {
//           text: "Client Type",
//           value: "client_type",
//           type: "client_type",
//           width: "",
//           sortable: true
//         },
        client_status: {
          text: "Client Status",
          value: "client_status",
          type: "client_status",
          width: "",
          sortable: true
        },
//         client_comments: {
//           text: "Comment",
//           value: "client",
//           type: "comment",
//           width: "",
//           sortable: false
//         },
        client_callback: {
          text: "NA / Max",
          value: "callback_attempts",
          type: "client_callback",
          width: "",
          sortable: true
        },
        client_await: {
          text: "Next Attempt at:",
          value: "callback_await",
          type: "client_await",
          width: "140",
          sortable: false
        },
        action_clients: {
          text: "Action",
          value: "action_clients",
          type: "action_clients",
          width: "",
          sortable: false
        }
      },
      archived: {
        fullname: {
          text: "Full name",
          value: "fullname",
          type: "fullname",
          width: "",
          sortable: false
        },
        dataset_name: {
          text: "Dataset Info",
          value: "origin_dataset.createdAt",
          type: "origin_dataset.info",
          width: "",
          sortable: true
        },
        /*origin_dataset_name: {
          text: "Origin Dataset",
          value: "origin_dataset.dataset_name",
          type: "origin_dataset.dataset_name",
          width: "",
          sortable: false
        },*/
        country: {
          text: "ISO Country",
          value: "iso_country",
          type: "iso_country",
          width: "",
          sortable: true
        },
        "agent.fullname": {
          text: "Agent Full Name",
          value: "agent.fullname",
          type: "agent.fullname",
          width: "",
          sortable: true
        },
        "agent.teamleader.fullname": {
          text: "Team Leader",
          value: "agent.teamleader.fullname",
          type: "agent.teamleader.fullname",
          width: "",
          sortable: false
        },
        rating: {
          text: "Rating",
          value: "rating",
          type: "rating",
          width: "",
          sortable: true
        },
        client_type: {
          text: "Client Type",
          value: "client_type",
          type: "client_type",
          width: "",
          sortable: true
        },
        client_status: {
          text: "Client Status",
          value: "client_status",
          type: "client_status",
          width: "",
          sortable: true
        },
        /*
        client_comments: {
          text: "Comment",
          value: "client",
          type: "comment",
          width: "",
          sortable: false
        },
        */
        action_clients: {
          text: "Action",
          value: "action_clients",
          type: "action_clients",
          width: "",
          sortable: false
        }
      },
      my: {
        fullname: {
          text: "Full name",
          value: "fullname",
          type: "fullname",
          width: "",
          sortable: false
        },
        country: {
          text: "Country",
          value: "iso_country",
          type: "iso_country",
          width: "",
          sortable: true
        },
        "agent.fullname": {
          text: "Agent Full Name",
          value: "agent.fullname",
          type: "agent.fullname",
          width: "",
          sortable: true
        },
        rating: {
          text: "Rating",
          value: "rating",
          type: "rating",
          width: "",
          sortable: true
        },
        client_type: {
          text: "Client Type",
          value: "client_type",
          type: "client_type",
          width: "",
          sortable: true
        },
        client_status: {
          text: "Client Status",
          value: "client_status",
          type: "client_status",
          width: "",
          sortable: true
        },
        /*
        client_comments: {
          text: "Comment",
          value: "client",
          type: "comment",
          width: "",
          sortable: false
        },
        */
        action_clients: {
          text: "Action",
          value: "action_clients",
          type: "action_clients",
          width: "",
          sortable: false
        }
      },
      teams: {
        fullname: {
          text: "Full name",
          value: "fullname",
          type: "fullname",
          width: "",
          sortable: false
        },
        dataset_name: {
          text: "Dataset Name",
          value: "origin_dataset.dataset_name",
          type: "origin_dataset.dataset_name",
          width: "",
          sortable: false
        },
        /*origin_dataset_name: {
          text: "Origin Dataset",
          value: "origin_dataset.dataset_name",
          type: "origin_dataset.dataset_name",
          width: "",
          sortable: false
        },
        */
        country: {
          text: "Country",
          value: "iso_country",
          type: "iso_country",
          width: "",
          sortable: true
        },
        "agent.fullname": {
          text: "Agent Full Name",
          value: "agent.fullname",
          type: "agent.fullname",
          width: "",
          sortable: true
        },
        "agent.teamleader.fullname": {
          text: "Team Leader",
          value: "agent.teamleader.fullname",
          type: "agent.teamleader.fullname",
          width: "",
          sortable: false
        },
        rating: {
          text: "Rating",
          value: "rating",
          type: "rating",
          width: "",
          sortable: true
        },
//         client_type: {
//           text: "Client Type",
//           value: "client_type",
//           type: "client_type",
//           width: "",
//           sortable: true
//         },
        client_status: {
          text: "Client Status",
          value: "client_status",
          type: "client_status",
          width: "",
          sortable: true
        },
//         client_comments: {
//           text: "Comment",
//           value: "client",
//           type: "comment",
//           width: "",
//           sortable: false
//         },
        client_callback: {
          text: "NA / Max",
          value: "callback_attempts",
          type: "client_callback",
          width: "",
          sortable: true
        },
        client_await: {
          text: "Next Attempt at:",
          value: "callback_await",
          type: "client_await",
          width: "140",
          sortable: false
        },
        action_clients: {
          text: "Action",
          value: "action_clients",
          type: "action_clients",
          width: "",
          sortable: false
        }
      },
      inwork: {
        fullname: {
          text: "Full name",
          value: "fullname",
          type: "fullname",
          width: "",
          sortable: false
        },
        country: {
          text: "Country",
          value: "iso_country",
          type: "iso_country",
          width: "",
          sortable: true
        },
        rating: {
          text: "Rating",
          value: "rating",
          type: "rating",
          width: "",
          sortable: true
        },
        client_type: {
          text: "Client Type",
          value: "client_type",
          type: "client_type",
          width: "",
          sortable: true
        },
        client_status: {
          text: "Client Status",
          value: "client_status",
          type: "client_status",
          width: "",
          sortable: true
        },
        /*
        client_comments: {
          text: "Comment",
          value: "client",
          type: "comment",
          width: "",
          sortable: false
        },
        */
        action_clients: {
          text: "Action",
          value: "action_clients",
          type: "action_clients",
          width: "",
          sortable: false
        }
      },
    },
    filters: [
      {
        name: "language", description: "Language", type: "OneOf",
        options: [
          { label: "ENG", value: "ENG" },
          { label: "GER", value: "GER" },
          { label: "FRE", value: "FRE" }
        ]
      },
      {
        name: "client_type", description: "Client Type", type: "OneOf",
        options: [{ label: "No Type", value: 0 }, { label: "Solution", value: 1 }, {
          label: "Insurance",
          value: 2
        }]
      },
      // Deprecated; See DynamicClientStatus.vue
      {
        name: "client_status", description: "Client Status", type: "OneOf",
        options: [
          { label: "Activation", value: 1 },
          { label: "Hot Client", value: 2 },
          { label: "Verification", value: 3 },
          { label: "Cold Client", value: 4 },
          { label: "No Status", value: 5 }
        ]
      },
      {
        name: "client_status_archived", description: "Client Status", type: "OneOf",
        options: [
          { label: "Closed Client", value: 6 },
          { label: "Closed Client (No Conversion)", value: 7 },
          { label: "Presentation Failed - No Interest", value: 8 },
          { label: "Presentation Failed - Fake Contacts", value: 9 },
          { label: "Presentation Failed - Callback limit", value: 10 },
          { label: "Registration Failed - No Interest", value: 11 },
          { label: "Registration Failed - Callback limit", value: 12 },
          { label: "Verification Failed", value: 13 },
          { label: "Presentation - No Answer/VM", value: 20 }

        ]
      }
    ],
    showFilters: false,
    paginationState: {},
    //Client
    client_cards: [],
    client_banks: [],
    client_bankings: [],
    //end Client

    // Transactions
    transactions_list: [],
    client_transactions_list : [],
    transaction_currencies: ["BTC", "ETH"],
    transaction_types: ["Withdraw", "Fee", "Deposit"],
    transaction_statuses: ["Pending", "Complete", "Canceled", "Verification"],
    // End Transactions

    // Verification statuses
    verification_statuses_list: [
      {name: 'Submit', value: 1},
      {name: 'Verified', value: 2},
      {name: 'OK', value: 3},
    ],
    // end Verification statuses
    current_route_name: null,
  }
};

const mutations = {
  SET_CURRENT_CLIENTS_ROUTE_NAME(state, name) {
    state.clients.current_route_name = name;
  },
  SET_CLIENTS_LIST(state, payload) {
    state.clients.clients = payload;
  },
  TOGGLE_CLIENTS_SHOW_FILTERS(state) {
    state.clients.showFilters = !state.clients.showFilters;
  },
  SET_PAGINATION_STATE(state, payload) {
    state.clients.paginationState = payload;
  },
  SET_CLIENT_CARDS(state, payload) {
    state.clients.client_cards = payload;
  },
  SET_TRANSACTIONS_LIST(state, payload) {
    state.clients.transactions_list = payload;
  },
  SET_CLIENT_TRANSACTIONS_LIST(state , payload) {
    state.clients.client_transactions_list = payload;
  },

  SET_CLIENT_BANKS(state, payload) {
    state.clients.client_banks = payload;
  },
  
  SET_CBS(state, payload) {
    state.clients.client_bankings = payload;
  },

  UPDATE_CLIENT_FILTER_OPTIONS(state, payload) {
    const indexToUpdate = state.clients.filters
      .findIndex(filter => filter.name === payload.name)

    indexToUpdate !== -1
      ? (state.clients.filters[indexToUpdate].options = payload.options)
      : state.clients.filters.push({ ...payload });
  },
};

const actions = {
  actionSetClientsTableNameByRoute({ commit }) {
    commit("SET_CURRENT_CLIENTS_ROUTE_NAME", router.currentRoute.name);
  },

  async axiosGetClients({ commit, state }, params = {}) {
    try {
      const { status, data } = await axios.post(`/api/clients/${state.clients.current_route_name}/list`,
        { ...params });
      if (status === 200) {
        commit("SET_CLIENTS_LIST", data);
      }
    } catch (e) {
      commit("SET_CLIENTS_LIST", []);
    }
  },
  async axiosGetClientsCustom({ commit }, {params = {}, route = 'all'}) {

    try {
      const { status, data } = await axios.post(`/api/clients/${route}/list`,
        { ...params });
      if (status === 200) {
        commit("SET_CLIENTS_LIST", data);
      }
    } catch (e) {
      commit("SET_CLIENTS_LIST", []);
    }
  },
  async axiosFindClients({ state }, params = null) {
    let route = state.clients.current_route_name
    if (params && params?.with_route) {
      route = params.with_route
      delete params.with_route
      if (route === 'all' && !params?.filter?.client_status) {
      	params.filter = { client_status: [1,3,4,60,70] }
      }
    }

    return new Promise((resolve, reject) => {
      axios.post(`/api/clients/${route}/list`, {
        offset: 0,
        limit: 100,
        order: {
          sortBy: "id",
          sortDesc: true,
        },
        ...params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async axiosFindClientsForDistribution( { state }, params = null) {
  	if (!state) return;
    return new Promise((resolve, reject) => {
      axios.post(`/api/clients/all/list`, {
        offset: 0,
        limit: 100,
        order: {
          sortBy: "id",
          sortDesc: true,
        },
        ...params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async axiosGetClient(_, id) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/clients/retrieve`, {
        id: id
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async axiosGetClientFiles(_, id) {
    return new Promise((resolve, reject) => {
      // const config = { headers: { Authorization: `Bearer ${token}` } };
      axios.post(`/api/clients/retrieve/files`, {
        id: id
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async axiosUpdateClient(_, params) {
    return new Promise((resolve, reject) => {

      // const config = { headers: { Authorization: `Bearer ${token}` } };

      axios.post(`/api/clients/update`, {
        ...params
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async axiosUpdateClientFiles(_, params) {
    return new Promise((resolve, reject) => {

      // const config = { headers: { Authorization: `Bearer ${token}` } };

      let { id, files_json } = { ...params };
      let formData = new FormData();
      for (const [key, value] of Object.entries(files_json)) {
        if (value) {
          formData.append(key, value);
        }
      }
      formData.append("id", id);

      axios.post(`/api/clients/update/files`, formData, {
        headers: { "Content-Type": "application/json" }
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async axiosDeleteClientFile(_, params) {
    return new Promise((resolve, reject) => {
      // const {id, objectName} = { ...params }

      axios.post(`/api/clients/delete/file`, params, {
        headers: { "Content-Type": "application/json" }
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async axiosDeleteClient({ dispatch }, params) {
    const {userId, request} = {...params}
    try {
      const { status } = await axios.delete(`/api/clients/delete`, {
        data: { id: userId }
      });
      if (status === 200) {
        dispatch("axiosGetClients", request);
      }
    } catch (e) {
      console.log(e);
    }
  },

  toggleClientsShowFilters({ commit }) {
    commit("TOGGLE_CLIENTS_SHOW_FILTERS");
  },

  async axiosClientCards({ commit }, params = null) {
    try {
      const { status, data } = await axios.post(`api/cards/list`, {
        filter: { ...params }
      });
      if (status === 200) {
        commit("SET_CLIENT_CARDS", data.rows);
      }
    } catch (e) {
      console.log(e, "e");
      // axiosErrorHandler(e)
    }
  },

  async axiosClientCardRetrieve( _, id ) {
    return new Promise((resolve, reject) => {
      // const {id, objectName} = { ...params }

      axios.post(`/api/cards/retrieve`, {
        id: id
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  axiosClientCardUpdate( { dispatch } , request ) {
    return new Promise((resolve, reject) => {

      // const config = { headers: { Authorization: `Bearer ${token}` } };

      axios.post(`/api/cards/update`, {
        id: request.id,
        data: request,
      })
        .then((response) => {
          dispatch('axiosClientCards', request?.client_id > 0 ? {client_id: request.client_id } : { })
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async axiosGetTransactionList({ commit }, params) {
    try {
      const { status, data } = await axios.post("/api/transactions/list", params);
      if (status === 200) {
        commit("SET_TRANSACTIONS_LIST", data?.rows);
        return data?.rows;
      }
    } catch (e) {
      console.log(e, "axiosGetTransactionList");
    }
  },
  async axiosGetTransactionListById({ commit }, id) {
    try {
      const { status, data } = await axios.post(`/api/transactions/retrieve/`,{id});
      if (status === 200) {
        commit("SET_CLIENT_TRAN SACTIONS_LIST", data?.rows);
        return data?.rows;
      }
    } catch (e) {
      console.log(e, "axiosGetTransactionList");
    }
  },

  async addAgentsInFilters({ dispatch, getters, commit }, params = null) {
    if (getters.clients_filters.filter(i => i.name === 'agent_id')?.length) return

    await dispatch('axiosAgentsList', params)

    let agentsFiler = {
      name: "agent_id", description: "By Team Member", type: "OneOf",
      options: [],
    }

    for (const i of getters.agents_list) {
      agentsFiler.options.push({label: i.fullname, value: i.id})
    }
    commit('UPDATE_CLIENT_FILTER_OPTIONS', agentsFiler)
  },

  async addDatasetsAndCountriesInFilters({ commit }) {
    try {
      const { status, data } = await axios.get(`/api/clients/list/filters`);
      if (status === 200) {
        if (data?.country?.length) {
          let countryFilter = {
            name: "country", description: "Country", type: "OneOf",
            options: [...data.country],
          }
          commit('UPDATE_CLIENT_FILTER_OPTIONS', countryFilter)
        }

        if (data?.dataset_id?.length) {
          let datasetFilter = {
            name: "dataset_id", description: "Dataset Name", type: "OneOf",
            options: [...data.dataset_id],
          }
          commit('UPDATE_CLIENT_FILTER_OPTIONS', datasetFilter)
        }
        
        if (data?.origin_dataset_id?.length) {
          let originDatasetFilter = {
            name: "origin_dataset_id", description: "Origin Dataset Name", type: "OneOf",
            options: [...data.origin_dataset_id],
          }
          commit('UPDATE_CLIENT_FILTER_OPTIONS', originDatasetFilter)
        }
      }
    } catch (e) {
      console.log(e, "e");
      // axiosErrorHandler(e)
    }

    // let agentsFiler = {
    //   name: "agent_id", description: "By Team Member", type: "OneOf",
    //   options: [],
    // }
    //
    // for (const i of getters.agents_list) {
    //   agentsFiler.options.push({label: i.fullname, value: i.id})
    // }
    // commit('UPDATE_CLIENT_FILTER_OPTIONS', agentsFiler)
  },

  async axiosClientBanks({ commit }) {
    try {
      const { status, data } = await axios.get(`api/clients/my_banks/list`);
      if (status === 200) {
        commit("SET_CLIENT_BANKS", data.rows);
      }
    } catch (e) {
      console.log(e, "e");
      // axiosErrorHandler(e)
    }
  },
  
  async axiosCBs({ commit }, params = null) {
    try {
      const { status, data } = await axios.post(`api/cb/list`, {
        filter: { ...params }
      });
      if (status === 200) {
        commit("SET_CBS", data.rows);
      }
    } catch (e) {
      console.log(e, "e");
      // axiosErrorHandler(e)
    }
  },

  async axiosCBRetrieve( _, id ) {
    return new Promise((resolve, reject) => {
      // const {id, objectName} = { ...params }

      axios.post(`/api/cb/retrieve`, {
        id: id
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  axiosCBUpdate( {dispatch}, request ) {
    return new Promise((resolve, reject) => {

      axios.post(`/api/cb/update`, {
        id: request.id,
        data: request,
      })
        .then((response) => {
          if (response?.data?.client_id > 0) {
			dispatch('axiosCBs', {client_id: response.data.client_id});
		  }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  
  async axiosClientComments(_, params) {
    try {
      const { status, data } = await axios.post(`api/logs/list`, params);
      if (status === 200) {
    	 return data;
      }
    } catch (e) {
      console.log(e, "e");
      return false;
      // axiosErrorHandler(e)
    }
  },

  async axiosClientCommentAdd(_, params) {
     try {
       const { status, data } = await axios.post(`api/logs/add`, params);
      if (status === 200) {
    	return data;
      }
    } catch (e) {
      console.log(e, "e");
      return false;
      // axiosErrorHandler(e)
    }
  },
};


const getters = {
  clients_current_route_name: (state) => state.clients.current_route_name,
  clients: (state) => state.clients.clients,
  clients_headers_map: (state) => state.clients.clients_headers_map,
  clients_filters: (state) => state.clients.filters,
  clients_show_filters: (state) => state.clients.showFilters,
  transactionCurrencies: (state) => state.clients.transaction_currencies,
  transactionTypes: (state) => state.clients.transaction_types,
  transactionStatuses: (state) => state.clients.transaction_statuses,
  client_cards: (state) => state.clients.client_cards,
  client_banks: (state) => state.clients.client_banks,
  client_bankings: (state) => state.clients.client_bankings,
  transactionsList: (state) => state.clients.transactions_list,
  clientTransactionsList: (state) => state.clients.client_transactions_list,
  verification_statuses_list: (state) => state.clients.verification_statuses_list,
  clients_pagination_data: (state) => state.clients.paginationState,
};

export default {
  state,
  mutations,
  actions,
  getters
};
