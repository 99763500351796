<template>
  <svg :width="width" :height="height" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.7762 24.846H3.22379C2.5986 24.8453 1.99919 24.5968 1.557 24.1548C1.1148 23.7129 0.865898 23.1136 0.864868 22.4884V5.59024C0.864981 4.96421 1.11339 4.36379 1.55562 3.92067C1.99784 3.47756 2.59776 3.22794 3.22379 3.22656H12.1219C12.2366 3.22656 12.3466 3.27212 12.4277 3.35322C12.5088 3.43432 12.5544 3.54431 12.5544 3.65899C12.5544 3.77368 12.5088 3.88367 12.4277 3.96477C12.3466 4.04587 12.2366 4.09143 12.1219 4.09143H3.22379C2.82707 4.09257 2.44699 4.251 2.16692 4.53197C1.88684 4.81294 1.72962 5.19352 1.72973 5.59024V22.4884C1.73019 22.8845 1.88775 23.2643 2.16784 23.5444C2.44793 23.8244 2.82768 23.982 3.22379 23.9825H28.7762C29.1723 23.982 29.5521 23.8244 29.8322 23.5444C30.1123 23.2643 30.2698 22.8845 30.2703 22.4884V5.59024C30.2704 5.19352 30.1132 4.81294 29.8331 4.53197C29.553 4.251 29.1729 4.09257 28.7762 4.09143H19.8781C19.7634 4.09143 19.6534 4.04587 19.5723 3.96477C19.4912 3.88367 19.4456 3.77368 19.4456 3.65899C19.4456 3.54431 19.4912 3.43432 19.5723 3.35322C19.6534 3.27212 19.7634 3.22656 19.8781 3.22656H28.7762C29.4022 3.22794 30.0022 3.47756 30.4444 3.92067C30.8866 4.36379 31.135 4.96421 31.1351 5.59024V22.4884C31.1341 23.1136 30.8852 23.7129 30.443 24.1548C30.0008 24.5968 29.4014 24.8453 28.7762 24.846Z" :fill="iconColor"/>
    <path d="M19.2817 5.7571H12.7183C12.446 5.75676 12.185 5.64844 11.9924 5.45591C11.7999 5.26338 11.6916 5.00235 11.6913 4.73007V1.17937C11.6916 0.907092 11.7999 0.646063 11.9924 0.453532C12.185 0.261001 12.446 0.152687 12.7183 0.152344H19.2817C19.554 0.152687 19.815 0.261001 20.0076 0.453532C20.2001 0.646063 20.3084 0.907092 20.3088 1.17937V4.73007C20.3084 5.00235 20.2001 5.26338 20.0076 5.45591C19.815 5.64844 19.554 5.75676 19.2817 5.7571ZM12.7183 1.01721C12.6753 1.01732 12.6341 1.03444 12.6037 1.06483C12.5734 1.09522 12.5562 1.1364 12.5561 1.17937V4.73007C12.5562 4.77305 12.5734 4.81423 12.6037 4.84461C12.6341 4.875 12.6753 4.89212 12.7183 4.89224H19.2817C19.3247 4.89212 19.3659 4.875 19.3963 4.84461C19.4267 4.81423 19.4438 4.77305 19.4439 4.73007V1.17937C19.4438 1.1364 19.4267 1.09522 19.3963 1.06483C19.3659 1.03444 19.3247 1.01732 19.2817 1.01721H12.7183Z" :fill="iconColor"/>
    <path d="M9.1066 12.6137C8.58274 12.6137 8.07065 12.4584 7.63509 12.1673C7.19952 11.8763 6.86003 11.4626 6.65956 10.9787C6.45909 10.4947 6.40664 9.96212 6.50884 9.44833C6.61104 8.93454 6.8633 8.4626 7.23372 8.09218C7.60414 7.72176 8.07608 7.4695 8.58987 7.3673C9.10366 7.2651 9.63621 7.31755 10.1202 7.51802C10.6042 7.71849 11.0178 8.05798 11.3089 8.49355C11.5999 8.92911 11.7552 9.4412 11.7552 9.96506C11.7544 10.6673 11.4751 11.3405 10.9786 11.837C10.482 12.3336 9.80882 12.6129 9.1066 12.6137ZM9.1066 8.18127C8.7538 8.18127 8.40892 8.28589 8.11558 8.48189C7.82224 8.6779 7.59361 8.95649 7.4586 9.28243C7.32359 9.60838 7.28826 9.96704 7.35709 10.3131C7.42592 10.6591 7.5958 10.9769 7.84527 11.2264C8.09474 11.4758 8.41258 11.6457 8.7586 11.7146C9.10462 11.7834 9.46328 11.7481 9.78922 11.6131C10.1152 11.478 10.3938 11.2494 10.5898 10.9561C10.7858 10.6627 10.8904 10.3179 10.8904 9.96506C10.8899 9.49211 10.7018 9.03866 10.3674 8.70424C10.033 8.36981 9.57954 8.18173 9.1066 8.18127Z" :fill="iconColor"/>
    <path d="M13.7717 17.1549H4.44151C4.38369 17.1549 4.32645 17.1433 4.27318 17.1208C4.21991 17.0984 4.17169 17.0654 4.13138 17.024C4.09106 16.9825 4.05946 16.9334 4.03846 16.8795C4.01745 16.8257 4.00746 16.7681 4.00908 16.7103C4.04577 15.3828 4.59889 14.122 5.55082 13.196C6.50275 12.27 7.77837 11.752 9.10638 11.752C10.4344 11.752 11.71 12.27 12.6619 13.196C13.6139 14.122 14.167 15.3828 14.2037 16.7103C14.2053 16.7681 14.1953 16.8257 14.1743 16.8795C14.1533 16.9334 14.1217 16.9825 14.0814 17.024C14.0411 17.0654 13.9928 17.0984 13.9396 17.1208C13.8863 17.1433 13.8291 17.1549 13.7712 17.1549H13.7717ZM4.90681 16.29H13.3046C13.1697 15.2722 12.6695 14.3381 11.8973 13.6615C11.125 12.9849 10.1333 12.6119 9.10659 12.6119C8.07989 12.6119 7.08814 12.9849 6.3159 13.6615C5.54366 14.3381 5.04352 15.2722 4.90854 16.29H4.90681Z" :fill="iconColor"/>
    <path d="M27.5585 8.76916H18.0156C17.9009 8.76916 17.7909 8.7236 17.7098 8.6425C17.6287 8.56141 17.5831 8.45142 17.5831 8.33673C17.5831 8.22204 17.6287 8.11205 17.7098 8.03095C17.7909 7.94986 17.9009 7.9043 18.0156 7.9043H27.5585C27.6732 7.9043 27.7832 7.94986 27.8643 8.03095C27.9454 8.11205 27.9909 8.22204 27.9909 8.33673C27.9909 8.45142 27.9454 8.56141 27.8643 8.6425C27.7832 8.7236 27.6732 8.76916 27.5585 8.76916Z" :fill="iconColor"/>
    <path d="M27.5585 12.7653H18.0156C17.9009 12.7653 17.7909 12.7197 17.7098 12.6386C17.6287 12.5575 17.5831 12.4475 17.5831 12.3328C17.5831 12.2181 17.6287 12.1081 17.7098 12.027C17.7909 11.946 17.9009 11.9004 18.0156 11.9004H27.5585C27.6732 11.9004 27.7832 11.946 27.8643 12.027C27.9454 12.1081 27.9909 12.2181 27.9909 12.3328C27.9909 12.4475 27.9454 12.5575 27.8643 12.6386C27.7832 12.7197 27.6732 12.7653 27.5585 12.7653Z" :fill="iconColor"/>
    <path d="M27.5585 16.7613H18.0156C17.9009 16.7613 17.7909 16.7158 17.7098 16.6347C17.6287 16.5536 17.5831 16.4436 17.5831 16.3289C17.5831 16.2142 17.6287 16.1042 17.7098 16.0231C17.7909 15.942 17.9009 15.8965 18.0156 15.8965H27.5585C27.6732 15.8965 27.7832 15.942 27.8643 16.0231C27.9454 16.1042 27.9909 16.2142 27.9909 16.3289C27.9909 16.4436 27.9454 16.5536 27.8643 16.6347C27.7832 16.7158 27.6732 16.7613 27.5585 16.7613Z" :fill="iconColor"/>
    <path d="M27.5585 20.7594H4.44151C4.32682 20.7594 4.21683 20.7138 4.13574 20.6327C4.05464 20.5516 4.00908 20.4417 4.00908 20.327C4.00908 20.2123 4.05464 20.1023 4.13574 20.0212C4.21683 19.9401 4.32682 19.8945 4.44151 19.8945H27.5585C27.6732 19.8945 27.7832 19.9401 27.8643 20.0212C27.9454 20.1023 27.9909 20.2123 27.9909 20.327C27.9909 20.4417 27.9454 20.5516 27.8643 20.6327C27.7832 20.7138 27.6732 20.7594 27.5585 20.7594Z" :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "IconDriverLicense",
    },
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 25,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
