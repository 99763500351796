import axios from 'axios';
import {Config} from '@/constants/config'
import Vue from 'vue'
import { getKeyFromSessionStorage } from "@/helpers/utils";
const instance = axios.create({
    baseURL: Config.VUE_APP_BASEURL,
});
Vue.prototype.$axios = instance;

let isRefreshing = false;
let refreshSubscribers = [];
instance.interceptors.request.use(function (config) {
  // console.log(Vue.prototype , 'prototype');
  const token = sessionStorage.getItem("token");
    if(token) {
        config.headers.Authorization =  `Bearer ${token}`;
    }
    return config;
});
instance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const { config, response: { status } } = error;
        const originalRequest = config;
        if(status === 500 || status === 404 && !isRefreshing && !originalRequest.url.includes('api/auth/check_url')) {
            Vue.prototype.$error(error?.response?.data?.message || 'Something went wrong')
        }
        if (status === 401) {
            if (!isRefreshing) {
                isRefreshing = true;
                refreshToken().then(newToken => {
                    isRefreshing = false;
                    onRefreshed(newToken);
                    refreshSubscribers = [];
                })
            }

            return new Promise((resolve) => {
                refreshSubscribers.push(token => {
                    originalRequest.headers.Authorization = `Bearer ${token}`;
                    resolve(instance(originalRequest));
                });
            });
        }
        if (status === 403) {
            const user = getKeyFromSessionStorage('user')
            if(user?.role) {
                if (user?.role !== 'client') {
                    Vue.prototype.$AppRouter.replace({ name: 'dashboard' });
                } else {
                    Vue.prototype.$AppRouter.replace({ name: 'dashboard' });
                }
            } else {
                Vue.prototype.$AppRouter.replace({ name: 'login' });
            }
        }

        return Promise.reject(error);
    },
);

function refreshToken() {
    // console.log('refreshToken');
    const refresh_token = sessionStorage.getItem('refresh_token')
    return instance.post('/api/auth/login/refresh', {
        refresh_token
    }).then(response => {
        const {data} = response
        const {refresh_token , token} = data
        if(refresh_token && token) {
            const payload = Vue.prototype.$jwtDec.decode(token);
            const userRole = payload?.role
            const agent_type = payload?.agent_type
            const user = getKeyFromSessionStorage('user')
            // console.log(user , 'user');
            if(user && userRole) {
                user.role = userRole
            }
            if(user && agent_type) {
                user.agent_type = agent_type
            }
            const body = {token, refresh_token , user}
            Vue.prototype.$store.dispatch('_authSetCredentials',body)
            Vue.prototype.$store.dispatch('_authAddState',body)
            return token
        }
    }).catch(e => {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("role");
        sessionStorage.removeItem("refresh_token");
        isRefreshing = false;
        Vue.prototype.$error(e?.response?.data?.message || "Your session has expired", "login")
        throw e
    })
}

function onRefreshed(newToken) {
    refreshSubscribers.forEach(subscriber => subscriber(newToken));
}

export default instance;
