<template>
	<svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.166992" width="46" height="46" rx="12" fill="#A5715B"/>
		<path d="M18.5029 20.7444C17.1232 22.1624 16.3557 24.0656 16.3658 26.044C16.3658 28.0469 17.1229 29.9252 18.5029 31.3436C19.1719 32.0382 19.9742 32.5906 20.8618 32.9677C21.7494 33.3448 22.7039 33.5388 23.6683 33.5381C25.6233 33.5381 27.4537 32.7619 28.8337 31.3436C31.68 28.4206 31.68 23.6673 28.8337 20.7444C28.1653 20.0491 27.3631 19.4962 26.4754 19.119C25.5877 18.7419 24.6328 18.5483 23.6683 18.5498C21.7996 18.5498 19.9212 19.2781 18.5029 20.7444ZM26.0258 22.9332L25.0215 24.6678C25.4783 24.9224 25.859 25.2941 26.1245 25.7447C26.3899 26.1952 26.5305 26.7084 26.5318 27.2314C26.5313 28.0065 26.2232 28.7498 25.6752 29.2979C25.1272 29.8461 24.384 30.1545 23.6089 30.1552C23.0071 30.153 22.4206 29.9657 21.9289 29.6187C21.4372 29.2717 21.0641 28.7818 20.8604 28.2156C20.8188 28.1005 20.8005 27.9782 20.8065 27.856C20.8125 27.7338 20.8426 27.6139 20.8952 27.5034C20.9478 27.3929 21.0217 27.2939 21.1128 27.2122C21.2039 27.1305 21.3103 27.0676 21.4258 27.0272C21.6584 26.9457 21.9138 26.9593 22.1364 27.0652C22.359 27.1712 22.5307 27.3607 22.6142 27.5926C22.6871 27.7981 22.8219 27.9758 23.0001 28.1015C23.1782 28.2271 23.3909 28.2944 23.6089 28.2941C24.1935 28.2941 24.6707 27.8159 24.6707 27.2314C24.6707 26.6468 24.1935 26.1695 23.6089 26.1695C23.3606 26.1695 23.1224 26.0709 22.9468 25.8953C22.7713 25.7197 22.6726 25.4816 22.6726 25.2332C22.6735 25.0238 22.7459 24.821 22.8777 24.6582L23.5984 23.4019H22.2538C22.0108 23.3941 21.7804 23.2921 21.6112 23.1174C21.4421 22.9428 21.3475 22.7092 21.3475 22.466C21.3475 22.2229 21.4421 21.9893 21.6112 21.8147C21.7804 21.64 22.0108 21.538 22.2538 21.5302H25.217C25.3811 21.5299 25.5424 21.5729 25.6844 21.6551C25.8265 21.7372 25.9443 21.8555 26.0258 21.9979C26.1072 22.1403 26.15 22.3015 26.15 22.4656C26.15 22.6296 26.1072 22.7908 26.0258 22.9332Z" fill="white"/>
		<path d="M23.6761 13.8227L21.3282 9.96352C21.0024 9.40768 20.4178 9.07227 19.7757 9.07227H13.4603C12.0707 9.07227 11.1986 10.5673 11.879 11.7748C13.1437 14.0122 14.9286 15.9122 17.0828 17.3139C17.422 17.5775 17.7986 17.7845 18.1983 17.9311C15.6903 19.7098 14.0468 22.6806 14.0468 26.0443C14.0468 31.4906 18.3545 35.9056 23.6684 35.9056C28.9824 35.9056 33.2901 31.4906 33.2901 26.0443C33.2901 22.6835 31.6504 19.7165 29.1463 17.9359C29.5533 17.7873 29.9352 17.5776 30.279 17.3139C32.4353 15.9148 34.2178 14.0173 35.4828 11.7748C36.1536 10.5673 35.2815 9.07227 33.8919 9.07227H27.5765C26.9344 9.07227 26.3499 9.40768 26.024 9.96352L23.6761 13.8227ZM23.6684 16.1831C23.5649 16.1831 23.4614 16.185 23.3589 16.1879L26.8472 10.4551L26.8511 10.4484C26.9243 10.3209 27.03 10.215 27.1574 10.1416C27.2848 10.0682 27.4295 10.0299 27.5765 10.0306H33.8919C34.0415 10.031 34.1883 10.0703 34.318 10.1446C34.4478 10.2189 34.556 10.3256 34.6321 10.4544C34.7081 10.5831 34.7494 10.7294 34.7519 10.8789C34.7544 11.0284 34.7179 11.1759 34.6461 11.3071C33.4601 13.4108 31.7826 15.1962 29.7567 16.5108L29.7261 16.53L29.6964 16.553C29.1963 16.9388 28.5944 17.17 27.9646 17.2181C26.6353 16.5363 25.1624 16.1815 23.6684 16.1831ZM17.698 32.1777C16.0969 30.5388 15.2053 28.3355 15.2159 26.0443C15.2159 23.7252 16.0976 21.5498 17.698 19.911C18.4745 19.1085 19.4045 18.4704 20.4327 18.0346C21.4608 17.5988 22.5661 17.3742 23.6828 17.3742C24.7995 17.3742 25.9048 17.5988 26.9329 18.0346C27.9611 18.4704 28.8911 19.1085 29.6676 19.911C32.9643 23.2939 32.9643 28.7948 29.6676 32.1777C28.0576 33.8164 25.9301 34.7173 23.678 34.7173C21.4164 34.7173 19.2889 33.8164 17.698 32.1777Z" fill="white"/>
	</svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "Icon3rdPlace",
    }
  },
};
</script>
