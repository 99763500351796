<template>
	<svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="61" height="60" rx="12" fill="#FFD600"/>
		<path d="M22.5627 27.1563C23.6534 26.0238 24.9616 25.1232 26.4087 24.5083C27.8558 23.8935 29.4122 23.5771 30.9845 23.5781C34.047 23.5781 37.0939 24.7656 39.4064 27.1563C44.047 31.9219 44.047 39.6719 39.4064 44.4375C38.3166 45.5713 37.0087 46.4727 35.5613 47.0877C34.114 47.7026 32.5571 48.0182 30.9845 48.0156C27.8127 48.0156 24.8127 46.75 22.5627 44.4375C20.3131 42.1256 19.0618 39.0226 19.0783 35.7969C19.0783 32.5312 20.3127 29.4688 22.5627 27.1563ZM29.5564 28.5016C29.1527 28.5021 28.7648 28.6589 28.4742 28.9391C28.1835 29.2194 28.0126 29.6012 27.9972 30.0046C27.9819 30.4081 28.1233 30.8018 28.3918 31.1033C28.6603 31.4048 29.0351 31.5906 29.4377 31.6219V40.9375C29.4377 41.3519 29.6023 41.7493 29.8953 42.0424C30.1883 42.3354 30.5858 42.5 31.0002 42.5C31.4146 42.5 31.812 42.3354 32.105 42.0424C32.398 41.7493 32.5627 41.3519 32.5627 40.9375V30.0641C32.5627 29.6497 32.398 29.2522 32.105 28.9592C31.812 28.6662 31.4146 28.5016 31.0002 28.5016H29.5564Z" fill="white"/>
		<path d="M27.1689 9.57813L30.997 15.8703L34.8251 9.57813C35.3564 8.67188 36.3095 8.125 37.3564 8.125H47.6533C49.9189 8.125 51.3408 10.5625 50.247 12.5313C48.1894 16.1824 45.2783 19.2811 41.7626 21.5625C41.2019 21.993 40.5785 22.335 39.9142 22.5766C43.9986 25.4797 46.672 30.3172 46.672 35.7969C46.672 44.6766 39.6486 51.875 30.9845 51.875C22.3204 51.875 15.297 44.6766 15.297 35.7969C15.297 30.3125 17.9767 25.4688 22.0658 22.5688C21.4111 22.3294 20.7976 21.9899 20.247 21.5625C16.7347 19.277 13.8246 16.1793 11.7626 12.5313C10.6533 10.5625 12.0751 8.125 14.3408 8.125H24.6376C25.6845 8.125 26.6376 8.67188 27.1689 9.57813ZM30.4798 19.7266C33.0856 19.6379 35.6709 20.216 37.9908 21.4062C39.017 21.3275 39.9978 20.9506 40.8126 20.3219L40.8595 20.2844L40.9126 20.2531C44.215 18.1095 46.9496 15.1985 48.8829 11.7687C49 11.5549 49.0594 11.3143 49.0553 11.0705C49.0513 10.8268 48.984 10.5883 48.86 10.3784C48.736 10.1685 48.5596 9.99448 48.348 9.87336C48.1364 9.75224 47.897 9.68819 47.6533 9.6875H37.3564C37.1163 9.68608 36.8802 9.7484 36.6721 9.86809C36.4641 9.98777 36.2915 10.1605 36.172 10.3688L36.1658 10.3797L30.4798 19.7266ZM21.2501 45.7969C22.5121 47.1089 24.0264 48.1521 25.7019 48.8637C27.3775 49.5752 29.1797 49.9405 31.0001 49.9375C34.672 49.9375 38.1408 48.4688 40.7658 45.7969C46.1408 40.2812 46.1408 31.3125 40.7658 25.7969C39.4997 24.4884 37.9834 23.448 36.3071 22.7374C34.6307 22.0269 32.8286 21.6607 31.0079 21.6607C29.1872 21.6607 27.3851 22.0269 25.7088 22.7374C24.0325 23.448 22.5162 24.4884 21.2501 25.7969C18.6397 28.469 17.1859 32.0613 17.2033 35.7969C17.2033 39.5781 18.6408 43.125 21.2501 45.7969Z" fill="white"/>
	</svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "Icon1stPlace",
    }
  },
};
</script>
