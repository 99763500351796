<template>
  <svg :width="width" :height="height" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6823 8.91165L21 6.5L18.9297 14.9092L16.3471 12.4298L12.181 16.7694C12.0396 16.9167 11.8442 17 11.64 17C11.4358 17 11.2404 16.9167 11.099 16.7694L8.04 13.583L3.54104 18.2694C3.25419 18.5682 2.77941 18.5779 2.4806 18.291C2.18179 18.0042 2.1721 17.5294 2.45896 17.2306L7.49896 11.9806C7.64039 11.8333 7.83578 11.75 8.04 11.75C8.24422 11.75 8.43961 11.8333 8.58104 11.9806L11.64 15.167L15.265 11.391L12.6823 8.91165Z" :fill="iconColor"/>
  </svg>

</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconarrowrise",
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 25,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
