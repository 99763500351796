<template>
  <svg :width="width" :height="height" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1658 29.3333H21.8325C27.1925 29.3333 28.1525 27.1867 28.4325 24.5733L29.4325 13.9067C29.7925 10.6533 28.8591 8 23.1658 8H9.83245C4.13912 8 3.20578 10.6533 3.56578 13.9067L4.56578 24.5733C4.84578 27.1867 5.80578 29.3333 11.1658 29.3333Z" :stroke="iconColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.166 7.99935V6.93268C11.166 4.57268 11.166 2.66602 15.4327 2.66602H17.566C21.8327 2.66602 21.8327 4.57268 21.8327 6.93268V7.99935" :stroke="iconColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.1673 17.3333V18.6667C19.1673 18.68 19.1673 18.68 19.1673 18.6933C19.1673 20.1467 19.154 21.3333 16.5007 21.3333C13.8607 21.3333 13.834 20.16 13.834 18.7067V17.3333C13.834 16 13.834 16 15.1673 16H17.834C19.1673 16 19.1673 16 19.1673 17.3333Z" :stroke="iconColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M29.366 14.666C26.286 16.906 22.766 18.2393 19.166 18.6927" :stroke="iconColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.99414 15.0273C6.99414 17.0807 10.3808 18.3207 13.8341 18.7073" :stroke="iconColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconclients",
    },
    width: {
      type: [Number, String],
      default: 33,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
