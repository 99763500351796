<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3077 15.2743C18.3077 15.5743 18.241 15.8827 18.0993 16.1827C17.9577 16.4827 17.7744 16.766 17.5327 17.0327C17.1244 17.4827 16.6743 17.8077 16.166 18.016C15.666 18.2244 15.1244 18.3327 14.541 18.3327C13.691 18.3327 12.7827 18.1327 11.8243 17.7244C10.866 17.316 9.90768 16.766 8.95768 16.0744C7.99935 15.3744 7.09102 14.5993 6.22435 13.741C5.36602 12.8743 4.59102 11.966 3.89935 11.016C3.21602 10.066 2.66602 9.11602 2.26602 8.17435C1.86602 7.22435 1.66602 6.31602 1.66602 5.44935C1.66602 4.88268 1.76602 4.34102 1.96602 3.84102C2.16602 3.33268 2.48268 2.86602 2.92435 2.44935C3.45768 1.92435 4.04102 1.66602 4.65768 1.66602C4.89102 1.66602 5.12435 1.71602 5.33268 1.81602C5.54935 1.91602 5.74102 2.06602 5.89102 2.28268L7.82435 5.00768C7.97435 5.21602 8.08268 5.40768 8.15768 5.59102C8.23268 5.76602 8.27435 5.94102 8.27435 6.09935C8.27435 6.29935 8.21602 6.49935 8.09935 6.69102C7.99102 6.88268 7.83268 7.08268 7.63268 7.28268L6.99935 7.94102C6.90768 8.03268 6.86602 8.14102 6.86602 8.27435C6.86602 8.34102 6.87435 8.39935 6.89102 8.46602C6.91602 8.53268 6.94102 8.58268 6.95768 8.63268C7.10768 8.90768 7.36602 9.26602 7.73268 9.69935C8.10768 10.1327 8.50768 10.5743 8.94102 11.016C9.39102 11.4577 9.82435 11.866 10.266 12.241C10.6993 12.6077 11.0577 12.8577 11.341 13.0077C11.3827 13.0243 11.4327 13.0493 11.491 13.0743C11.5577 13.0993 11.6243 13.1077 11.6993 13.1077C11.841 13.1077 11.9493 13.0577 12.041 12.966L12.6743 12.341C12.8827 12.1327 13.0827 11.9743 13.2743 11.8743C13.466 11.7577 13.6577 11.6993 13.866 11.6993C14.0243 11.6993 14.191 11.7327 14.3743 11.8077C14.5577 11.8827 14.7494 11.991 14.9577 12.1327L17.716 14.091C17.9327 14.241 18.0827 14.416 18.1743 14.6243C18.2577 14.8327 18.3077 15.041 18.3077 15.2743Z" :stroke="iconColor" stroke-width="1.5" stroke-miterlimit="10"/>
  </svg>


</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconphone",
    },
    width: {
      type: [Number, String],
      default: 20,
    },
    height: {
      type: [Number, String],
      default: 20,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
