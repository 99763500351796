<template>
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M9.3999 0C4.42981 0 0.399902 4.02991 0.399902 9C0.399902 13.9701 4.42981 18 9.3999 18C14.37 18 18.3999 13.9701 18.3999 9C18.3999 4.02991 14.37 0 9.3999 0ZM9.84789 13.3634L9.85191 14.0002C9.85191 14.0886 9.77959 14.1629 9.6912 14.1629H9.12066C9.03227 14.1629 8.95995 14.0906 8.95995 14.0022V13.3714C7.17602 13.2388 6.33629 12.2223 6.24588 11.1174C6.23785 11.023 6.31218 10.9426 6.4066 10.9426H7.33472C7.41307 10.9426 7.48138 10.9989 7.49343 11.0752C7.59588 11.7121 8.09209 12.1882 8.98205 12.3067V9.43996L8.48584 9.31339C7.43517 9.06228 6.43472 8.40737 6.43472 7.04933C6.43472 5.58482 7.54767 4.79732 8.96999 4.65871V3.99576C8.96999 3.90737 9.04231 3.83504 9.13071 3.83504H9.69521C9.78361 3.83504 9.85593 3.90737 9.85593 3.99576V4.65268C11.232 4.79129 12.2646 5.59487 12.3852 6.84643C12.3952 6.94085 12.3209 7.02321 12.2245 7.02321H11.3224C11.2421 7.02321 11.1738 6.96295 11.1637 6.8846C11.0834 6.29799 10.6133 5.81987 9.84789 5.7154V8.41339L10.3582 8.53192C11.6599 8.85335 12.5459 9.47612 12.5459 10.8703C12.5459 12.383 11.4209 13.2268 9.84789 13.3634ZM7.68428 6.95692C7.68428 7.46719 7.99968 7.86295 8.6787 8.10804C8.77312 8.14621 8.86754 8.17634 8.98004 8.20848V5.71741C8.23874 5.81183 7.68428 6.22768 7.68428 6.95692ZM10.0247 9.67299C9.96843 9.66094 9.91218 9.64687 9.84789 9.62879V12.3147C10.7037 12.2384 11.2943 11.7683 11.2943 10.9808C11.2943 10.3641 10.9749 9.96228 10.0247 9.67299Z" :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "IconUSD",
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
