<template>
  <svg :width="width" :height="height" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.49935 15.5827C12.4114 15.5827 15.5827 12.4114 15.5827 8.49935C15.5827 4.58733 12.4114 1.41602 8.49935 1.41602C4.58733 1.41602 1.41602 4.58733 1.41602 8.49935C1.41602 12.4114 4.58733 15.5827 8.49935 15.5827Z" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.67383 10.278C5.80133 10.4905 5.95716 10.6888 6.13424 10.8659C7.43757 12.1692 9.55548 12.1692 10.8659 10.8659C11.3972 10.3346 11.7017 9.66169 11.8009 8.97461" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.19922 8.02503C5.29839 7.33086 5.60297 6.665 6.13422 6.13375C7.43756 4.83042 9.55547 4.83042 10.8659 6.13375C11.0501 6.31792 11.1988 6.51626 11.3263 6.72168" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.53906 12.1686V10.2773H7.4303" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.4615 4.83008V6.72132H9.57031" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconTurnover",
    },
    width: {
      type: [Number, String],
      default: 17,
    },
    height: {
      type: [Number, String],
      default: 17,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
