<template>
  <svg :width="width" :height="height" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.1673 14.6667C27.1673 8.77563 22.3917 4 16.5007 4C10.6096 4 5.83398 8.77563 5.83398 14.6667" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.16602 21.1052V17.5616C3.16602 16.6439 3.79061 15.8439 4.68095 15.6213L7.75383 14.8531C8.13252 14.7585 8.49935 15.0449 8.49935 15.4352V23.2316C8.49935 23.622 8.13251 23.9084 7.75383 23.8137L4.68094 23.0455C3.79061 22.8229 3.16602 22.0229 3.16602 21.1052Z" :stroke="iconColor" stroke-width="2"/>
    <path d="M29.8333 21.1052V17.5616C29.8333 16.6439 29.2087 15.8439 28.3184 15.6213L25.2455 14.8531C24.8668 14.7585 24.5 15.0449 24.5 15.4352V23.2316C24.5 23.622 24.8668 23.9084 25.2455 23.8137L28.3184 23.0455C29.2087 22.8229 29.8333 22.0229 29.8333 21.1052Z" :stroke="iconColor" stroke-width="2"/>
    <path d="M27.1673 24V25.3333C27.1673 26.4379 26.2719 27.3333 25.1673 27.3333H19.834" :stroke="iconColor" stroke-width="2"/>
    <path d="M18.5 29.3333H14.5C13.3954 29.3333 12.5 28.4378 12.5 27.3333C12.5 26.2287 13.3954 25.3333 14.5 25.3333H18.5C19.6046 25.3333 20.5 26.2287 20.5 27.3333C20.5 28.4378 19.6046 29.3333 18.5 29.3333Z" :stroke="iconColor" stroke-width="2"/>
  </svg>

</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconagents",
    },
    width: {
      type: [Number, String],
      default: 33,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
