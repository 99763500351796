import LAYOUT from "@/layout/layout";

export default [
  {
    path: "/admin/database",
    component: LAYOUT.base,
    children: [
      {
        path: "",
        name: "exports",
        meta: { roles: ["1_admin", "2_manager"] },
        component: () => import("../../views/Exports.vue")
      },
    ]
  }
];
