<template>
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M10.0491 13.0651H8.16664C6.93664 13.0651 5.93914 12.0301 5.93914 10.7551C5.93914 10.4476 6.19414 10.1926 6.50164 10.1926C6.80914 10.1926 7.06414 10.4476 7.06414 10.7551C7.06414 11.4076 7.55914 11.9401 8.16664 11.9401H10.0491C10.5366 11.9401 10.9416 11.5051 10.9416 10.9801C10.9416 10.3276 10.7091 10.2001 10.3266 10.0651L7.30414 9.00006C6.71914 8.79756 5.93164 8.36256 5.93164 7.02006C5.93164 5.86506 6.83914 4.93506 7.94914 4.93506H9.83164C11.0616 4.93506 12.0591 5.97006 12.0591 7.24506C12.0591 7.55256 11.8041 7.80756 11.4966 7.80756C11.1891 7.80756 10.9341 7.55256 10.9341 7.24506C10.9341 6.59256 10.4391 6.06006 9.83164 6.06006H7.94914C7.46164 6.06006 7.05664 6.49506 7.05664 7.02006C7.05664 7.67256 7.28914 7.80006 7.67164 7.93506L10.6941 9.00006C11.2791 9.20256 12.0666 9.63756 12.0666 10.9801C12.0591 12.1276 11.1591 13.0651 10.0491 13.0651Z" :fill="iconColor" />
		<path d="M9 14.0625C8.6925 14.0625 8.4375 13.8075 8.4375 13.5V4.5C8.4375 4.1925 8.6925 3.9375 9 3.9375C9.3075 3.9375 9.5625 4.1925 9.5625 4.5V13.5C9.5625 13.8075 9.3075 14.0625 9 14.0625Z" :fill="iconColor" />
		<path d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625ZM9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 5.175 12.825 2.0625 9 2.0625Z" :fill="iconColor" />
	</svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconDollarGreen",
    },
    iconColor: {
      type: String,
      default: "#0A6408",
    },
  },
};
</script>
