<template>
  <svg :width="width" :height="height" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7506 5.07102C12.7081 5.06393 12.6585 5.06393 12.616 5.07102C11.6385 5.0356 10.8594 4.23518 10.8594 3.24352C10.8594 2.2306 11.674 1.41602 12.6869 1.41602C13.6998 1.41602 14.5144 2.23768 14.5144 3.24352C14.5073 4.23518 13.7281 5.0356 12.7506 5.07102Z" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.0213 10.2278C12.9917 10.3907 14.0613 10.2207 14.8121 9.7178C15.8108 9.05196 15.8108 7.96113 14.8121 7.2953C14.0542 6.79238 12.9704 6.62238 12 6.79238" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.22859 5.07102C4.27109 5.06393 4.32068 5.06393 4.36318 5.07102C5.34068 5.0356 6.11984 4.23518 6.11984 3.24352C6.11984 2.2306 5.30526 1.41602 4.29234 1.41602C3.27943 1.41602 2.46484 2.23768 2.46484 3.24352C2.47193 4.23518 3.25109 5.0356 4.22859 5.07102Z" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.95786 10.2278C3.98745 10.3907 2.91786 10.2207 2.16703 9.7178C1.16828 9.05196 1.16828 7.96113 2.16703 7.2953C2.92495 6.79238 4.0087 6.62238 4.97911 6.79238" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.50062 10.362C8.45812 10.355 8.40854 10.355 8.36604 10.362C7.38854 10.3266 6.60938 9.5262 6.60938 8.53453C6.60938 7.52162 7.42396 6.70703 8.43687 6.70703C9.44979 6.70703 10.2644 7.5287 10.2644 8.53453C10.2573 9.5262 9.47812 10.3337 8.50062 10.362Z" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.43852 12.5924C5.43977 13.2583 5.43977 14.3491 6.43852 15.0149C7.57185 15.7728 9.42768 15.7728 10.561 15.0149C11.5598 14.3491 11.5598 13.2583 10.561 12.5924C9.43477 11.8416 7.57185 11.8416 6.43852 12.5924Z" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconClientsRegistered",
    },
    width: {
      type: [Number, String],
      default: 17,
    },
    height: {
      type: [Number, String],
      default: 17,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
