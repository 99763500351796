import LAYOUT from "@/layout/layout";
import Wrapper from "@/modules/AdminClients/layout/Clients"

export default [
    {
        path: "/admin/clients",
        component: LAYOUT.base,
        meta: { auth: true, permissions: "clients-list-view" },
        children: [
            {
                path: "",
                name: "clients",
                component: Wrapper,
                redirect: "/admin/clients/all",
                children: [
                    {
                        path: "/admin/clients/all",
                        name: "all",
                        meta : {roles : ['1_admin','3_teamleader']},
                        component: () => import(/* webpackChunkName: '' */ "@/modules/AdminClients/views/Clients.vue"),
                    },
                    {
                      path: "/admin/clients/my",
                      name: "my",
                      meta : {roles : ['3_teamleader']},
                      component: () => import(/* webpackChunkName: '' */ "@/modules/AdminClients/views/Clients.vue"),
                    },
                    {
                        path: "/admin/clients/teams",
                        name: "teams",
                        meta : {roles : ['3_teamleader']},
                        component: () => import(/* webpackChunkName: '' */ "@/modules/AdminClients/views/Clients.vue"),
                    },
                    {
                        path: "/admin/clients/archived",
                        name: "archived",
                        meta : {roles : ['1_admin', '3_teamleader', '4_agent']},
                        component: () => import(/* webpackChunkName: '' */ "@/modules/AdminClients/views/Clients.vue"),
                    },
                    {
                      path: "/admin/clients/inwork",
                      name: "inwork",
                      meta : {roles : ['4_agent']},
                      component: () => import(/* webpackChunkName: '' */ "@/modules/AdminClients/views/Clients.vue"),
                    },
                ],
            },
            {
                path: "/admin/clients/:id/profile",
                name: "client-profile",
                component: () => import(/* webpackChunkName: '' */ "@/modules/AdminClients/views/ClientProfile.vue")
            },
            {
                path: "/admin/clients/:id/content",
                name: "client-content",
                component: () => import(/* webpackChunkName: '' */ "@/modules/AdminClients/views/ClientContent.vue")
            }


        ]

    }
];
