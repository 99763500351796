import axios from "@/helpers/axiosInterceptor";

const state = {
  clientDashboard: {
    client_info: null,
    client_transactions_list : []
  },
};

const mutations = {
  SET_CLIENT_INFO(state, payload) {
    state.clientDashboard.client_info = payload
  },
  SET_TRANSACTIONS_CLIENT_LIST(state, payload) {
    state.clientDashboard.client_transactions_list = payload;
  },
  SET_CLIENT_STATS(state, payload) {
    state.clientDashboard.client_stats = payload;
  },
};

const actions = {
  axiosAddCard( _, data ) {
    return new Promise((resolve, reject) => {

      // const config = { headers: { Authorization: `Bearer ${token}` } };

      axios.post(`/api/cards/create`, {
        data: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  axiosDeleteCard( _, id ) {
    return new Promise((resolve, reject) => {

      // const config = { headers: { Authorization: `Bearer ${token}` } };

      axios.delete(`api/cards/delete`, {
        data: {id : id},
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async axiosGetClientInfo({commit}) {
    try {
      const { status, data } = await axios.get(`/api/clients/retrieve/info`);
      if (status === 200) {
        commit("SET_CLIENT_INFO", data);

        //Change has_transactions from back for Access of Client
        let user = JSON.parse( window.sessionStorage.getItem("user") );
        if (user?.has_transactions !== data?.has_transactions) {
          user['has_transactions'] = data?.has_transactions
          window.sessionStorage.setItem("user", JSON.stringify(user));
          document.location.reload();
        }
        //end Change has_transactions from back for Access of Client
      }
    } catch (e) {
      console.log(e, "e");
      // axiosErrorHandler(e)
    }
  },

  async axiosUpdateClientInfo({getters, commit}, params= null) {
    let dataParams = getters.get_client_info
    if (params) {
      dataParams = {...params}
    }

    try {
      const { status, data } = await axios.post(`/api/clients/update/info`, {
        data: dataParams
      });
      if (status === 200) {
        commit("SET_CLIENT_INFO", data);
      }
    } catch (e) {
      console.log(e, "e");
      // axiosErrorHandler(e)
    }
  },
  async axiosUpdateClientInfoFiles(_, files_json) {
    return new Promise((resolve, reject) => {

      let formData = new FormData();
      for (const [key, value] of Object.entries(files_json)) {
        if (value) {
          formData.append(key, value);
        }
      }

      axios.post(`/api/clients/update/info/files`, formData, {
        headers: { "Content-Type": "application/json" }
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async axiosSubmitCardsVerification({dispatch}) {
    try {
      const { status } = await axios.post(`/api/clients/submit/cards`, );
      if (status === 200) {
        dispatch("axiosGetClientInfo");
      }
    } catch (e) {
      console.log(e, "e");
      // axiosErrorHandler(e)
    }
  },
  async axiosSubmitBanksVerification({dispatch}) {
    try {
      const { status } = await axios.post(`/api/clients/submit/bank`, );
      if (status === 200) {
        dispatch("axiosGetClientInfo");
      }
    } catch (e) {
      console.log(e, "e");
      // axiosErrorHandler(e)
    }
  },
  async axiosGetClientTransactionList({ commit }, params = {
    "limit": 18,
    "offset": 0,
    "order": {
      "sortDesc": true,
      "sortBy": "id"
    },
    "filter": {

    }
  }) {
    try {
      const { status, data } = await axios.post("/api/transactions/client_list", params);
      if (status === 200) {
        commit("SET_TRANSACTIONS_CLIENT_LIST", data);
        return data?.rows;
      }
    } catch (e) {
      console.log(e, "axiosGetTransactionList");
    }
  },
  
  
  async axiosGetClientStatistics({ commit }, params=null) {
    try {
      const { status, data } = await axios.post("/api/stats/balance", params);
      if (status === 200) {
        commit("SET_CLIENT_STATS", data);
        return data;
      }
    } catch (e) {
      console.error(e, "axiosGetClientStatistics");
    }
  },

  axiosAddBank( _, data ) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/clients/add/bank`, {
        data: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};


const getters = {
  get_client_info: (state) => state.clientDashboard.client_info,
  get_client_transactions_list: (state) => state.clientDashboard.client_transactions_list,
  get_client_stats: (state) => state.clientDashboard.client_stats
};

export default {
  state,
  mutations,
  actions,
  getters,
};
