<template>
<svg :width="width" :height="height" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.5 25H5.5C3.29086 25 1.5 23.2091 1.5 21V10.5258C1.5 9.12705 2.23062 7.82993 3.42679 7.10497L11.4268 2.25649C12.7011 1.48421 14.299 1.48421 15.5732 2.25649L23.5732 7.10497C24.7694 7.82993 25.5 9.12705 25.5 10.5258V21C25.5 23.2091 23.7091 25 21.5 25H17.5M9.5 25V19.6667C9.5 17.4575 11.2909 15.6667 13.5 15.6667V15.6667C15.7091 15.6667 17.5 17.4575 17.5 19.6667V25M9.5 25H17.5" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconhome",
    },
    width: {
      type: [Number, String],
      default: 27,
    },
    height: {
      type: [Number, String],
      default: 26,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
