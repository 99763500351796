<template>
  <svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M15.6836 6.16406C16.1992 6.67969 16.5 7.36719 16.5 8.09766V20C16.5 21.5469 15.2539 22.75 13.75 22.75H2.75C1.20312 22.75 0 21.5469 0 20V3.5C0 1.99609 1.20312 0.75 2.75 0.75H9.15234C9.88281 0.75 10.5703 1.05078 11.0859 1.56641L15.6836 6.16406ZM9.625 2.25391V6.9375C9.625 7.32422 9.92578 7.625 10.3125 7.625H14.9961C14.9531 7.45312 14.8672 7.28125 14.6953 7.10938L10.1406 2.55469C9.96875 2.38281 9.79688 2.29688 9.625 2.25391ZM15.125 20V9H10.3125C9.15234 9 8.25 8.09766 8.25 6.9375V2.125H2.75C1.97656 2.125 1.375 2.76953 1.375 3.5V20C1.375 20.7734 1.97656 21.375 2.75 21.375H13.75C14.4805 21.375 15.125 20.7734 15.125 20ZM3.4375 4.875C3.05078 4.875 2.75 4.57422 2.75 4.1875C2.75 3.84375 3.05078 3.5 3.4375 3.5H6.1875C6.53125 3.5 6.875 3.84375 6.875 4.1875C6.875 4.57422 6.53125 4.875 6.1875 4.875H3.4375ZM3.4375 7.625C3.05078 7.625 2.75 7.32422 2.75 6.9375C2.75 6.59375 3.05078 6.25 3.4375 6.25H6.1875C6.53125 6.25 6.875 6.59375 6.875 6.9375C6.875 7.32422 6.53125 7.625 6.1875 7.625H3.4375ZM7.73438 16.3906C8.59375 15.7031 10.0547 15.9609 10.5703 16.9922C10.6562 17.1641 10.8281 17.25 11 17.25H13.0625C13.4062 17.25 13.75 17.5938 13.75 17.9375C13.75 18.3242 13.4062 18.625 13.0625 18.625H11C10.3125 18.625 9.66797 18.2383 9.36719 17.6367C9.23828 17.3789 9.02344 17.3359 8.9375 17.3359C8.80859 17.3359 8.59375 17.3789 8.50781 17.5938L8.16406 18.2812C8.03516 18.4961 7.77734 18.625 7.5625 18.625H7.47656C7.21875 18.625 6.96094 18.4531 6.875 18.1523L6.1875 16.0039L5.71484 17.3789C5.45703 18.1523 4.76953 18.625 3.95312 18.625H3.4375C3.05078 18.625 2.75 18.3242 2.75 17.9375C2.75 17.5938 3.05078 17.25 3.4375 17.25H3.95312C4.16797 17.25 4.33984 17.1211 4.42578 16.9492L5.19922 14.5859C5.32812 14.1562 5.71484 13.8984 6.1875 13.8984C6.61719 13.8984 7.00391 14.1562 7.13281 14.5859L7.73438 16.3906Z" :fill="iconColor" fill-opacity="0.65"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "IconContract",
    },
    iconColor: {
      type: String,
      default: "#1C1C1C",
    },
  },
};
</script>
