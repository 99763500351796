<template>
  <svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.66675 9.33366V22.667C4.66675 26.667 6.62675 29.3337 11.3334 29.3337H20.6667C25.2934 29.3337 27.3334 26.667 27.3334 22.667V21.107C27.3334 20.4003 27.0534 19.7203 26.5467 19.227L25.4401 18.1203C24.9334 17.6137 24.6534 16.947 24.6534 16.2403V13.3337C24.6534 12.6003 25.2534 12.0003 25.9867 12.0003C26.7201 12.0003 27.3201 11.4003 27.3201 10.667V9.33366C27.3201 5.33366 25.2801 2.66699 20.6534 2.66699H11.3201C6.62675 2.66699 4.66675 5.33366 4.66675 9.33366Z" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6532 29.2937L10.6399 25.3337C10.6399 23.8537 11.8266 22.667 13.3066 22.667H18.6532C20.1199 22.667 21.3066 23.8537 21.3199 25.3203L21.3599 29.307" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.9199 6.6004L11.9866 2.74707" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.9199 6.6004L15.9866 2.74707" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.9199 6.55973L19.9866 2.71973" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconSave",
    },
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
