<template>
  <div class="pa-10 fill-height d-flex">
    <v-card
      class="fill-height flex-grow-1 pa-6 d-flex flex-column"
      elevation="0"
    >

      <v-layout
        align-center
        justify-space-between
        class="flex-grow-0 mb-5"
      >
        <v-layout align-center justify-start>
          <v-btn
            v-for="(item, index) in filteredTabs"
            :key="index"
            text
            link
            :to="`/admin/clients/${item.url}`"
            color="gray"
            class="mr-1"
          >{{ item.text }}
          </v-btn>
        </v-layout>

        <!-- v-layout align-center justify-end>
          <v-btn
            fab
            small
            class="mr-5"
            @click="toggleShowFilters()"
            elevation="0"
          >
            <v-icon
              :color="clients_show_filters ? 'primary' : 'gray2'"
            >mdi-filter
            </v-icon>
          </v-btn>
		</v-layout -->
	  </v-layout>
	
<!-- Filters set -->

	  <v-row style="max-height:100px;">
		<v-col v-if="['1_admin'].includes(userRole)">	  
		  <v-select
          	outlined
            clearable
            background-color="gray4"
            hide-details
            label="Language"
            v-model="filterObj.language"
            deletable-chips
            :items="users_languages_list"
            :item-text="item => item.label"
            :item-value="item => item.value"
            @change="triggerApplyFilter"
          />
		</v-col>
		<v-col v-if="['1_admin'].includes(userRole)">
    	  <v-autocomplete
          	outlined
            background-color="gray4"
            append-icon="$vuetify.icons.iconsearch"
            label="Find Dataset"
            placeholder="Find Dataset"
            v-model="filterObj.origin_dataset_id"
            chips
            clearable
            hide-details
            hide-selected
            item-text="dataset_name"
            item-value="id"
            height="58"
            @click:clear="filterObj.origin_dataset_id=null;datasets_list = clients_filters?.filter((el) => el.name === 'origin_dataset_id')[0]?.options.map((item)=>({id: item.value, dataset_name: item.label}));"
            :items="datasets_list"
            :loading="ds_loading"
            @change="triggerApplyFilter"
          />
          <!-- :search-input.sync="searchDataset" -->
		</v-col>
		<v-col v-if="['1_admin', '3_teamleader'].includes(userRole) && agents_list && $route.path.indexOf('my')===-1">
      	  <v-autocomplete
          	outlined
            clearable
            background-color="gray4"
            hide-details
            v-model="filterObj.agent_id"
            label="Agent"
            :items="agents_list"
            :item-text="item => item.fullname"
            :item-value="item => item.id"
            @change="triggerApplyFilter"
          >
            <template v-slot:item="{item}">
            	<div>
            		<div v-if="item.role==='3_teamleader'" style="line-height:12px;width:12px;display:inline-block;border-radius:6px;background:#ff7200">&nbsp;&nbsp;</div>
            		{{ item.fullname }}
            	</div>
		  	</template>
          </v-autocomplete>
		</v-col>
		<v-col>
      	  <v-autocomplete
          	outlined
            clearable
            background-color="gray4"
            hide-details
            label="Country"
            v-model="filterObj.iso_country"
            :items="clients_filters.filter((el) => el.name === 'country')[0]?.options"
            :item-text="item => iso_country_list[item.value]"
            :item-value="item => item.value"
            @change="triggerApplyFilter"
            multiple
          >
            <template v-slot:selection="{ item, index }">
       			<v-chip v-if="index < 2">
          			<span>{{ item.label }}</span>
        		</v-chip>
        		<span
         		 v-if="index === 2"
         		 class="grey--text text-caption"
       			>
          			(+{{ filterObj.iso_country.length - 2 }})
        		</span>
      		</template>
          </v-autocomplete>
		</v-col>
		<v-col>
          <!-- v-select
          	outlined
            clearable
            background-color="gray4"
            hide-details
            label="Client Type"
            v-model="filterObj.client_type"
            :items="users_client_types"
            :item-text="item => item.label"
            :item-value="item => item.value"
            @change="doClientFilters"
          / -->
          <v-select
          	v-if="$route.path.indexOf('archive')===-1"
          	outlined
            clearable
            background-color="gray4"
            hide-details
            label="Status"
            v-model="filterObj.client_status"
            multiple
            :items="Object.entries(client_status_list).filter(([key, value]) => { return (value?.type === 'client' || value?.type === 'pseudo') && value?.active && key})"
            :item-text="item => item[1].text"
            :item-value="item => item[0]"
            @change="triggerApplyFilter"
          >
          	<template v-slot:selection="{ item, index }">
       			<v-chip v-if="index < 1">
          			<span>{{ item[1].text }}</span>
        		</v-chip>
        		<span
         		 v-if="index === 1"
         		 class="grey--text text-caption"
       			>
          			(+{{ filterObj.client_status.length - 1 }})
        		</span>
      		</template>
      	  </v-select>
      	  <v-select
          	v-else
          	outlined
            clearable
            background-color="gray4"
            hide-details
            label="Status"
            v-model="filterObj.client_status"
            multiple
            :items="Object.entries(client_status_list).filter(([key, value]) => { return (value?.type === 'client' || value?.type === 'pseudo') && !value?.active && key})"
            :item-text="item => item[1].text"
            :item-value="item => item[0]"
            @change="triggerApplyFilter"
          >
          	<template v-slot:selection="{ item, index }">
       			<v-chip v-if="index < 1">
          			<span>{{ item[1].text }}</span>
        		</v-chip>
        		<span
         		 v-if="index === 1"
         		 class="grey--text text-caption"
       			>
          			(+{{ filterObj.client_status.length - 1 }})
        		</span>
      		</template>
      	  </v-select>
		</v-col>
		<v-col>
		  <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Recent Changed"
                outlined
                background-color="gray4"
                hide-details
                v-model="dateFormatted"
                prepend-inner-icon="$vuetify.icons.iconcalendardate"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                @click:clear="clearDateRange()"
              />
            </template>
            <v-date-picker
              v-model="computedDate"
              no-title
              scrollable
              range
            />
          </v-menu>
		</v-col>
		<v-col>
          <v-text-field
            outlined
            background-color="gray4"
            flat
            label="Full name, username ..."
            required
            class="elevation-0"
            append-icon="$vuetify.icons.iconsearch"
            hide-details
            clearable
            @keyup="textSearch($event)"
            v-model="searchInput"
            @click:clear="clearSearchInput()"
          />
        </v-col>
	  </v-row>
<!-- / Filters set -->
    
      <div class="flex-grow-1">
        <router-view :key="$route.path"></router-view>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { clientTabItems } from "@/helpers/utils";
import DynamicClientStatus from "@/components/dynamic/DynamicClientStatus.vue";

export default {
    name: "Wrapper",

    data: () => ({
        //filters
        filterObj: {
            search: null,
            language: null,
            client_type: null,
            client_status: [],
            iso_country: [],
            origin_dataset_id: null,
            updatedAt: {
         		"Op.between": null,
        	},
        },
        searchDataset: null,
        datasets_list: [],
		ds_loading: false,
        searchInput: null,
        timer: null,
        //end filters
        dateRange: []
    }),

	async beforeMount() {
		this.searchInput = this.clients_pagination_data?.search;
		if (this.clients_pagination_data?.filter) {
			this.filterObj = this.clients_pagination_data.filter;
			if (this.filterObj?.updatedAt?.['Op.between']) {
				this.computedDate = this.filterObj.updatedAt['Op.between'].map(e=>e.replaceAll('/', '-'));
			}
			this.triggerApplyFilter();
		}
		this.datasets_list = this.clients_filters?.filter((el) => el.name === 'origin_dataset_id')[0]?.options.map((item)=>({id: item.value, dataset_name: item.label}));	
	},

    methods: {

        textSearch() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
            	if (this?.searchInput?.length<1) {
            		this.$root.$refs.Clients.clearSearch();
            	} else {
	                this.filterObj.search = this.searchInput;
	            }
                this.triggerApplyFilter();
            }, 2000);
        },

		formattedFilters() {
			let filter = {};
			for(const [key, value] of Object.entries(this.filterObj)) {
				if (key === 'search') continue;
				if (value?.length === 0 || value === null) continue;
				filter[key] = value;
			}
			if (filter?.updatedAt?.["Op.between"] === null) {
				delete filter.updatedAt;
			}
			return filter;
		},

        triggerApplyFilter() {
            let obj = {filter: this.formattedFilters()};
            this.$nextTick(() => {
                if (!this.searchInput) {
                	obj.search = null;
                } else {
                	obj.search = this.searchInput;
                }
                //this.$root.$refs.Clients.$refs.filters_area.clearAllFilters();
                this.$root.$refs.Clients.onApply(obj, false);
            });
        },

        clearSearchInput() {
            this.searchInput = null;
            this.$root.$refs.Clients.clearSearch();
            this.triggerApplyFilter();
        },

        toggleShowFilters() {
            this.$store.dispatch("toggleClientsShowFilters");
        },

        getRouteInfoByUrl(name) {
          const routeByRole = this.allRoutes
            .find(route => route.name === name && route.parent.name === 'clients')
            .meta?.roles

          return routeByRole && routeByRole.indexOf(this.userRole) !== -1
        },

        async findDataset(params) {
	      try {
	        const { data, status } = await this.$axios.post("/api/datasets/list", params);
	        if (status === 200) {
	          return data;
	        }
	      } catch (e) {
	      	console.log(e);
	      }
		},

		clearDateRange() {
      		this.dateRange = []
      		this.filterObj.updatedAt = { "Op.between": null };
      		this.triggerApplyFilter()
    	},
    	
    	formatDate (date) {
      		if(date.length === 2) {
        		const arrBetween = []
        		for (const i in date) {
          			let [year, month, day] = date[i].split('-')
          			arrBetween.push(`${year}/${month}/${day}`)
        		}
        		if (!this.filterObj?.updatedAt) {
        			this.filterObj.updatedAt = {};
        		}
        		this.filterObj.updatedAt["Op.between"] = arrBetween
        		this.triggerApplyFilter()
      		}
	    	return date
    	}
    },
    computed: {
        ...mapGetters([
          "clients_pagination_data",
          "clients_show_filters",
          "userRole",
          "users_languages_list",
          "clients_filters",
          "agents_list",
          "iso_country_list"
        ]),
        allRoutes() {
            return this.$router.matcher.getRoutes();
        },
        filteredTabs() {
            return clientTabItems.filter(tab => Boolean(this.getRouteInfoByUrl(tab.name)))
        },
        client_status_list() {
        	//if (!active) return 1;
        	let list = DynamicClientStatus.methods.statusList();
        	//list[55] = {text: 'No Status: NA/VM', color: 'gray2', icon: 'mdi-window-close', type: 'client', active: true};// pseudo
        	return list;
        },
        dateFormatted: {
    		get() {
	    	    return this.dateRange ? this.formatDate(this.dateRange).join('~') : null
	    	},
	    	set() {}
    	},
    	computedDate: {
      		get() {
        		return this.dateRange
      		},
      		set(val) {
      			const [from , to] = val
        		new Date(from) > new Date(to) ? this.dateRange = val.reverse() : this.dateRange = val
      		}
    	},
    },

  	watch: {
      '$route.path': function(cur,prev) {
    	if (cur?.indexOf('archive')!==-1 || prev?.indexOf('archive')!==-1) {
    		//this.$root.$refs.Clients.$refs.filters_area.clearAllFilters();//clearClientStatusFilter();
    		this.searchInput = this.clients_pagination_data?.search;
    		delete this.filterObj?.client_status;
    		this.triggerApplyFilter();
    	}
    	if (cur?.indexOf('my')!==-1 || prev?.indexOf('my')!==-1) {
    		delete this.filterObj?.agent_id;
    		this.triggerApplyFilter();
    	}
    	//this.searchInput = null
      },
      clients_filters: function() {
    	this.datasets_list = this.clients_filters?.filter((el) => el.name === 'origin_dataset_id')[0]?.options.map((item)=>({id: item.value, dataset_name: item.label}));	
      },
    
	  // deprecated until we load all datasets for filters select
      _searchDataset (val) {
      if (val?.length < 3) return;
      this.ds_loading = true;
      this.findDataset({
      	//filter:{
      	//	id: clients_filters.filter((el) => el.name === 'origin_dataset_id')[0].options
      	//}
      	search: val,
	    offset: 0,
    	limit: 20,
    	order: {
    		sortBy: "id",
    		sortDesc: true
    	}
      })
 	 	.then(res => {
          this.datasets_list = res?.rows
    	})
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.ds_loading = false))
    },
  },
};
</script>

<style lang="scss" scoped></style>
