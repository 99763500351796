<template>
  <svg :width="width" :height="height" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.95833 4.60352V6.72852H14.875V2.47852H4.95833V4.60352ZM4.95833 4.60352H2.125V12.3952H4.95833V4.60352ZM4.95833 12.3952V14.5202H14.875V10.2702H4.95833V12.3952Z" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.95833 4.60352H2.125V12.3952H4.95833" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.95898 10.2715H14.8757V14.5215H4.95898V12.3965V10.2715Z" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.95898 2.47852H14.8757V6.72852H4.95898V4.60352V2.47852Z" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconleadsdistributed",
    },
    width: {
      type: [Number, String],
      default: 17,
    },
    height: {
      type: [Number, String],
      default: 17,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
