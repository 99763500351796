import LAYOUT from "@/layout/layout";

export default [
  {
    path: "/verification",
    component: LAYOUT.base,
    children: [
      {
        path: "",
        name: "kyc",
        meta: {roles: ['client']},
        component: () => import(/* webpackChunkName: '' */ "@/modules/ClientKYC/views/ClientKYC"),
      },
    ],

  },
];
