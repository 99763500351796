<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M9.51123 16.0344V4.18437C9.51123 3.05937 9.03623 2.60938 7.83623 2.60938H4.81123C3.61123 2.60938 3.13623 3.05937 3.13623 4.18437V16.0344C3.13623 17.1594 3.61123 17.6094 4.81123 17.6094H7.83623C9.03623 17.6094 9.51123 17.1594 9.51123 16.0344Z" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M18.1362 16.0344V4.18437C18.1362 3.05937 17.6612 2.60938 16.4612 2.60938H13.4362C12.2446 2.60938 11.7612 3.05937 11.7612 4.18437V16.0344C11.7612 17.1594 12.2362 17.6094 13.4362 17.6094H16.4612C17.6612 17.6094 18.1362 17.1594 18.1362 16.0344Z" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "IconDSpaused",
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
