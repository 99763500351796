import Vue from 'vue'
import axios from "@/helpers/axiosInterceptor";

const state = {
  adminDashboard: {
    users_counter: {
      clients: 0,
      admins: 0,
      agents: 0,
      teamleaders: 0,
      managers: 0,
    },
    users_statistics: null,
  },
};

const mutations = {
  SET_ALL_USERS_COUNTER(state, params) {
    const {type, value} = {...params}
    value
      ? Vue.set(state.adminDashboard.users_counter, type, value)
      : Vue.set(state.adminDashboard.users_counter, type, 0)
  },

  SET_DASHBOARD_USERS_STATISTICS(state, payload) {
    state.adminDashboard.users_statistics = payload
  },
};

const actions = {

  async axiosTransferClient (_, params ) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/clients/transfer`, {
        ...params
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async axiosTransferAgent (_, params ) {
    return new Promise((resolve, reject) => {
      axios.post(`/api/users/agents/transfer`, {
        ...params
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async axiosClientsCounter({commit}) {
    const params = {
      "offset": 0,
      "limit": 0,
      "order": {
        "sortBy": "id",
        "sortDesc": true
      }
    }
    await axios.post(`/api/clients/list`, {...params})
      .then(response => {
        commit('SET_ALL_USERS_COUNTER', {type: 'clients', value: response?.data?.count})
      })
      .catch(error => {
        console.log(error, )
      })
  },
  async axiosTeamLeadersCounter({commit}, filter = null) {
    const params = {
      "offset": 0,
      "limit": 0,
      "order": {
        "sortBy": "id",
        "sortDesc": true
      },
      filter: filter
    }
    await axios.post(`/api/users/teams/list`, {...params})
      .then(response => {
        commit('SET_ALL_USERS_COUNTER', {type: 'teamleaders', value: response?.data?.count})
      })
      .catch(error => {
        console.log(error, )
      })
  },
  async axiosAgentsCounter({commit}, filter = null) {
    const params = {
      "offset": 0,
      "limit": 0,
      "order": {
        "sortBy": "id",
        "sortDesc": true
      },
      filter: {
        "role": ["4_agent"],
        ...filter,
      },
    }
    await axios.post(`/api/users/agents/list`, {...params})
      .then(response => {
        commit('SET_ALL_USERS_COUNTER', {type: 'agents', value: response?.data?.count})
      })
      .catch(error => {
        console.log(error, )
      })
  },
  async axiosManagersCounter({commit}) {
    const params = {
      "offset": 0,
      "limit": 0,
      "order": {
        "sortBy": "id",
        "sortDesc": true
      }
    }
    await axios.post(`/api/users/staff/list`,
      {
        ...params,
        filter: {
          "role": ["2_manager"]
        }
      }
    )
      .then(response => {
        commit('SET_ALL_USERS_COUNTER', {type: 'managers', value: response?.data?.count})
      })
      .catch(error => {
        console.log(error, )
      })
  },
  async axiosAdminsCounter({commit}) {
    const params = {
      "offset": 0,
      "limit": 0,
      "order": {
        "sortBy": "id",
        "sortDesc": true
      }
    }
    await axios.post(`/api/users/staff/list`,
      {
        ...params,
        filter: {
          "role": ["1_admin"]
        }
      }
    )
      .then(response => {
        commit('SET_ALL_USERS_COUNTER', {type: 'admins', value: response?.data?.count})
      })
      .catch(error => {
        console.log(error, )
      })
  },

  async axiosDashboardUsersStatistics({commit}, params) {
    try {
      const { status, data } = await axios.post(`/api/logs/agents_statistics
`, {
        filter: {...params},
      })
      if(status === 200) {
        commit('SET_DASHBOARD_USERS_STATISTICS', data)
      }
    } catch(e) {
      console.log(e , 'e');
    }
  },
};


const getters = {
  users_counter: (state) => state.adminDashboard.users_counter,
  dashboard_user_statistics: (state) => state.adminDashboard.users_statistics,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
