<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M17.98 10.8994V14.8994C17.98 15.1594 17.97 15.4094 17.94 15.6494C17.71 18.3494 16.12 19.6894 13.19 19.6894H12.79C12.54 19.6894 12.3 19.8094 12.15 20.0094L10.95 21.6094C10.42 22.3194 9.56 22.3194 9.03 21.6094L7.82999 20.0094C7.69999 19.8394 7.41 19.6894 7.19 19.6894H6.79001C3.60001 19.6894 2 18.8994 2 14.8994V10.8994C2 7.96938 3.35001 6.37938 6.04001 6.14938C6.28001 6.11938 6.53001 6.10938 6.79001 6.10938H13.19C16.38 6.10938 17.98 7.70938 17.98 10.8994Z" :stroke="iconColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M21.98 6.89938V10.8994C21.98 13.8394 20.63 15.4194 17.94 15.6494C17.97 15.4094 17.98 15.1594 17.98 14.8994V10.8994C17.98 7.70938 16.38 6.10938 13.19 6.10938H6.79004C6.53004 6.10938 6.28004 6.11938 6.04004 6.14938C6.27004 3.45938 7.86004 2.10938 10.79 2.10938H17.19C20.38 2.10938 21.98 3.70938 21.98 6.89938Z" :stroke="iconColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M13.4955 13.3594H13.5045" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M9.9955 13.3594H10.0045" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
	<path d="M6.4955 13.3594H6.5045" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconComments",
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 25,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
