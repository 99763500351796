<template>
  <svg :width="width" :height="height" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7138 15.5709C17.8734 15.5709 15.5709 17.8734 15.5709 20.7138C15.5709 23.554 17.8734 25.8566 20.7138 25.8566C23.554 25.8566 25.8566 23.554 25.8566 20.7138C25.8566 17.8734 23.554 15.5709 20.7138 15.5709ZM14.4277 20.7138C14.4277 17.2422 17.242 14.4277 20.7138 14.4277C24.1852 14.4277 26.9993 17.242 26.9993 20.7138C26.9993 24.1852 24.1851 26.9993 20.7138 26.9993C17.2422 26.9993 14.4277 24.1851 14.4277 20.7138Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.714 4.14289C10.1892 4.14289 8.1426 6.1897 8.1426 8.71445C8.1426 11.2392 10.1892 13.2858 12.714 13.2858C15.2387 13.2858 17.2855 11.2392 17.2855 8.71445C17.2855 6.1897 15.2387 4.14289 12.714 4.14289ZM7 8.71445C7 5.5585 9.5582 3 12.714 3C15.8699 3 18.4284 5.5584 18.4284 8.71445C18.4284 11.8704 15.87 14.4284 12.714 14.4284C9.55801 14.4284 7 11.8702 7 8.71445Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7142 14.4278C7.98044 14.4278 4.14279 18.2655 4.14279 22.9993H3C3 17.6341 7.34924 13.2852 12.7141 13.2852L12.7142 14.4278Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4079 18.7908C22.1848 18.5675 21.8228 18.5675 21.5997 18.7908L19.8776 20.5127C19.6405 20.7499 19.4781 21.0515 19.4106 21.3798L19.2569 22.128L20.0052 21.9744C20.3337 21.9069 20.635 21.7444 20.8723 21.5074L22.5942 19.7853C22.8175 19.5622 22.8175 19.2004 22.5942 18.9771L22.4079 18.7908ZM20.7915 17.9824C21.461 17.3132 22.5466 17.3132 23.216 17.9824L23.4024 18.169C24.0718 18.8384 24.0718 19.924 23.4024 20.5934L21.6805 22.3156C21.2852 22.7107 20.7826 22.9814 20.2352 23.0939L18.6376 23.422C18.4495 23.4609 18.2544 23.4023 18.1187 23.2665C17.9827 23.1306 17.9243 22.9355 17.9628 22.7472L18.2913 21.1498C18.4036 20.6024 18.6743 20.0998 19.0694 19.7047L20.7915 17.9824Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "IconCrmManager",
    },
    width: {
      type: [Number, String],
      default: 30,
    },
    height: {
      type: [Number, String],
      default: 30,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
