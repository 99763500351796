<template>
  <svg :width="width" :height="height" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5 3.33268V1.66602M11.5 3.33268V4.99935M11.5 3.33268H7.75M1.5 8.33268V4.99935C1.5 4.55732 1.67559 4.1334 1.98816 3.82084C2.30072 3.50828 2.72464 3.33268 3.16667 3.33268H4.83333M4.83333 1.66602V4.99935M16.5 8.33268V4.99935C16.5 4.55732 16.3244 4.1334 16.0118 3.82084C15.6993 3.50828 15.2754 3.33268 14.8333 3.33268H14.4167M1.5 8.33268V15.8327C1.5 16.2747 1.67559 16.6986 1.98816 17.0112C2.30072 17.3238 2.72464 17.4993 3.16667 17.4993H14.8333C15.2754 17.4993 15.6993 17.3238 16.0118 17.0112C16.3244 16.6986 16.5 16.2747 16.5 15.8327V8.33268H1.5Z" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconcalendardate",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 19,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
