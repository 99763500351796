<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 6.6665V10.8332" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.4997 18.3333C6.47467 18.3333 3.20801 15.0667 3.20801 11.0417C3.20801 7.01667 6.47467 3.75 10.4997 3.75C14.5247 3.75 17.7913 7.01667 17.7913 11.0417" :stroke="iconColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 1.6665H13" :stroke="iconColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.917 15.4165V14.4498C12.917 13.2581 13.767 12.7665 14.8003 13.3665L15.6337 13.8498L16.467 14.3331C17.5003 14.9331 17.5003 15.9081 16.467 16.5081L15.6337 16.9915L14.8003 17.4748C13.767 18.0748 12.917 17.5831 12.917 16.3915V15.4165Z" :stroke="iconColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "icontime",
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
