import axios from "@/helpers/axiosInterceptor";

const state = {
  settings: {
    app_settings: {
    	// default settings are stored in Edit/modules by section 
    },
    app_settings_loading: false,
    app_settings_saving: false,
  },
};


const mutations = {
  // Full settings. Loading on very start
  SET_APP_SETTINGS(state, payload) {
    state.settings.app_settings = payload;
  },
  SET_SETTINGS(state, payload) {
    state.settings.app_settings[payload.section] = payload;
  },
  SET_SETTINGS_LOADING(state, payload) {
    state.settings.app_settings_loading = payload;
  },
  SET_SETTINGS_SAVING(state, payload) {
    state.settings.app_settings_saving = payload;
  }
};

const actions = {
  async saveAppSettings({ commit }, settings) {
  	try {
  		// check before saving and apply
  		if (settings?.section) {
  			commit("SET_SETTINGS_SAVING", true);
  			const { data, status } = await axios.post("/api/settings/set", settings);
			if (status === 200 && data.section === settings.section) {
				commit("SET_SETTINGS", data);
			} else throw new Error('Wrong data received');
		} else throw new Error('Unexpected data');
	} catch (e) {
		console.error('Save settings fail', e, settings);
	} finally {
		commit("SET_SETTINGS_SAVING", false);
	}
  },

  async loadAppSettings({ commit }) {
  	try {
		commit("SET_SETTINGS_LOADING", true);
  		const { data, status } = await axios.post("/api/settings/get");
		if (status === 200) {
			commit("SET_APP_SETTINGS", data);
		} else throw new Error('Wrong data received');
	} catch (e) {
		console.error('Loading settings fail', e);
	} finally {
		commit("SET_SETTINGS_LOADING", false);
	}
  },

};

const getters = {
  settings_headers_map: (state) => state.settings.headers,
  app_settings: (state) => state.settings.app_settings,
  app_settings_loading: (state) => state.settings.app_settings_loading,
  app_settings_saving: (state) => state.settings.app_settings_saving,
};

export default {
  state,
  mutations,
  actions,
  getters
};
