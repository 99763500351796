<template>
	<v-container class="d-flex align-center">
		<v-btn
			text
			@click="openNotificationWindow()"
		>
			<v-icon class="mr-3" color="redDark">$vuetify.icons.iconnotification</v-icon>
			<span class="redDark--text text-h6" v-if="notifications?.news>0">+{{notifications?.news}}</span>
		</v-btn>
          
		<div class="notifications-holder" v-if="notification_tab">
		<v-card class="notifications-area">
			<div class="text-center">
              	<v-btn
              		text
              		class="closeNotifArea"
              		@click="openNotificationWindow()"
              	>
              	   <v-icon color="redDark">$vuetify.icons.iconclose</v-icon>
              	</v-btn>
              	<v-btn-toggle
            		v-model="notification_tab"
            		color="primary"
            		group
            		class="noti_panel"
          		>
          		<v-btn
            	  elevation="0"
            	  height="40"
            	  :value="'new'"
            	  outlined
            	>Unseen</v-btn>
            	<v-btn
            	  elevation="0"
            	  height="40"
            	  :value="'all'"
            	>Seen</v-btn>
            	<v-btn
            	  elevation="0"
            	  height="40"
            	  :value="'compose'"
            	  v-if="userRole==='1_admin'"
            	>Compose</v-btn>
          		</v-btn-toggle>
          	</div>
            <v-card-text v-if="['new', 'all'].includes(notification_tab)" style="overflow-x:scroll;height: calc(100% - 48px);">
            	<div v-if="notification_tab==='new'&&notifications?.news<1" class="title">Nothing here...</div>
              	<div v-else class="pa-1" v-for="obj in notifications?.rows?.filter(e=>notification_tab==='new'?!e.seen:e.seen)" :key="'noty-'+obj.id">
              		<div 
              			:class="{
              				'mt-5': true,
              				'notification_item': true,
              				'new': notification_tab==='new',
              				'all': notification_tab==='all',
              				'seen': obj.seen
              			}"
              		>
              			<div class="d-flex title align-center">
              				<v-btn
              					:disabled="obj.seen"
              					text
              					height="32"
              					width="32"
              					min-width="auto"
              					title="Mark notification as seen"
              					@click="markAsSeen(obj.id)"
              				>
        		      			<v-icon v-if="obj.seen" :color="'gray2'">$vuetify.icons.iconeyehide</v-icon>
        		      			<v-icon v-else :color="'white'">$vuetify.icons.iconeye</v-icon>
        		      		</v-btn>   
              				<div class="ml-2">{{ obj.data?.title ?? 'Notification #'+obj.id }}</div>
              			</div>
              			<div class="d-flex pa-4">
							<div class="text">
								{{ obj.data.text }}
							</div>
						</div>
						<div class="d-flex pa-1 justify-space-between align-center">
							<v-chip v-if="obj.seen" small>
								<b>{{ obj.type?.toUpperCase() }}</b> 
								&nbsp;from&nbsp;<b>{{ obj.issuer > 0 ? obj.issuer_name : 'System' }}</b>
							</v-chip>
							<v-chip v-else small :color="obj.type==='info'?'primary':'yellow'">
								<b>{{ obj.type?.toUpperCase() }}</b> 
								&nbsp;from&nbsp;<b>{{ obj.issuer > 0 ? obj.issuer_name : 'System' }}</b>
							</v-chip>
							
							<v-chip text small class="text-right">
								{{ new Date(obj.createdat) | moment("DD&nbsp;MMM&nbsp;YYYY") }}
								<div class="ml-1 gray2--text">
									{{ new Date(obj.createdat) | moment("HH:mm:ss") }}
								</div>
							</v-chip>
						</div>
              		 </div>
              	</div>
            </v-card-text>
            <v-card-text v-if="'compose'===notification_tab" style="overflow-x:scroll;height: calc(100% - 48px);">
              		<v-form lazy-validation ref="noti_form">
              			<v-row>
              				<v-col cols="12">
              				  <v-text-field
              				  	background-color="white"
              				  	hide-details="auto"
              				  	placeholder="Notification title"
              				  	outlined
              				  	v-model="notification_compose.data.title"
              				    :rules="[rules.required]"
              				  />              					
              				</v-col>
              				<v-col cols="12">
              				  <v-text-field
              				  	background-color="white"
              				  	hide-details="auto"
              				  	outlined
              				    placeholder="Notification text"
              				    v-model="notification_compose.data.text"
              				    :rules="[rules.required]"
              				  />              					
              				</v-col>
              			</v-row>
              			<v-row>
              				<v-col cols="6">
              					<v-select 
              						:background-color="notification_compose.type==='info'?'primary':'yellow'"
									outlined
									v-model="notification_compose.type"
									:items="['info', 'caution']"
									:rules="[rules.required]"
									:menu-props="menuProps"
									required
								/>
              				</v-col>
              				<v-col cols="6">
              					<v-select 
									outlined
									v-model="notification_compose.issuer"
									:items="[{value:0,text:'System'}, {value:this.user.id, text:'Me'}]"
									:menu-props="menuProps"
									required
								/>
              				</v-col>
              			</v-row>
              			<v-row>
              				<v-col cols="12">
              					Recipient groups:
              					<v-select
              						multiple
              						outlined
              						v-model="notification_compose.recipients"
              						:menu-props="menuProps"
              						:items="['all', 'admin', 'manager', 'teamleader', 'agent']"
              						:rules="[rules.required]"
              						small-chips
              					/>
              					Recipient teams:
              					<v-select
              						multiple
              						outlined
              						v-model="notification_compose.recipients"
              						:menu-props="menuProps"
              						:items="team_leaders_list"
              						:item-value="item=>'team:'+item.id"
              						:item-text="item=>item.team_name+', '+item.language"
              						:rules="[rules.required]"
              						small-chips
              					/>
              				</v-col>
              			</v-row>
              			<v-row>
              				<v-col>
              					<v-btn
            						@click="onSubmit"
            						height="60"
            						width="100%"
            						:loading="loading"
            						class="gradient-primary"
            						color="primary"
            						:disabled="notification_compose.recipients?.length<1 || notification_compose.data?.title?.length<3 || notification_compose.data?.text?.length<3"
            					> Send
            					</v-btn>
              				</v-col>
              			</v-row>
              		</v-form>
            </v-card-text>
        </v-card>
        </div>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import rules from "@/helpers/rules";
import {menuProps, vueToast} from "@/helpers/utils";

export default {
	name: "Notifications",
	mounted() {
        if (!this.user) {
            this._authGetCredentials();
        }
        if (['1_admin', '2_manager', '3_teamleader', '4_agent'].includes(this.user.role) && this.app_settings?.Security?.NOTIFICATIONS) {
        	this.notificationsUpdate();
		}
		// teams
		if (this.user.role === '1_admin') {
			if (this.team_leaders_list.length === 0){
  				this.axiosTeamLeadersList({})
  			}
  		}
    },
    data: () => ({
    	rules,
    	menuProps,
    	loading: false,
        notifications: null,
        notification_tab: false,
        notification_compose: {
        	data: {
        		title: null,
        		text: null
        	},
        	recipients: [],
        	type: 'info',
        	issuer: 0
        },
        windowActiveTimer: null,
    }),
    methods: {
    	...mapActions(["_authGetCredentials", "axiosTeamLeadersList"]),

		notificationsUpdate() {
			if (!this.app_settings?.Security?.NOTIFICATIONS) return;
	  		this.$axios.post("/api/stats/notifications", {
				type: 'latest',
				lastId: this.notifications?.rows?.[0]?.id ?? 0
			}).then(resp=>{
				if (this.notifications?.news) {
					if (resp.data?.news > this.notifications.news) { // got new(s)
						// trigger msg or something 
						for (const noti of resp.data.rows) {
							if (!this.notifications.rows.find(n=>n.id===noti.id)) {
								vueToast(noti.data?.title+' : '+noti.data?.text, noti?.type==='info'?"info":"warning");
								this.notifications.rows.unshift(noti);
							}
						}
						this.notifications.total = resp.data.total;
						this.notifications.news = resp.data.news;
						this.notifications.page = resp.data.page;
					}
				} else {
					this.notifications = resp.data;
				}
				this.windowActiveTimer = setTimeout(()=>this.notificationsUpdate(), 30*1000);
			}).catch(e => {
				console.error('Notification update fail', e);
				this.windowActiveTimer = setTimeout(()=>this.notificationsUpdate(), 60*1000);
			});
		},
		
		markAsSeen(note_id) {
			if (this.notifications?.rows && this.notifications.rows.find(e=>e.id===note_id)) {
				this.notifications.rows.find(e=>e.id===note_id).seen = true;
				this.notifications.news--;
			}
			this.$axios.post("/api/stats/notifications", {
				type: 'seen',
				id: note_id
			}).catch(e => console.error('Notification seen fail', e));
		},

	  	openNotificationWindow() {
	  		this.notification_tab = this.notification_tab ? false : 'new';
	  	},
	  	
	  	async onSubmit() {
	  		const valid = await this.$refs.noti_form.validate();
      		if (!valid) return;
			this.loading = true;
        	try {
        		const body = this.notification_compose;
        		body.action = 'compose';
			    this.$axios.post("/api/stats/notifications", body)
			    	.catch(e => console.error('Notification compose fail', e))
			    	.then(()=>{
			    		this.notificationsUpdate();
			    		this.$refs.noti_form.reset();
			    		this.notification_tab = 'new';
			    	});
	    	} catch (e) {
	    		console.error('Notification compose fail', e)
	    	} finally {
		    	this.loading = false;
		    }
	  	}
    },
    
    computed: {
        ...mapGetters(["user", "userRole", "app_settings", "team_leaders_list"])
    }
};
</script>
<style>

.noti_panel>button{
	border-radius: 24px!important;
    padding: 12px 25px 12px 25px!important;
}
.notifications-holder{ 
	position: absolute;
    top: 120px;
    max-width: 500px;
    right: 20px;
    height: 75vh;
    width: 30%;
}

.notifications-area{
	position: relative;
    background:#f8fafb!important;
    max-width: 500px;
    height:100%;
}
.closeNotifArea{
	left:6px;
	position:absolute;
	top:6px;
	border-radius:21px;
	padding:5px;
	min-width: auto;
}
.notification_item{
	border:1px solid #b6d3f8;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    .title{
    	padding:5px;
    	background: #84a2c6;
    	color: #fff;
    	border-top-left-radius: 12px;
    	border-top-right-radius: 12px;
    }
    &.seen{
    	border:1px solid #c3c3c3;
		.title{
			background: #f1f1f1;
    		color: #666;
		}
    
    }
}

</style>