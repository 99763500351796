<template>
	<svg width="62" height="61" viewBox="0 0 62 61" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="0.5" width="61" height="60" rx="12" fill="#0E80FF"/>
		<path d="M17.9605 18.6216H42.9605C43.2526 18.6215 43.5443 18.64 43.834 18.6771C43.7358 17.9878 43.499 17.3256 43.138 16.7304C42.777 16.1351 42.2991 15.6191 41.7333 15.2135C41.1674 14.8079 40.5253 14.5211 39.8456 14.3703C39.1659 14.2196 38.4628 14.2081 37.7785 14.3365L17.2184 17.8466H17.1949C15.9044 18.0934 14.7567 18.8236 13.9863 19.888C15.1469 19.0625 16.5363 18.6198 17.9605 18.6216Z" fill="white"/>
		<path d="M42.9609 20.5H17.9609C16.6353 20.5014 15.3644 21.0287 14.427 21.9661C13.4896 22.9034 12.9624 24.1744 12.9609 25.5V40.5C12.9624 41.8256 13.4896 43.0966 14.427 44.0339C15.3644 44.9713 16.6353 45.4985 17.9609 45.5H42.9609C44.2866 45.4985 45.5575 44.9713 46.4949 44.0339C47.4322 43.0966 47.9595 41.8256 47.9609 40.5V25.5C47.9595 24.1744 47.4322 22.9034 46.4949 21.9661C45.5575 21.0287 44.2866 20.5014 42.9609 20.5ZM39.25 35.5C38.7555 35.5 38.2722 35.3534 37.8611 35.0787C37.4499 34.804 37.1295 34.4135 36.9403 33.9567C36.7511 33.4999 36.7016 32.9972 36.798 32.5123C36.8945 32.0273 37.1326 31.5819 37.4822 31.2322C37.8319 30.8826 38.2773 30.6445 38.7623 30.548C39.2472 30.4516 39.7499 30.5011 40.2067 30.6903C40.6635 30.8795 41.054 31.1999 41.3287 31.6111C41.6034 32.0222 41.75 32.5055 41.75 33C41.75 33.663 41.4866 34.2989 41.0178 34.7678C40.5489 35.2366 39.913 35.5 39.25 35.5Z" fill="white"/>
		<path d="M13 30.7695V22.9961C13 21.3031 13.9375 18.4648 17.1914 17.85C19.9531 17.332 22.6875 17.332 22.6875 17.332C22.6875 17.332 24.4844 18.582 23 18.582C21.5156 18.582 21.5547 20.4961 23 20.4961C24.4453 20.4961 23 22.332 23 22.332L17.1797 28.9336L13 30.7695Z" fill="white"/>
	</svg>

</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "IconYooMoney",
    }
  },
};
</script>
