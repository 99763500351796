import LAYOUT from "@/layout/layout";

export default [
  {
    path: "/transactions",
    component: LAYOUT.base,
    children: [
      {
        path: "",
        name: "client-transactions",
        meta: {roles: ['client']},
        component: () => import(/* webpackChunkName: '' */ "@/modules/ClientTransactions/views/ClientTransactions.vue"),
      },
    ],

  },
];
