import modalConfirm from "@/components/UI/modalConfirm.vue";
export default {
    install(Vue , {vuetify}) {
        async function renderConfirmComponent(title, message, btnsColor, btnConfirmClass, icon, iconColor) {
            const ErrorHandlerComponent = Vue.extend(modalConfirm);
            const instance = new ErrorHandlerComponent({
                vuetify,
            });
            instance.$mount();
            return await instance.open(title, message, btnsColor, btnConfirmClass, icon, iconColor);
        }

        Vue.prototype.$confirm = renderConfirmComponent;
    }
};
