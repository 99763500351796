<template>
  <v-dialog
    v-model="active"
    :max-width="maxWidth"
    class="dialog"
    transition="scale-transition"
  >
    <v-card class="dialog-container">
      <div class="dialog-header">
        <v-btn
          icon
          color="black_white"
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="dialog-content">
        <h3 class="flex-center text-center my-6">{{
            error
          }}
        </h3>
        <v-btn @click="close" class="gradient-primary" color="primary">Ok</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "Error",
  props: {
    error: {
      type: String,
      default: "",
    },
    redirectUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return { active: false };
  },
  methods: {
    close() {
      this.active = false;
      this.$destroy();
    },
    open() {
      this.active = true;
    },
  },
  mounted() {
    if (this.redirectUrl && this.$route.name !== this.redirectUrl) {
      this.$router.replace({ name: this.redirectUrl });
    }
    if (this.error) {
      this.open();
    }
  },
  computed: {
    maxWidth() {
      if (this.error?.length > 200) return "960";
      return typeof this.error === "string" ? "340" : "768";
    },
  },
};
</script>

<style lang="scss">
.dialog {
  &-right {
    right: 0;
    background: #000;
  }

  &-container {
    min-width: 200px;
    min-height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 40px 36px 40px !important;
    position: relative
  }

  &-trigger {
    &-container {
      display: flex;
    }
  }

  &-header {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    top: 10px;
    right: 10px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }
}
</style>
