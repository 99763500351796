import Vue from "vue";
import CryptoJS from "crypto-js";

export function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function vueToast(txt, type = "error", duration = 3000) {
  Vue.$toast.open({
    message: txt,
    type,
    position: "bottom",
    duration: duration
  });
}

export function generateRandomString(length) {
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const specialChars = "~`!@#$%^&*()_-+={[}]|:;<,>.?/";
  const numericChars = "0123456789";

  const randomBytes = CryptoJS.lib.WordArray.random(length);

  let hasLowercase = false;
  let hasUppercase = false;
  let hasNumeric = false;
  let hasSpecial = false;

  let randomString = "";
  for (let i = 0; i < randomBytes.sigBytes; i++) {
    const byte = randomBytes.words[Math.floor(i / 4)] >>> (24 - (i % 4) * 8) & 0xff;

    let randomChar;
    if (!hasLowercase || !hasUppercase || !hasNumeric || !hasSpecial) {
      if (!hasLowercase) {
        randomChar = lowercaseChars.charAt(byte % lowercaseChars.length);
        hasLowercase = true;
      } else if (!hasUppercase) {
        randomChar = uppercaseChars.charAt(byte % uppercaseChars.length);
        hasUppercase = true;
      } else if (!hasNumeric) {
        randomChar = numericChars.charAt(byte % numericChars.length);
        hasNumeric = true;
      } else {
        randomChar = specialChars.charAt(byte % specialChars.length);
        hasSpecial = true;
      }
    } else {
      const charSet = lowercaseChars + uppercaseChars + numericChars + specialChars;
      randomChar = charSet.charAt(byte % charSet.length);
    }

    randomString += randomChar;
  }


  return randomString;
}

export const menuProps = {
  bottom: true,
  left: true,
  offsetY: true,
  nudgeBottom: "5",
  "content-class": "select-content"
};

export function getKeyFromSessionStorage(key) {
  const itemStorage = sessionStorage.getItem(key);
  if (!itemStorage) {
    return null;
  }
  const item = JSON.parse(itemStorage);
  if (!item) {
    return null;
  }
  return item;
}

export const noAuthRoutes = ["activate", "login", "password-recovery"];

export const menuItems = [
  {
    name: "Home",
    url: "/admin",
    disable: false,
    roles: ["1_admin", "2_manager", "3_teamleader", "4_agent"],
    icon: "iconhome"
  },
  {
    name: "Users",
    url: "/admin/users",
    disable: false,
    roles: ["1_admin"],
    icon: "iconusers"
  },
  {
    name: "Users",
    url: "/admin/users/teams",
    disable: false,
    roles: ["2_manager"],
    icon: "iconclients"
  },
  {
    name: "Clients",
    url: "/admin/clients",
    disable: false,
    roles: ["1_admin", "3_teamleader"],
    icon: "iconclients"
  },
  {
    name: "Clients in Work",
    url: "/admin/clients/inwork",
    disable: false,
    roles: ["4_agent"],
    icon: "iconclients"
  },
  {
    name: "Agents List",
    url: "/admin/users/agents",
    disable: false,
    roles: ["3_teamleader"],
    icon: "iconagents"
  },
  {
    name: "Contracts",
    url: "/admin/contracts",
    disable: false,
    roles: ["1_admin", "2_manager", "3_teamleader", "4_agent"],
    icon: "IconContracts"
  },
  {
    name: "Finances",
    url: "/admin/finance",
    disable: false,
    roles: ["1_admin", "2_manager", "3_teamleader", "4_agent"],
    icon: "IconFinances"
  },
  {
    name: "DataBase",
    url: "/admin/database",
    disable: false,
    roles: ["1_admin", "2_manager"],
    icon: "iconexports"
  },
  {
    name: "Settings",
    url: "/admin/settings",
    disable: false,
    roles: ["1_admin"],
    icon: "iconSettings",
    icon_save: "iconSave",
    save_marker: true
  },


  //client
  {
    name: "Home",
    url: "/",
    disable: false,
    roles: ['client'],
    icon: "iconhome"
  },
  {
    name: "Wallet",
    url: "/wallet",
    disable: false,
    roles: ['client'],
    icon: "iconWallet"
  },
  {
    name: "Transactions",
    url: "/transactions",
    disable: false,
    roles: ['client'],
    icon: "iconCardCoin"
  },
  {
    name: "Verification",
    url: "/verification",
    disable: false,
    roles: ['client'],
    icon: "iconkyc"
  },
]

export const clientTabItems = [
  {text: "All Clients", url: "all", name: "all"},
  {text: "My Clients", url: "my", name: "my"},
  {text: "Teams Clients", url: "teams", name: "teams"},
  {text: "Clients in Work", url: "inwork", name: "inwork"},
  {text: "Archived Clients", url: "archived", name: "archived"},
]

export const userTabItems = [
  {text: 'All staff', url: 'staff', name: 'staff'},
  {text: 'Teams', url: 'teams', name: 'teams'},
  {text: 'CRM Manager', url: 'managers', name: 'managers'},
  {text: 'Agents', url: 'agents', name: 'agents'},
  {text: 'Restricted Access', url: 'restricted-access', name: 'restricted_access'},

  {text: 'Distribution', url: 'distribution', name: 'distribution'},
  {text: 'Allocation', url: 'allocation', name: 'allocation'},
]

export const transactionHeaders = [
  {
    text: "Type",
    type: "transaction_type",
    value: "Transaction Type",
    width: "120"
  },
  {
    text: "Currency",
    value: "Currency",
    type: "transaction_currency"
  },
  {
    text: "Amount",
    value: "Amount",
    type: "transaction_amount"
  },
  {
    text: "Date",
    type: "transaction_date",
    value: "date"
  },
  {
    text: "Status",
    type: "client_status",
    value: "status"
  },
  {
    text: "Action",
    value: "actions",
    type: "action_transactions"
  }
];
export const transactionHeadersIncome = [
  {
    text: "Type",
    type: "transaction_type",
    value: "Transaction Type",
    width: "120"
  },
  {
    text: "Currency",
    value: "Currency",
    type: "transaction_currency"
  },
  {
    text: "Amount",
    value: "Amount",
    type: "transaction_amount"
  },
  {
    text: "Created Date",
    type: "transaction_date",
    value: "createdAt"
  },
  {
    text: "Processed Date",
    type: "transaction_date",
    value: "updatedAt"
  },
  {
    text: "Status",
    type: "client_status",
    value: "status"
  },
  {
    text: "Action",
    value: "actions",
    type: "action_transactions_income"
  }
];
export const datasetHeaders = [
  {
    text: "",
    type: "expand",
    sortable: false,
  },
  {
    text: "Date & Time",
    type: "createdAt",
    value: "createdAt"
  },
/*
  {
    text: "Data Source",
    value: "source_name",
    type: "source_name"
  },
*/
  {
    text: "Name",
    value: "dataset_name",
    type: "dataset_name"
  },
  {
    text: "Lang",
    type: "lang",
    value: "lang"
  },
  {
    text: "ISO",
    type: "country",
    value: "country"
  },
  {
    text: "Prior",
    value: "priority",
    type: "priority",
    width: "60"
  },
  {
    text: "Distributed to",
    value: "teamleader_id",
    type:  "distributedTo",
    sortable: false
  },
  {
    text: "Status",
    value: "status",
    type: "dataset_status"
  },
  {
    text: "Total leads",
    value: "total_leads",
    type: "ds_total_leads"
  },
  {
    text: "Leads left",
    value: "leads_left",
    type: "leads_left"
  },
/*  {
    text: "Comments",
    value: "dataset",
    type: "comment"
  },
*/
  {
    text: "Conv. %",
    value: "conversion",
    type: "conversion"
  },
  {
    text: "$ AR/L",
    value: "arl",
    type: "arl"
  },
  {
    text: "ROI %",
    value: "roi",
    type: "roi"
  },
  {
    text: "PR %",
    value: "pr",
    type: "roi"
  },
  {
    text: "Action",
    value: "action_dataset",
    type: "action_dataset",
    width: "80"
  },
]
export const clientStatsHeaders = [
		{
		  text: "Asset Name",
		  type: "transaction_currency",
		  value: "currency"
		},
  		{
  		  text: "Price",
  		  type: "text",
 		  value: "price",
  		},
  		{
		  text: "24H Change",
    	  value: "change24",
    	  type: "change24"
	    },
	    {
    	  text: "Market Cap",
  		  type: "text",
		  value: "marketcap"
  		},
  		{
  		  text: "24H Vol",
  		  type: "text",
  		  value: "vol24",
  		},
  		{
  		  text: "30 Day Trend",
  		  type: "t30day",
  		  value: "t30day",
  		  width: "210",
  		  align: "center"
  		},
  		{
  		  text: "Balance",
  		  type: "crypto_balance",
  		  value: "balance",
  		  align: "center"
  		}
  	  ];
export const clientTransactionHeaders = [
  {
    text: "Date",
    type: "transaction_date",
    value: "date"
  },
  {
    text: "Type",
    type: "transaction_type_client",
    value: "Transaction Type Client"
  },
  {
    text: "Currency",
    value: "currency",
    type: "transaction_currency"
  },
  {
    text: "Amount",
    type: "amount",
    value: "amount"
  },
  {
    text: "Amount USD",
    type: "amount_usd",
    value: "amount_usd"
  },
  {
    text: "Documents",
    type: "documents",
    value: "documents"
  },
  {
    text: "Invoice",
    type: "invoice",
    value: "invoice"
  },
  {
    text: "Status",
    value: "status",
    type: "client_status"
  }
];
export const finTransactionHeadersIncome = [
  {
    text: "Transaction ID",
    type: "transaction_type",
    value: "id",
    width: "150"
  },
  {
    text: "Client Full Name",
    type: "trx_holders",
    value: "client.full_name",
    width: "200",
    sortable: false
  },
  {
    text: "Amount",
    value: "amount",
    type: "fin_amount",
    width: "140"
  },
  {
    text: "Amount",
    value: "amount_usd",
    type: "custom_header",
    width: "100",
    icon: "IconUSD",
    color: "green"
  },
  {
    text: "Type",
    value: "ftd",
    type: "fin_trx_type",
    width: "120",
    sortable: true,
  },
  {
    text: "Agent Full Name",
    type: "trx_holders",
    value: "agent.full_name",
    width: "200",
    sortable: false
  },
  {
    text: "Created Date",
    type: "transaction_date",
    value: "createdAt"
  },
  {
    text: "Processed Date",
    type: "transaction_date",
    value: "updatedAt",
    sortable: false
  },
  {
    text: "Status",
    type: "client_status",
    value: "status"
  },
  {
    text: "Proof Link",
    type: "proof_link",
    value: "hash",
    sortable: false
  },
  {
    text: "Action",
    value: "actions",
    type: "action_transactions_income",
    sortable: false
  }
];
export const contractsHeaders = [
  		{
          text: "Contract ID",
          value: "id", 
          type: "id",
          width: "",
          sortable: false,
        },
        {
          text: "Contractor",
          value: "contractor", 
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconReferrer",
          color: "red"
        },
        {
          text: "Agent Offeree",
          value: "offeree",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconReferrer",
          color: "green"
        },
        {
          text: "Rewards",
          value: "reward",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconPercentSquare",
          color: "red"
        },
        {
          text: "Transaction ID",
          value: "transaction_id",
          type: "transaction_id",
          width: "",
          sortable: false,
          //icon: "IconPercentRound",
          //color: "#0A6408"
        },
        {
          text: "Created Date",
          value: "createdAt",
          type: "contract_date",
          width: "",
          sortable: true,
        },
        {
          text: "Processed Date",
          value: "updatedAt",
          type: "contract_date",
          width: "",
          sortable: false,
        },
         {
          text: "Status",
          value: "status",
          type: "client_status",
          width: "",
          sortable: true,
        },
];
export const contractsHeadersFin = [
  		{
          text: "Contract ID",
          value: "id", 
          type: "id",
          width: "",
          sortable: false,
        },
        {
          text: "Contractor",
          value: "contractor", 
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconReferrer",
          color: "red"
        },
        {
          text: "Agent Offeree",
          value: "offeree",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconReferrer",
          color: "green"
        },
        {
          text: "Rewards",
          value: "reward",
          type: "custom_header",
          width: "",
          sortable: true,
          icon: "IconPercentSquare",
          color: "red"
        },
        {
		  text: "Client",
		  value: "contractClient",
    	  type: "custom_header",
    	  sortable: true,
    	  icon: "IconTeamLeadMicro",
    	  color: "blue"
		},
        {
          text: "Transaction ID",
          value: "transaction_id",
          type: "transaction_id",
          width: "",
          sortable: false,
          //icon: "IconPercentRound",
          //color: "#0A6408"
        },
        {
          text: "Created Date",
          value: "createdAt",
          type: "contract_date",
          width: "",
          sortable: true,
        },
        {
          text: "Processed Date",
          value: "updatedAt",
          type: "contract_date",
          width: "",
          sortable: false,
        },
         {
          text: "Status",
          value: "status",
          type: "contract_status",
          width: "",
          sortable: true,
        },
];
export const contractTrxHeaders = [
		{
          text: "Contract ID",
          value: "id",
          type: "contract_id",
          width: "",
          sortable: false,
        },
  		{
          text: "Contractor",
          value: "contractor", 
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconReferrer",
          color: "red"
        },
        {
          text: "Agent Offeree",
          value: "offeree",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconReferrer",
          color: "green"
        },
        {
          text: "Rewards",
          value: "reward",
          type: "custom_header",
          width: "",
          sortable: true,
          icon: "IconPercentSquare",
          color: "red"
        },
        {
		  text: "Client",
		  value: "contractClient",
    	  type: "custom_header",
    	  sortable: true,
    	  icon: "IconTeamLeadMicro",
    	  color: "blue"
		},
        {
          text: "Income ID",
          value: "transaction_id",
          type: "transaction_id",
          width: "",
          sortable: false
        },
        {
          text: "Contractor's Share",
          value: "contractors_share",
          type: "contractors_share",
          width: "",
          sortable: true,
        },
        {
    		text: "Offeree Reward",
    		value: "offeree_reward",
    		type: "custom_header",
    		width: "100",
    		icon: "IconUSD",
    		color: "green",
    		sortable: true,
  		},
        {
          text: "Processed Date",
          value: "updatedAt",
          type: "contract_date",
          width: "",
          sortable: false,
        }
];
export const bonusTrxHeaders = [
		{
          text: "ID",
          value: "id",
          type: "bonus_id",
          width: "250",
          sortable: false,
        },
  		{
          text: "Agent Full Name",
          value: "fullname", 
          type: "fullname",
          width: "220",
          sortable: false,
        },
        {
          text: "Transaction ID",
          value: "transaction_id",
          type: "transaction_id",
          width: "",
          sortable: false,
        },
        {
          text: "Type",
          value: "type",
          type: "bonus_type",
          width: "180",
          sortable: false,
        },
        {
    		text: "Amount",
    		value: "amount_usd",
    		type: "custom_header",
    		width: "120",
    		icon: "IconUSD",
    		color: "green",
    		sortable: false,
  		},
        {
          text: "",
          value: "reward",
          type: "custom_header",
          width: "80",
          sortable: false,
          icon: "IconPercentSquare",
          color: "red"
        },
        {
          text: "Turnover",
          value: "turnover",
          type: "turnover",
          width: "120",
          sortable: false
        },
        {
    		text: "Bonus",
    		value: "bonus_usd",
    		type: "custom_header",
    		width: "120",
    		icon: "IconUSD",
    		color: "green",
    		sortable: false,
  		},
        {
          text: "Processed Date",
          value: "updatedAt",
          type: "contract_date",
          width: "140",
          sortable: false,
        }
];
export const distr_headers = [
		{
          text: "Department",
          value: "agent_type", 
          type: "text",
          sortable: false,
        },
  		{
          text: "Role",
          value: "role", 
          type: "text",
          sortable: false,
        },
        {
          text: "Agent",
          value: "user",
          type: "agent_prior",
          width: "250",
          sortable: false,
        },
        {
          text: "Source Name",
          value: "source_name",
          type: "text",
          sortable: false,
        },
        {
          text: "Dataset Name",
          value: "dataset_name",
          type: "text",
          sortable: false,
        },
        {
          text: "Client",
          value: "lead",
          type: "client_link",
          width: "250",
          sortable: false,
        },
        {
          text: "Status on Date",
          value: "act_status",
          type: "log_client_status",
          sortable: false,
        },
        {
          text: "Current status",
          value: "curr_status",
          type: "log_client_status",
          sortable: false,
        },
        {
          text: "Date",
          value: "createdAt",
          type: "timeDate",
          width: "200",
          sortable: false,
        },
];
export const alloc_headers = [
		{
          text: "Agent",
          value: "agent_summary", 
          type: "agent_summary",
          sortable: false,
          width: "300"
        },
        {
          text: "Team Name",
          value: "team_name", 
          type: "text",
          sortable: false,
          width: "250"
        },
        /*
        {
          text: "Department",
          value: "agent_type", 
          type: "text",
          sortable: false,
          width: "130"
        },
        
        {
          text: "Role",
          value: "role", 
          type: "text",
          sortable: false,
          width: "130"
        },
        {
          text: "Agent",
          value: "user",
          type: "agent_prior",
          width: "250",
          sortable: false,
        },
        */
        {
          text: "Workload",
          value: "workload",
          type: "text",
          width: "100",
          sortable: false,
        },
        {
          text: "Clients",
          value: "alloc_clients",
          type: "alloc_clients",
          sortable: false,
        },
        {
          text: "Action",
          value: "fill_staff",
          type: "fill_staff",
          sortable: false,
          width: "100"
        },
        
];


export const leaderboardHeaders = [
		{
          text: "Place",
          value: "place",
          type: "place",
          sortable: false,
        },
  		{
          text: "Full Name",
          value: "fullname", 
          type: "fullname",
          width: "250",
          sortable: false,
        },
        {
          text: "Team",
          value: "team",
          type: "team",
          sortable: false,
        },
        {
    		text: "Language",
    		value: "language",
    		type: "language",
    		sortable: false,
  		},
        {
          text: "Turnover",
          value: "turnover",
          type: "custom_header",
    	  width: "120",
    	  icon: "IconUSD",
    	  color: "green",
    	  sortable: false,
        },
];

export const datePickerTextFieldProps = {
  appendIcon: "$vuetify.icons.iconcalendardate",
  solo: true,
  flat: true,
  height: "60",
  backgroundColor: "gray4",
  hideDetails: "auto"
};

export const defaultFormDataTransaction = {
    "type": null,
    "currency": null,
    "amount": 0,
    "status": null,
    "address": '',
    date : new Date(),
};

export const defaultFormDataFeeTransaction = {
  "type": "Fee",
  name: null,
  "currency": 'BTC',
  "amount_usd": 0,
  "status": null,
  address : '',
  "file" : null,
}

export function objectToFormData(obj, formData = new FormData()) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      formData.append(key, value);
    }
  }
  return formData;
}

export function replaceDefaultValues(def, target) {
	if (target === undefined) return def;
	for(const key of Object.keys(target)) {
		if (key === 'files_json')continue; // do not apply for files;
		if (typeof target[key] === 'object' && !Array.isArray(target[key]) && target[key] !== null) {
			def[key] = replaceDefaultValues(def[key], target[key]);
		} else {
			if (def === undefined) def={}; // new object, add.
			def[key] = target[key];
		}
	}
	return def;
}

export function copyMatchingFields(source, target) {
  for (const key in source) {
    // eslint-disable-next-line no-prototype-builtins
    if (Object.prototype.hasOwnProperty.call(source, key) && target.hasOwnProperty(key)) {
      target[key] = source[key];
    }
  }
  return target;
}

export function deepEqual(obj1, obj2) {
  // Check if the objects are the same reference in memory
  if (obj1 === obj2) {
    return true;
  }

  // Check if the objects are both null or undefined
  if (obj1 == null || obj2 == null) {
    return obj1 === obj2;
  }

  // Check if the objects are not of the same type
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // Check if the objects are primitive types
  if (typeof obj1 !== 'object') {
    return obj1 === obj2;
  }

  // Check if the objects have the same number of properties
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Recursively check each property value
  for (const key of keys1) {
    if (!deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export const languageList = ['ENG','GER','FRE','ESP'];
export const countryList = ['England', 'Germany', 'France', 'Spain'];
export const clientTransactionStatuses = [ 'Pending', 'Canceled', 'Complete' ];
export const TransactionTypes = [ 'Deposit', 'Withdraw', 'Fee'];

// export const clientTransactionsFilters =   [
//   {
//     name: "status", description: "Status", type: "OneOf",
//     options: [
//       { label: "Pending", value: 'Pending' },
//       { label: "Canceled", value: 'Canceled' },
//       { label: "Complete", value: 'Complete' },
//     ]
//   },
//   {
//     name: "currency", description: "Currency", type: "OneOf",
//     options: [
//       { label: "eur", value: 'eur' },
//       { label: "usd", value: 'usd' },
//       { label: "gbp", value: 'gbp' },
//       { label: "eth", value: 'eth' },
//       { label: "btc", value: 'btc' },
//     ]
//   },
// ]

export function transformDate(value) {
  if (value?.length === 10) {
    const arr = value?.split("/");
    const year = arr[2];
    const firstTwoNumbers = year[0] + year[1];
    if (+firstTwoNumbers < 19 || +firstTwoNumbers > 20) {
      return null;
    }
    const el = arr?.shift();
    arr.splice(1, 0, el);
    return arr.join("/");
  }
  return value;
}

export const settingsHeaders = {
      automation: [
        {
          text: "Country Name",
          value: "country",
          type: "automation_country",
          width: "",
          sortable: false
        },
        {
          text: "Start At",
          value: "startAt",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "iconTime",
          color: "green"
        },
        {
          text: "Pause At",
          value: "pauseAt",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "iconTimePaused",
          color: "red"
        },
        {
          text: "Edit",
          value: "action_settings",
          type: "action_automation",
          width: "120",
          sortable: false
        }
      ],
      internal_team_target: [
        {
          text: "Team Leader",
          value: "teamLead",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconTeamLeadMicro",
          color: "blue"
        },
        {
          text: "Monthly Target",
          value: "mTarget",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconMoneyOut",
          color: "red"
        },
        {
          text: "Edit",
          value: "action_settings",
          type: "action_automation",
          width: "120",
          sortable: false
        },
      ],
      internal_agent_tickets: [
        {
          text: "Ticket Amount",
          value: "tAmount",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconTicket",
          color: "blue"
        },
        {
          text: "Monthly Target",
          value: "mTarget",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconMoneyOut",
          color: "red"
        },
        {
          text: "Edit",
          value: "action_settings",
          type: "action_automation",
          width: "120",
          sortable: false
        },
      ],
      internal_team_tickets: [
        {
          text: "Ticket Amount",
          value: "tAmount",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconTicket",
          color: "blue"
        },
        {
          text: "Total monthly team revenue is:",
          value: "mTarget",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconMoneyOut",
          color: "red"
        },
        {
          text: "Edit",
          value: "action_settings",
          type: "action_automation",
          width: "120",
          sortable: false
        },
      ],
      internal_referals: [
        {
          text: "Referrer Name",
          value: "referrer",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconReferrer",
          color: "red"
        },
        {
          text: "Referrals Names",
          value: "referral",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconReferral",
          color: "red"
        },
        {
          text: "1 Level",
          value: "level1",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconPercentSquare",
          color: "red"
        },
        {
          text: "2 Level",
          value: "level2",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconPercentRound",
          color: "#0A6408"
        },
        {
          text: "Edit",
          value: "action_settings",
          type: "action_automation",
          width: "120",
          sortable: false
        },
      ],
      company_crypto_creds: [
        {
          text: "Currency",
          type: "custom_header",
          value: "currency",
          width: "40",
          sortable: false,
          icon: "IconDollarHead",
          color: "green"
        },     
        {
          text: "Wallet Address",
          value: "address",
          type: "wallet_address",
          width: "",
          sortable: false,
        },
        {
          text: "Active",
          value: "active",
          type: "wallet_active",
          width: "80",
          sortable: true,
        },
        {
          text: "Edit",
          value: "wallet_edit",
          type: "wallet_edit",
          width: "80",
          sortable: false
        },
      ],
      company_fiat_creds: [
        {
          text: "Country",
          value: "iso",
          type: "iso_country",
          width: "220",
          sortable: true,
        },
        {
          text: "Bank Name",
          value: "bankName",
          type: "bank_name",
          width: "",
          sortable: true,
        },
        {
          text: "Currency",
          type: "custom_header",
          value: "currency",
          width: "40",
          sortable: false,
          icon: "IconDollarHead",
          color: "green"
        },
        {
          text: "IBAN",
          value: "iban",
          type: "custom_header",
          width: "",
          sortable: false,
          icon: "IconDollar",
          color: "green"
        },
        {
          text: "Third Party Fees",
          value: "fees",
          type: "percent",
          width: "150"
        },
        {
          text: "Active",
          value: "active",
          type: "wallet_active",
          width: "80",
          sortable: true,
        },
        {
          text: "Edit",
          value: "wallet_edit",
          type: "wallet_edit",
          width: "120",
          sortable: false
        },
      ],
      telegram:[
        {
          text: "Token",
          value: "token",
          type: "telegram_token",
          sortable: false
        },
        {
          text: "Source Name",
          value: "source_name",
          type: "token_source",
          sortable: false
        },
        {
          text: "Expire date",
          value: "expire",
          type: "token_expire",
          width: "100",
          sortable: true
        },
        {
          text: "Active",
          value: "active",
          type: "token_active",
          width: "80",
          sortable: true
        },
        {
          text: "Actions",
          value: "action_settings",
          type: "edit_token",
          width: "80",
          sortable: false
        },
      ]
};
