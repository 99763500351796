<template>
	<svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M16.5 20C18.7091 20 20.5 18.2091 20.5 16C20.5 13.7909 18.7091 12 16.5 12C14.2909 12 12.5 13.7909 12.5 16C12.5 18.2091 14.2909 20 16.5 20Z" :stroke="iconColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M3.1665 17.1736V14.8269C3.1665 13.4402 4.29984 12.2936 5.69984 12.2936C8.11317 12.2936 9.09984 10.5869 7.8865 8.49357C7.19317 7.29357 7.6065 5.73357 8.81984 5.04024L11.1265 3.72024C12.1798 3.09357 13.5398 3.46691 14.1665 4.52024L14.3132 4.77357C15.5132 6.86691 17.4865 6.86691 18.6998 4.77357L18.8465 4.52024C19.4732 3.46691 20.8332 3.09357 21.8865 3.72024L24.1932 5.04024C25.4065 5.73357 25.8198 7.29357 25.1265 8.49357C23.9132 10.5869 24.8998 12.2936 27.3132 12.2936C28.6998 12.2936 29.8465 13.4269 29.8465 14.8269V17.1736C29.8465 18.5602 28.7132 19.7069 27.3132 19.7069C24.8998 19.7069 23.9132 21.4136 25.1265 23.5069C25.8198 24.7202 25.4065 26.2669 24.1932 26.9602L21.8865 28.2802C20.8332 28.9069 19.4732 28.5336 18.8465 27.4802L18.6998 27.2269C17.4998 25.1336 15.5265 25.1336 14.3132 27.2269L14.1665 27.4802C13.5398 28.5336 12.1798 28.9069 11.1265 28.2802L8.81984 26.9602C7.6065 26.2669 7.19317 24.7069 7.8865 23.5069C9.09984 21.4136 8.11317 19.7069 5.69984 19.7069C4.29984 19.7069 3.1665 18.5602 3.1665 17.1736Z" :stroke="iconColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconSettings",
    },
    width: {
      type: [Number, String],
      default: 32,
    },
    height: {
      type: [Number, String],
      default: 32,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
