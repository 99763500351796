<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M13.5002 5.93233C13.4777 5.93233 13.4627 5.93233 13.4402 5.93233H13.4027C11.9852 5.88733 10.9277 4.79233 10.9277 3.44233C10.9277 2.06233 12.0527 0.944824 13.4252 0.944824C14.7977 0.944824 15.9227 2.06983 15.9227 3.44233C15.9152 4.79983 14.8577 5.89483 13.5077 5.93983C13.5077 5.93233 13.5077 5.93233 13.5002 5.93233ZM13.4252 2.06233C12.6677 2.06233 12.0527 2.67733 12.0527 3.43483C12.0527 4.17733 12.6302 4.77733 13.3727 4.80733C13.3802 4.79983 13.4402 4.79983 13.5077 4.80733C14.2352 4.76983 14.7977 4.16983 14.8052 3.43483C14.8052 2.67733 14.1902 2.06233 13.4252 2.06233Z" :fill="iconColor"/>
	<path d="M13.5074 11.46C13.2149 11.46 12.9224 11.4375 12.6299 11.385C12.3224 11.3325 12.1199 11.04 12.1724 10.7325C12.2249 10.425 12.5174 10.2225 12.8249 10.275C13.7474 10.4325 14.7224 10.26 15.3749 9.825C15.7274 9.5925 15.9149 9.29999 15.9149 9.00749C15.9149 8.71499 15.7199 8.42999 15.3749 8.19749C14.7224 7.76249 13.7324 7.58999 12.8024 7.75499C12.4949 7.81499 12.2024 7.60499 12.1499 7.29749C12.0974 6.98999 12.2999 6.69749 12.6074 6.64499C13.8299 6.42749 15.0974 6.65998 15.9974 7.25998C16.6574 7.70248 17.0399 8.33249 17.0399 9.00749C17.0399 9.67499 16.6649 10.3125 15.9974 10.7625C15.3149 11.2125 14.4299 11.46 13.5074 11.46Z" :fill="iconColor"/>
	<path d="M4.47785 5.9325C4.47035 5.9325 4.46285 5.9325 4.46285 5.9325C3.11285 5.8875 2.05535 4.7925 2.04785 3.4425C2.04785 2.0625 3.17285 0.9375 4.54535 0.9375C5.91785 0.9375 7.04285 2.0625 7.04285 3.435C7.04285 4.7925 5.98535 5.8875 4.63535 5.9325L4.47785 5.37L4.53035 5.9325C4.51535 5.9325 4.49285 5.9325 4.47785 5.9325ZM4.55285 4.8075C4.59785 4.8075 4.63535 4.8075 4.68035 4.815C5.34785 4.785 5.93285 4.185 5.93285 3.4425C5.93285 2.685 5.31785 2.07 4.56035 2.07C3.80285 2.07 3.18785 2.685 3.18785 3.4425C3.18785 4.1775 3.75785 4.77 4.48535 4.815C4.49285 4.8075 4.52285 4.8075 4.55285 4.8075Z" :fill="iconColor"/>
	<path d="M4.47 11.46C3.5475 11.46 2.6625 11.2125 1.98 10.7625C1.32 10.32 0.9375 9.68249 0.9375 9.00749C0.9375 8.33999 1.32 7.70248 1.98 7.25998C2.88 6.65998 4.1475 6.42749 5.37 6.64499C5.6775 6.69749 5.88 6.98999 5.8275 7.29749C5.775 7.60499 5.4825 7.81499 5.175 7.75499C4.245 7.58999 3.2625 7.76249 2.6025 8.19749C2.25 8.42999 2.0625 8.71499 2.0625 9.00749C2.0625 9.29999 2.2575 9.5925 2.6025 9.825C3.255 10.26 4.23 10.4325 5.1525 10.275C5.46 10.2225 5.7525 10.4325 5.805 10.7325C5.8575 11.04 5.655 11.3325 5.3475 11.385C5.055 11.4375 4.7625 11.46 4.47 11.46Z" :fill="iconColor"/>
	<path d="M9.00023 11.5349C8.97773 11.5349 8.96273 11.5349 8.94023 11.5349H8.90273C7.48523 11.4899 6.42773 10.3949 6.42773 9.04486C6.42773 7.66486 7.55274 6.54736 8.92524 6.54736C10.2977 6.54736 11.4227 7.67236 11.4227 9.04486C11.4152 10.4024 10.3577 11.4974 9.00773 11.5424C9.00773 11.5349 9.00773 11.5349 9.00023 11.5349ZM8.92524 7.66487C8.16774 7.66487 7.55273 8.27987 7.55273 9.03737C7.55273 9.77987 8.13024 10.3799 8.87274 10.4099C8.88024 10.4024 8.94023 10.4024 9.00773 10.4099C9.73523 10.3724 10.2977 9.77237 10.3052 9.03737C10.3052 8.28737 9.69024 7.66487 8.92524 7.66487Z" :fill="iconColor"/>
	<path d="M8.99996 17.07C8.09996 17.07 7.19996 16.8375 6.50246 16.365C5.84246 15.9225 5.45996 15.2925 5.45996 14.6175C5.45996 13.95 5.83496 13.305 6.50246 12.8625C7.90496 11.9325 10.1025 11.9325 11.4975 12.8625C12.1575 13.305 12.54 13.935 12.54 14.61C12.54 15.2775 12.165 15.9225 11.4975 16.365C10.8 16.83 9.89996 17.07 8.99996 17.07ZM7.12496 13.8075C6.77246 14.04 6.58496 14.3325 6.58496 14.625C6.58496 14.9175 6.77996 15.2025 7.12496 15.435C8.13746 16.1175 9.85496 16.1175 10.8675 15.435C11.22 15.2025 11.4075 14.91 11.4075 14.6175C11.4075 14.325 11.2125 14.04 10.8675 13.8075C9.86246 13.125 8.14496 13.1325 7.12496 13.8075Z" :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconReferral",
    },
    iconColor: {
      type: String,
      default: "#0A6408",
    },
  },
};
</script>
