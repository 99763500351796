<template>
  <svg :width="width" :height="height" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6823 16.0883L21 18.5L18.9297 10.0908L16.3471 12.5702L12.181 8.2306C12.0396 8.08328 11.8442 8 11.64 8C11.4358 8 11.2404 8.08328 11.099 8.2306L8.04 11.417L3.54104 6.7306C3.25419 6.43179 2.77941 6.4221 2.4806 6.70896C2.18179 6.99582 2.1721 7.47059 2.45896 7.7694L7.49896 13.0194C7.64039 13.1667 7.83578 13.25 8.04 13.25C8.24422 13.25 8.43961 13.1667 8.58104 13.0194L11.64 9.83298L15.265 13.609L12.6823 16.0883Z" :fill="iconColor"/>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "iconarrowfall",
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 25,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
};
</script>
